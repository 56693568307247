// import { authHeader } from '../_helpers';

export const userService = {
    login,
    logout,
    checkuser
};

function login( username, password ) {
    return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/login`, {
        method: 'POST',
        body: JSON.stringify({
            email: username.toLowerCase(),
            password: password
        }),
        headers: {
        'Content-Type':'application/json'
        }
    })
        .then(user => user.json())
        .then(user => { 
            if (user) {
                if(user.status !== '1') return false
                user.authdata = window.btoa(username + ':' + password);
                localStorage.setItem('user', JSON.stringify(user));
                return user;
            }
            return false;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function checkuser(id){

    return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/checkuser?id=${id}`)
        .then(user => user.json())
        .then(user => {         
            if (!user) return false
            if(user.status!=='1') return false
            return true;
        });

}