import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { userService } from '../../services/user.service'

class Header extends Component {
    constructor(props){
        super(props)

        this.state = {
            username: '',
            password: '',
            submitted: false,
            user: JSON.parse(localStorage.getItem('user')),
            loading: false,
            errorLogin: ''
        }

        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    async componentDidMount(){
        const { user } = this.state
      const check = await userService.checkuser(user._id['$oid'])
      if(!check) return  this.props.history.push("/login");
    }
    render(){
        const { user } = this.state

        return(
            <header className="navbar navbar-dark sticky-top flex-md-nowrap p-0 shadow">
  <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="#"><img src="skiils-lighter.png" style={{ width: 130 }} /></a>
  <button className="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>
  <input className="form-control form-control-dark w-100" type="text" aria-label="Search" />
  <ul className="navbar-nav px-3">
    <li className="nav-item text-nowrap">
      <Link to="/login" className="text-white">Salir </Link>
    </li>
  </ul>
</header>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(Header);