import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button } from 'react-bootstrap'
import Header from '../Header'
import Nav from '../Nav'
import Spinner from 'react-bootstrap/Spinner'
import Table from 'react-bootstrap/Table'
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Select from 'react-select'

class DetallePlanificacion extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            matrices: [],
            loadingUsuarios: true,
            filtroevaluaciones: '',
            cargo: {},
            showcreacion: false,
            loadingcargos: true,
            cargos: [],
            areas: [],
            loadingareas: true,
            loadingmatrices: true,
            evaluaciones: [],
            reporte: [],
            toaststyle: { position: toast.POSITION.BOTTOM_RIGHT },
            habilitarnuevaevaluacion: false,
            conseciones: false,
            filtrotipo: '',
            filtrocategoria: '',
            idplanificacion: this.props.match.params.id,
            loadingplanificacion: true,
            planificacion: false,
            opcionesfecha: { weekday: 'long', year: 'numeric', month:'long', day: 'numeric' },
            clasificacion: false,
            loadingclasificacion: true,
            permitir_planificacion: false,
            capacitacion: { usuarios: [] },
            personas: []
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeRol = this.handleChangeRol.bind(this)
        this.handleChangeCap = this.handleChangeCap.bind(this)
        this.handleChangeEmails = this.handleChangeEmails.bind(this)
    }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    handleChangeEmails(e){
        const { capacitacion } = this.state
        let array = []
        if(e.length > 0){
          e.map(user => {
            array.push(user.value)
          })
        }  
        capacitacion.usuarios = array
        return this.setState({ capacitacion })
      }

    handleChangeRol(e){
        const { name, value } = e.target
        const { cargo } = this.state
        cargo[name] = value
        return this.setState({ cargo: cargo })
    }

    componentDidMount(){
        const { user, idplanificacion } = this.state
        this.getCargos({ propietario: user.propietario })
        this.getMatrices({ propietario: user.propietario })
        this.getEvaluaciones({ "planificacion._id": idplanificacion })
        this.getConseciones(user.propietario)
        this.getAreas(user.propietario)
        this.getReporte(user.propietario, 'simple')
        this.getPlanificacion(idplanificacion)
        this.getConfigByTipo(user.propietario,'clasificacion','clasificacion','loadingclasificacion')

        if(user.tipo ==='admin' || user.tipo === 'evaluator') this.setState({ permitir_planificacion: true })
    }

    getConfigByTipo(propietario,tipo,estado,loading){

        this.setState({ [loading]: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/getConfitByTipo?propietario=${propietario}&tipo=${tipo}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ [loading]: false, [estado]: res })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    checkIfNuevaEvaluacion(){
        const { user, conseciones } = this.state
        if(!user.area) return false
        if(!conseciones) return false
        if(!conseciones.areas) return false
        const buscararea = conseciones.areas.filter(ar => ar.idarea === user.area)
        if(buscararea.length > 0){
            return this.setState({ habilitarnuevaevaluacion: buscararea[0].activo })
        }
    }

    mostrarReportes(){
        const { loadingreporte, evaluaciones, planificacion } = this.state

        if(loadingreporte){
            return <div>
                <Spinner animation="border" />
                <h3>Cargando reporte</h3>
            </div>
        }

        let contarusuarios = {}
            let a = 0
            let b = 0
            let c = 0
            let d = 0
            let e = 0
            let pendientes = 0
            const usuariostotales = planificacion.usuarios ? parseInt(planificacion.usuarios) : 0

            if(evaluaciones){
                if(evaluaciones.length){
                    evaluaciones.map(ev=>{
                        contarusuarios[ev.usuarioevaluado.email] = ev.usuarioevaluado.email

                        if(ev.categoria.toLowerCase() ==='a'){
                            a++
                        } else if(ev.categoria.toLowerCase() ==='b'){
                            b++
                        } else if(ev.categoria.toLowerCase() ==='c'){
                            c++
                        } else if(ev.categoria.toLowerCase() ==='d'){
                            d++
                        } else if(ev.categoria.toLowerCase() ==='e'){
                            e++
                        } 
                    })
                }
            }
            let porcentaje = 0

            if(usuariostotales > 0) porcentaje = evaluaciones.length / usuariostotales * 100

            return <Card className="mb-3">
<Card.Body>
    <img src="icon2.jpg" className="iconsystem" />
<h4>Estadísticas</h4>
<Table responsive hover className="mb-0">
            <thead>
              <tr>
                <th>Evaluados</th>
                <th>No evaluados</th>
                <th>A</th>
                <th>B</th>
                <th>C</th>
                <th>D</th>
                <th>E</th>
                <th>Porcentaje evaluado</th>
              </tr>
            </thead>
            <tbody>
                 <tr>
                      <th>{Object.keys(contarusuarios).length}</th>
                      <th>{usuariostotales - Object.keys(contarusuarios).length}</th>
                      <th>{a}</th>
                      <th>{b}</th>
                      <th>{c}</th>
                      <th>{d}</th>
                      <th>{e}</th>
                      <th><ProgressBar animated now={Math.round(porcentaje)} label={`${Math.round(porcentaje)}%`} /></th>
                  </tr>
            </tbody>
          </Table>
</Card.Body>
            </Card>
    }

    async getConseciones(propietario){
        
        this.setState({ loadingconseciones: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_campanasReclutamiento?propietario=${propietario}`)
        .then(res => res.json())
        .then(async res => {
            this.setState({ conseciones: res, loadingconseciones: false })
            this.checkIfNuevaEvaluacion()
        })
        .catch(error => {
            this.setState({ loadingconseciones: false })
            return toast.error("Error al consultar la información, intente recargar la página", this.state.toaststyle)
        })
  
    }

    getPlanificacion(id){

        this.setState({ loadingplanificacion: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/getReclutamientoById?id=${id}`)
        .then(res => res.json())
        .then(res => {
            const datos = JSON.parse(res)
            console.log(datos)
            this.setState({ loadingplanificacion: false, planificacion: datos })
        })
        .catch(error => {
            console.log(error)
            this.setState({ loadingplanificacion: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    getReporte(propietario, tipo){

        this.setState({ loadingreporte: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_GetReportesEvaluaciones?propietario=${propietario}&tipo=${tipo}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingreporte: false, reporte: res })
        })
        .catch(error => {
            this.setState({ loadingreporte: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    getAreas(propietario){

        this.setState({ loadingareas: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getAreas?propietario=${propietario}`)
        .then(res => res.json())
        .then(res => {
            this.setState({ loadingareas: false, areas: res })
        })
        .catch(error => {
            this.setState({ loadingareas: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    getEvaluaciones(condicion){
        const { user } = this.state
        let array = []
        let url = 'https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_VerEvaluaciones'

        if(user.tipo==='admin'){
            url = 'https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getMultiplesEvaluaciones'
        } else if(user.tipo==='evaluator'){
            if(user.area){
                url = 'https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getMultiplesEvaluaciones'
            }
        } 

        this.setState({ loadingevaluaciones: true })
        return fetch(url,{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            
            res.map((info,ival) => {

                let valor = `${info.usuarioevaluado.email}`
                if(info.cargo){
                    if(typeof info.cargo === 'object'){
                        valor += ` · ${info.cargo.nombre}`
                    }
                }
                if(info.usuarioevaluado.detallesarea){
                    if(typeof info.usuarioevaluado.detallesarea === 'object'){
                        valor += ` · ${info.usuarioevaluado.detallesarea.nombre}`
                    }
                }

                array.push({ value: info.usuarioevaluado._id['$oid'], label: valor })


                res[ival].buscar = JSON.stringify(info.usuarioevaluado)
            })

            this.setState({ loadingevaluaciones: false, evaluaciones: res, personas: array })
        })
        .catch(error => {
            this.setState({ loadingevaluaciones: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    getMatrices(condicion){

        this.setState({ loadingmatrices: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_VerMatricesHabilidades`,{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            this.setState({ loadingmatrices: false, matrices: res })
        })
        .catch(error => {
            this.setState({ loadingmatrices: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    crearCapacitacion(){
        const { capacitacion, user } = this.state
        
        if(!capacitacion.titulo||!capacitacion.descripcion){
            return toast.error("Todos los campos son requeridos", this.state.toaststyle)
        }
        capacitacion.propietario = user.propietario
        capacitacion.status = '1'
        capacitacion.creador = user
        capacitacion.creado = this.formatDate(new Date())

        if(capacitacion.usuarios.length < 1) return toast.error("No hay usuarios en el filtro", this.state.toaststyle)
        
        this.setState({ guardandocapacitacion: true })
        return fetch('https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_CrearCapacitacion',{
            method: 'POST',
            body: JSON.stringify(capacitacion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            toast.success('Creado exitosamente', this.state.toaststyle)
            this.setState({ guardandocapacitacion: false, capacitacion: { usuarios: [] } })
        })
        .catch(error => {
            this.setState({ guardandocapacitacion: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    calcularHabilidadesReprobadas(evaluaciones,tipo){
        const usuarios = []
        evaluaciones.map(evaluacion => {
            if(evaluacion.matriz){
                evaluacion.matriz.esquema.niveles.map((nivel,inivel) => {
                    return nivel.niveles ? nivel.niveles.map((lev,levi) => {
                            if(lev.niveles.length < 1) return false
                            return lev.niveles ? lev.niveles.map((level,leveli) => {
                                        return level.habilidades ? level.habilidades.map((hab,ihab) => {
                                            const valor_esperado = parseInt(hab.esperado)
                                            const valor_logrado = parseInt(hab.logrado)
                                            if(isNaN(valor_esperado) === true || isNaN(valor_logrado) === true) return false
                                            if(valor_logrado < valor_esperado){
                                                if(!evaluacion.usuarioevaluado ) return false
                                                if( typeof evaluacion.usuarioevaluado !== 'object' ) return false
                                                const validar_si_user_existe = usuarios.findIndex(us => us.id === evaluacion.usuarioevaluado._id['$oid'])
                                                if(validar_si_user_existe > -1){
                                                    usuarios[validar_si_user_existe].habilidades.push(hab)
                                                } else {
                                                    usuarios.push({
                                                        id: evaluacion.usuarioevaluado._id['$oid'],
                                                        tipo,
                                                        habilidades: [
                                                            hab
                                                        ]
                                                    })
                                                }
                                            }
                                        }) : false
                                    }) : false
                                }) : false
                })
            }
        })
        return usuarios
    }

    handleChangeCap(e){
        const { name, value } = e.target
        const { capacitacion, personas, evaluaciones } = this.state
        capacitacion[name] = value

        if(name==='subtipo'){
            if(value==='todos los usuarios'){
                let array = []
                if(personas.length > 0){
                    personas.map(user => {
                      array.push(user.value)
                    })
                  }  
                capacitacion.usuarios = array
            } else if(value==='escoger'){
                capacitacion.usuarios = []
            } else if(value==='habilidades-reprobadas'){
                const datos = this.calcularHabilidadesReprobadas(evaluaciones,'habilidades-reprobadas')
                let users = []
                datos.map(us => users.push(us.id))
                capacitacion.usuarios = users
                capacitacion.meta = datos
            } else {
                let array_a = []
                const filtrar_a = evaluaciones.filter(ev => ev.categoria === value)
                filtrar_a.map(eva => {
                    array_a.push(eva.usuarioevaluado._id['$oid'])
                })
                capacitacion.usuarios = array_a
            }
        }
        console.log(capacitacion)
        return this.setState({ capacitacion })
    }

    ifSePermitePlanificacion(){
        const { permitir_planificacion, capacitacion, personas } = this.state

        if(!permitir_planificacion) return false

        return <Card className="mb-3">
        <Card.Body>
        <h2>Capacitaciones</h2>
        <h4>Crear nueva</h4>

        <Row>
        <Col md={4} >
            <div className="form-group">
            <label className="form-control-label d-block">Título</label>
            <input name="titulo" className="form-control" value={capacitacion.titulo ? capacitacion.titulo : ''} onChange={this.handleChangeCap} />
            </div>
        </Col>

        <Col md={4} >
        <div className="form-group">
            <label className="form-control-label d-block">Descripción</label>
            <input name="descripcion" className="form-control" value={capacitacion.descripcion ? capacitacion.descripcion : ''} onChange={this.handleChangeCap} />
            </div>
        </Col>
        <Col md={4} >
        <div className="form-group">
            <label className="form-control-label d-block">Escoger usuarios</label>
            <select className="form-control" name="subtipo" value={capacitacion.subtipo ? capacitacion.subtipo : ''} onChange={this.handleChangeCap}>
                <option value="">Selecciona una opción</option>
                <option value="todos los usuarios">Todos los usuarios</option>
                <option value="escoger">Escoger</option>
                <option value="habilidades-reprobadas">Con habilidades reprobadas</option>
                <option value="A">Todos los A</option>
                <option value="B">Todos los B</option>
                <option value="C">Todos los C</option>
                <option value="D">Todos los D</option>
                <option value="E">Todos los E</option>
            </select>
            </div>
        </Col>
        {
  capacitacion.subtipo === 'escoger' ? <Col md={12} >
  <div className="form-group">
      <label className="form-control-label d-block">Selecciona los usuarios a los cuales está dirigida la evaluación en esta planificación</label>
      <Select
        isMulti={true} 
        options={personas} 
        placeholder="Seleccione los usuarios"
        onChange={this.handleChangeEmails}
        />
  </div>
  </Col> : false
}
        <Col md={12} >
           <h4>{capacitacion.usuarios.length} Usuarios</h4>
           <button className="btn btn-success" onClick={()=> this.crearCapacitacion()}>CREAR CAPACITACIÓN</button>
        </Col>
        </Row>

        </Card.Body>

        </Card>

    }

    getCargos(condicion){
        this.setState({ loadingcargos: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_GetCargos`,{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            this.setState({ loadingcargos: false, cargos: res })
        })
        .catch(error => {
            this.setState({ loadingcargos: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    getRoles(tipo){

        this.setState({ loadingcargos: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getRolesUsuario?tipo=${tipo}`)
        .then(res => res.json())
        .then(res => {
            this.setState({ loadingcargos: false, roles: res })
        })
        .catch(error => {
            this.setState({ loadingcargos: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    guardarCrear(){
        const { cargo, user } = this.state
        
        if(!cargo.nombre||!cargo.idmatriz||!cargo.areaasignada){
            return alert("Todos los campos son requeridos")
        }
        cargo.propietario = user.propietario
        cargo.status = '1'
        cargo.creador = user
        this.setState({ guardandocambios: true })
        return fetch('https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_EditarCrearCargo',{
            method: 'POST',
            body: JSON.stringify(cargo),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            const texto = cargo._id ? 'Rol modificado exitosamente' : 'Rol creado exitosamente'
            toast.success(texto, this.state.toaststyle)
            this.setState({ guardandocambios: false, area: {} })
            this.getCargos({ propietario: user.propietario })
        })
        .catch(error => {
            this.setState({ guardandocambios: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    fetchUsuarios(condicion){
        this.setState({ loadingUsuarios: true })
        return fetch('https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getUsersCustom',{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            this.setState({ loadingUsuarios: false, usuarios: res })
        })
        .catch(error => {
            this.setState({ loadingUsuarios: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    statusUser(tipo){
        switch (tipo) {
            case '1':
                    return <small className="text-success"><i className="far fa-check-circle"></i> APROBADA</small>
                    break;
            default:
                return <small ><i className="far fa-clock"></i> EN REVISIÓN</small>
                break;
        }
    }

    tipoUser(tipo){
        switch (tipo) {
            case 'admin':
                    return 'Administrador'
                    break;
            case 'evaluator':
                    return 'Evaluador'
                    break;
            case 'empleado':
                    return 'Usuario'
                    break;        
            default:
                return tipo
                break;
        }
    }

    formularioNuevoUser(){
        const { showcreacion, cargos, cargo, areas, guardandocambios, matrices } = this.state

        if(guardandocambios){
            return <div>
            <Spinner animation="border" />
            <h3>Guardando información...</h3>
        </div>
        }
        if(showcreacion){

            return <Card className="card-blue">
                
                <Card.Body className="text-white ">
                <Row>
                <Col md={12} >
                { cargo._id ? <h3>Editar evaluación {cargo.nombre ? cargo.nombre : 'usuario'} </h3> : <h3>Crear evaluación</h3> }
                </Col>

<Col md={3} >
    <label className="form-control-label d-block">Nombre</label>
    <input name="nombre" className="form-control" value={cargo.nombre ? cargo.nombre : ''} onChange={this.handleChangeRol} />
</Col>

<Col md={6} >
    <label className="form-control-label d-block">Descripción</label>
    <input name="descripcion" className="form-control" value={cargo.descripcion} onChange={this.handleChangeRol} />
</Col>

<Col md={3} >
    <label className="form-control-label d-block">Cargo</label>
    <select name="areaasignada" className="form-control" value={cargo.areaasignada ? cargo.areaasignada : ''} onChange={this.handleChangeRol} >
        <option value="">Seleccione</option>
        {
            cargos.length > 0 ? cargos.map(puesto => {
                return <option value={puesto._id['$oid']}> {puesto.nombre} </option>
            }) : false
        }
    </select>
</Col>

<Col md={3}>
<label className="form-control-label d-block">Click para { cargo._id ? 'editar' : 'crear' } </label>
    <Button style={{ background: 'white' }} onClick={()=>this.guardarCrear()} variant="outline-white">{ cargo._id ? 'GUARDAR CAMBIOS' : 'CREAR' }</Button>
</Col>

<Col xs={12}>
<p className="m-0 onclick" onClick={()=>this.setState({ cargo: {} })}>Reiniciar formulario</p>

</Col>

</Row>
                </Card.Body>
            </Card>
        }


    }


    tablaDetalle(evaluacionesFiltradas){

        return evaluacionesFiltradas.map((cargo,i)=>{
        
        
            let usuariosevaluados = ''
    
            if(cargo.modalidad==='autoevaluacion'){
                usuariosevaluados = `${cargo.usuarioevaluado.nombres} - ${cargo.usuarioevaluado.rut}`
            } else if(cargo.modalidad === 'evaluacion') {
                if(cargo.listausuarios){
                    if(cargo.listausuarios.length > 0){
                        cargo.listausuarios.map(u => {
                            usuariosevaluados = usuariosevaluados + `${u.nombres}, `
                        })
                    }
                }
            }
            return <Card key={`user${i}`} >
                <Card.Body>
                <Row>
                    <Col xs={3}>
                        <h6>Fecha de creación</h6>
                        <h4>{cargo.creado}</h4>
                    </Col>
                    <Col xs={3}>
                        <h6>Estado</h6>
                        <h4>{this.statusUser(cargo.status)}</h4>
                    </Col>
                    <Col xs={3}>
                        <h6>Modalidad</h6>
                        <h4>{cargo.modalidad}</h4>
                    </Col>
                    <Col xs={3}>
                        <h6>Evaluado</h6>
                        <p>{usuariosevaluados}</p>
                    </Col>
                    <Col xs={3}>
                        <h6>Creador</h6>
                        <h4>{cargo.creador ? `${cargo.creador.nombres} ${cargo.creador.apellidopaterno}` : 'No encontrado'}</h4>
                    </Col>
                    <Col xs={2}>
                        <h6>Llenado</h6>
                        <h4>{cargo.porcentajellenado ? `${cargo.porcentajellenado}%` : 'Sin data' }</h4>
                    </Col>
                    <Col xs={2}>
                        <h6>Correctas</h6>
                        <h4>{cargo.porcentajecorrectas ? `${cargo.porcentajecorrectas}%` : 'Sin data' }</h4>
                    </Col>
                    <Col xs={2}>
                        <h6>Promedio</h6>
                        <h4>{cargo.resultadopromedio ? `${cargo.resultadopromedio}` : 'Sin data' }</h4>
                    </Col>
                    {
                        cargo.status === '1' ? <Col xs={2}>
                        <h6>Categoría</h6>
                        <h4 className="categoriaspan">{cargo.categoria ? cargo.categoria : 'Sin data'}</h4>
                    </Col> : false
                    }
                    <Col xs={12}><Link to={`detalle-evaluacion-${cargo._id['$oid']}`}> <button className="btn btn-outline-primary">Ver detalles</button> </Link></Col>
                </Row>
                </Card.Body>
                </Card>
        })
    }

    tablaListado(evaluacionesFiltradas){

        return <div>

<div className="form-group">
<label className="form-control-label">Filtrar resultados</label>
<input name="filtroevaluaciones" className="form-control mb-4" placeholder="Escribe cualquier palabra clave" onChange={this.handleChange} />
</div>

<Row>

        <Col xs={3}>
        <div className="form-group">
        <label className="form-control-label">Filtrar por tipo</label>
        <select name="filtrotipo" className="form-control"  onChange={this.handleChange} >
                <option value="">Todo</option>
                <option value="simple">Evaluación</option>
                <option value="insignia">Insignias</option>
            </select>
        </div>
        </Col>

        <Col xs={3}>
        <div className="form-group">
        <label className="form-control-label">Filtrar por categoria</label>
        <select name="filtrocategoria" className="form-control"  onChange={this.handleChange} >
                <option value="">Todo</option>
                <option value="A">A</option>
                <option value="B">B</option>
                <option value="C">C</option>
                <option value="D">D</option>
                <option value="E">E</option>
            </select>
        </div>
        </Col>

        <Col xs={12}>
            <p className="etiquetaclass">{evaluacionesFiltradas.length} coincidencias</p>
        </Col>

</Row>


<Table responsive hover>
        <thead>
          <tr>
            <th>Título</th>
            <th>Creación</th>
            <th>Estado</th>
            <th>Modalidad</th>
            <th>Tipo</th>
            <th>Llenado</th>
            <th>Correctas</th>
            <th>Promedio</th>
            <th>Categoría</th>
            <th>Usuarios evaluados</th>
          </tr>
        </thead>
        <tbody>
          { evaluacionesFiltradas.map((cargo,i)=>{
              
              
              let usuariosevaluados = ''
      
              if(cargo.modalidad==='autoevaluacion'){
                  usuariosevaluados = `${cargo.usuarioevaluado.nombres} - ${cargo.usuarioevaluado.rut}`
              } else if(cargo.modalidad === 'evaluacion') {
                  if(cargo.listausuarios){
                      if(cargo.listausuarios.length > 0){
                          cargo.listausuarios.map(u => {
                              usuariosevaluados = usuariosevaluados + `${u.nombres}, `
                          })
                      }
                  }
              }
              return <tr key={`user${i}`}>
                  <th>{cargo.titulo}<br/> <Link className="onclick" to={`detalle-evaluacion-${cargo._id['$oid']}`}>Detalles</Link></th>
                  <th>{cargo.creado}  </th>
                  <th>{this.statusUser(cargo.status)}</th>
                  <th>{cargo.modalidad}</th>
                  <th><span className="spanclass" style={{ background: cargo.tipo === 'simple' ? '#208bff' : '#ffcb0f' }}>{cargo.tipo}</span></th>
                  <th>{cargo.porcentajellenado ? `${cargo.porcentajellenado}%` : 'Sin data' }</th>
                  <th>{cargo.porcentajecorrectas ? `${cargo.porcentajecorrectas}%` : 'Sin data' }</th>
                  <th>{cargo.resultadopromedio ? `${cargo.resultadopromedio}` : 'Sin data' }</th>
                  <th>{cargo.categoria ? <h6 className="categoriaspan">{cargo.categoria}</h6> : 'Sin data'}</th>
                  <th>{usuariosevaluados}</th>
              </tr>
          }) }
        </tbody>
      </Table>
        </div>
      
    }

    mostrarEvaluacionesByTipoUser(tipo, evaluacionesFiltradas){

        switch(tipo){
            case 'empleado':
                return this.tablaDetalle(evaluacionesFiltradas);
                default:
                    return this.tablaListado(evaluacionesFiltradas);
        }
    }

    mostrarEvaluaciones(){
        const { loadingplanificacion, filtroevaluaciones, planificacion, filtrotipo, opcionesfecha, filtrocategoria, habilitarnuevaevaluacion, evaluaciones, user } = this.state

        if(loadingplanificacion){
            return <div>
                <Spinner animation="border" />
                <h3>Cargando información</h3>
            </div>
        }

        let rangoactual = ''

        if(planificacion.startDate){
            const stringdesde = new Date(planificacion.startDate).toLocaleDateString('es-ES', opcionesfecha)
            rangoactual += `Desde ${stringdesde}`
        }

        if(planificacion.endDate){
            const stringhasta = new Date(planificacion.endDate).toLocaleDateString('es-ES', opcionesfecha)
            rangoactual += ` hasta ${stringhasta}`
        }

        if(planificacion.startDate === planificacion.endDate){
            const stringunicafecha = new Date(planificacion.startDate).toLocaleDateString('es-ES', opcionesfecha)
            rangoactual = ''
            rangoactual = stringunicafecha
        }

        const lowercasedFilter = filtroevaluaciones.toLowerCase();
        const evaluacionesFiltradasByText = evaluaciones.filter(item => {
            return Object.keys(item).some(key => {
                    if(!item[key]) return false
                    return item[key].toString().toLowerCase().includes(lowercasedFilter)
                }
            );
        });
        const evaluacionesByCat = filtrocategoria ? evaluacionesFiltradasByText.filter(ev => ev.categoria === filtrocategoria) : evaluacionesFiltradasByText
        const evaluacionesFiltradas = filtrotipo ? evaluacionesByCat.filter(ev => ev.tipo === filtrotipo) : evaluacionesByCat
        
        return <div>

<Card style={{ width: '100%', marginBottom: 20 }}>



                            


        <Card.Body>
          <Card.Title style={{ float: 'left' }}>{evaluaciones.length} evaluaciones encontradas</Card.Title>
          { /* habilitarnuevaevaluacion === true ? <Link to="/crear-evaluacion" style={{ float: 'right', marginBottom: 15 }}  className="btn btn-outline-primary">NUEVA EVALUACIÓN</Link> : false */ }

          <div style={{ clear: 'both' }}><hr /></div>

          {
    planificacion ? <div>
        <h2>{planificacion.titulo}</h2>
        <p style={{ marginBottom: 0 }}>{planificacion.descripcion}</p>
        <p style={{ marginBottom: 0 }}>{rangoactual}</p>
    </div> : false
}

        {this.formularioNuevoUser()}
          <Row>

            
            {filtroevaluaciones !== '' ? <Col xs={12}><label className="form-control-label">{evaluacionesFiltradas.length} Coindicencias</label></Col>:false}
          
          </Row>          

        { this.mostrarEvaluacionesByTipoUser(user.tipo, evaluacionesFiltradas) }
        
        </Card.Body>
      </Card>

{this.mostrarReportes()}
</div>
    }

    ifAreaExist(idarea){
        const { areas } = this.state

        if(areas.length > 0){
            const buscar = areas.findIndex(ma => ma._id['$oid'] === idarea)
            if(areas[buscar]){
                return areas[buscar].nombre
            }
        }

        return 'No encontrado'
    }

    ifMatrixExist(idmatriz){
        const { matrices } = this.state

        if(matrices.length > 0){
            const buscar = matrices.findIndex(ma => ma._id['$oid'] === idmatriz)
            if(matrices[buscar]){
                return matrices[buscar].nombre
            }
        }

        return 'No encontrado'
    }

    mostrarCalificacion(){
        const { loadingclasificacion, clasificacion } = this.state

        if(loadingclasificacion){
            return <div>
            <Spinner animation="border" />
            <h3>Cargando información...</h3>
        </div>
        }

        return <Card className="mb-3 cardblue">
        <Card.Body>
        
        <Row className="leyenda">
        <Col md={12}>
                <h4 className="text-white">Definición de clasificación</h4>
                <hr />
            </Col>
        
            <Col md={2}>
                <h3 className="etiqueta">A</h3>
                <p>{clasificacion.a.texto}</p>
            </Col>
        
            <Col md={2}>
                <h3 className="etiqueta">B</h3>
                <p>{clasificacion.b.texto}</p>
            </Col>
        
            <Col md={2}>
                <h3 className="etiqueta">C</h3>
                <p>{clasificacion.c.texto}</p>
            </Col>
        
            <Col md={2}>
                <h3 className="etiqueta">D</h3>
                <p>{clasificacion.d.texto}</p>
            </Col>
            <Col md={2}>
                <h3 className="etiqueta">E</h3>
                <p>{clasificacion.e.texto}</p>
            </Col>
            <Col md={12}>
                <p style={{fontSize: 14, fontWeight: 'lighter' }}>{clasificacion.informacion_adicional}</p>
            </Col>
        </Row>
        
        </Card.Body>
                    </Card>


    }
    
    render(){

        const {miusuario} = this.props
        const { errorLogin } = this.state
        return(
            <div className="fluid">
       <Header/>   
       <ToastContainer />
<div className="container-fluid">
  <div className="row">
   <Nav/>
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Evaluaciones</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
        </div>
      </div>

      {this.ifSePermitePlanificacion()}
      {this.mostrarEvaluaciones()}
      
      {this.mostrarCalificacion()}
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(DetallePlanificacion);