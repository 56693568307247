import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button } from 'react-bootstrap'
import Header from '../Header'
import Nav from '../Nav'
import Spinner from 'react-bootstrap/Spinner'
import Table from 'react-bootstrap/Table'
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Toast from 'react-bootstrap/Toast'
import { Chart } from "react-google-charts";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { calcularHabilidadesReprobadas }  from '../../helpers/index'

class Informes extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            matrices: [],
            loadingUsuarios: true,
            filtroevaluaciones: '',
            cargo: {},
            showcreacion: false,
            loadingcargos: true,
            cargos: [],
            areas: [],
            loadingareas: true,
            loadingmatrices: true,
            evaluaciones: [],
            reporte: [],
            toaststyle: { position: toast.POSITION.BOTTOM_RIGHT },
            habilitarnuevaevaluacion: false,
            conseciones: false,
            filtrotipo: '',
            filtrocategoria: '',
            reporteinsignia: [],
            loadingreporteinsignia: true,
            totalinsigniasaprobadas: [],
            filtroinsignia: '',
            loadingGraficos: false,
            graficos: true,
            capacitacion: { usuarios: [] },
            mostrar_habilidades: 'Habilidades reprobadas',
            tipovisualizacion: 'informes',
            usuarios_seleccionados: [],
            area_busqueda: '',
            cargo_busqueda: '',
            filtro_habilidades: ''
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeRol = this.handleChangeRol.bind(this)
        this.handleChangeCap = this.handleChangeCap.bind(this)
        this.handleChangeActualizar = this.handleChangeActualizar.bind(this)
    }

    handleChangeCap(e){
        const { name, value } = e.target
        const { capacitacion } = this.state
        capacitacion[name] = value
        return this.setState({ capacitacion })
    }

    handleChangeActualizar(e){
        const { name, value } = e.target
        this.setState({ [name]: value })
        const { area_busqueda, cargo_busqueda, user } = this.state

        if(name === "area_busqueda"){

                return this.getGraficos(user.propietario, value, cargo_busqueda)
   
        } else if(name === "cargo_busqueda"){

                               return this.getGraficos(user.propietario, area_busqueda, value )

        }

                 return this.getGraficos(user.propietario, area_busqueda, cargo_busqueda)
    }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    handleChangeRol(e){
        const { name, value } = e.target
        const { cargo } = this.state
        cargo[name] = value
        return this.setState({ cargo: cargo })
    }

    componentDidMount(){
        const { user, area_busqueda, cargo_busqueda } = this.state
        this.getCargos({ propietario: user.propietario })
        this.getMatrices({ propietario: user.propietario })
        // user.tipo = 'usuario' // evitar
        if(user.tipo==='admin'){
            this.getEvaluaciones({ propietario: user.propietario })
        } else if(user.tipo==='evaluator'){
            if(user.area){
                this.getEvaluaciones({ "cargo.areaasignada": user.area, propietario: user.propietario, tipo: 'simple' })
            }
        } else if(user.tipo==='empleado'){
            this.getEvaluaciones({ "usuarioevaluado.id": user._id['$oid'], propietario: user.propietario, tipo: 'simple' })
        }
        this.getConseciones(user.propietario)
        this.getAreas(user.propietario)
        this.getReporte(user.propietario, 'simple', 'loadingreporte', 'reporte')
        this.getReporte(user.propietario, 'insignia', 'loadingreporteinsignia', 'reporteinsignia')
        this.getInsigniasAprobadas(user.propietario)
        this.getGraficos(user.propietario, area_busqueda, cargo_busqueda)
    }

    getGraficos(propietario, area_busqueda, cargo_busqueda){

        console.log(propietario, area_busqueda, cargo_busqueda)
        this.setState({ loadingGraficos: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/getInformesV2?propietario=${propietario}&areabusqueda=${area_busqueda}&cargobusqueda=${cargo_busqueda}`)
        .then(res => res.json())
        .then(async res => {
            this.setState({ graficos: res, loadingGraficos: false })
        })
        .catch(error => {
            this.setState({ loadingGraficos: false })
            return toast.error("Error al consultar la información de los gráficos, intente recargar la página", this.state.toaststyle)
        })
    }

    checkIfNuevaEvaluacion(){
        const { user, conseciones } = this.state
        if(!user.area) return false
        if(!conseciones) return false
        if(!conseciones.areas) return false
        const buscararea = conseciones.areas.filter(ar => ar.idarea === user.area)
        if(buscararea.length > 0){
            return this.setState({ habilitarnuevaevaluacion: buscararea[0].activo })
        }
    }

    async getInsigniasAprobadas(propietario){
        this.setState({ loadinginsignias: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getTotalInsigniasAprobadas?propietario=${propietario}`)
        .then(res => res.json())
        .then(async res => {
            const datos = JSON.parse(res)
            console.log(datos)
            this.setState({ totalinsigniasaprobadas: datos, loadinginsignias: false })
        })
        .catch(error => {
            this.setState({ loadinginsignias: false })
            return toast.error("Error al consultar la información de insignias, intente recargar la página", this.state.toaststyle)
        })
  
    }

    async getConseciones(propietario){
        
        this.setState({ loadingconseciones: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_campanasReclutamiento?propietario=${propietario}`)
        .then(res => res.json())
        .then(async res => {
            this.setState({ conseciones: res, loadingconseciones: false })
            this.checkIfNuevaEvaluacion()
        })
        .catch(error => {
            this.setState({ loadingconseciones: false })
            return toast.error("Error al consultar la información, intente recargar la página", this.state.toaststyle)
        })
  
    }

    getReporte(propietario, tipo, loading, estado){

        this.setState({ [loading]: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_GetReportesEvaluaciones?propietario=${propietario}&tipo=${tipo}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ [loading]: false, [estado]: res })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    getAreas(propietario){

        this.setState({ loadingareas: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getAreas?propietario=${propietario}`)
        .then(res => res.json())
        .then(res => {
            this.setState({ loadingareas: false, areas: res })
        })
        .catch(error => {
            this.setState({ loadingareas: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    getEvaluaciones(condicion){
        const { user } = this.state
        let url = 'https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_VerEvaluaciones'

        if(user.tipo==='admin'){
            url = 'https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getMultiplesEvaluaciones'
        } else if(user.tipo==='evaluator'){
            if(user.area){
                url = 'https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getMultiplesEvaluaciones'
            }
        } 

        this.setState({ loadingevaluaciones: true })
        return fetch(url,{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            
            res.map((info,ival) => {
                res[ival].buscar = JSON.stringify(info.usuarioevaluado)
            })

            this.setState({ loadingevaluaciones: false, evaluaciones: res })
        })
        .catch(error => {
            this.setState({ loadingevaluaciones: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    getMatrices(condicion){

        this.setState({ loadingmatrices: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_VerMatricesHabilidades`,{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            this.setState({ loadingmatrices: false, matrices: res })
        })
        .catch(error => {
            this.setState({ loadingmatrices: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    getCargos(condicion){
        this.setState({ loadingcargos: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_GetCargos`,{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            this.setState({ loadingcargos: false, cargos: res })
        })
        .catch(error => {
            this.setState({ loadingcargos: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    getRoles(tipo){

        this.setState({ loadingcargos: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getRolesUsuario?tipo=${tipo}`)
        .then(res => res.json())
        .then(res => {
            this.setState({ loadingcargos: false, roles: res })
        })
        .catch(error => {
            this.setState({ loadingcargos: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    guardarCrear(){
        const { cargo, user } = this.state
        
        if(!cargo.nombre||!cargo.idmatriz||!cargo.areaasignada){
            return alert("Todos los campos son requeridos")
        }
        cargo.propietario = user.propietario
        cargo.status = '1'
        cargo.creador = user
        this.setState({ guardandocambios: true })
        return fetch('https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_EditarCrearCargo',{
            method: 'POST',
            body: JSON.stringify(cargo),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            const texto = cargo._id ? 'Rol modificado exitosamente' : 'Rol creado exitosamente'
            toast.success(texto, this.state.toaststyle)
            this.setState({ guardandocambios: false, area: {} })
            this.getCargos({ propietario: user.propietario })
        })
        .catch(error => {
            this.setState({ guardandocambios: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    fetchUsuarios(condicion){
        this.setState({ loadingUsuarios: true })
        return fetch('https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getUsersCustom',{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            this.setState({ loadingUsuarios: false, usuarios: res })
        })
        .catch(error => {
            this.setState({ loadingUsuarios: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    statusUser(tipo){
        switch (tipo) {
            case '1':
                    return <small className="text-success"><i className="far fa-check-circle"></i> APROBADA</small>
                    break;
            default:
                return <small ><i className="far fa-clock"></i> EN REVISIÓN</small>
                break;
        }
    }

    tipoUser(tipo){
        switch (tipo) {
            case 'admin':
                    return 'Administrador'
                    break;
            case 'evaluator':
                    return 'Evaluador'
                    break;
            case 'empleado':
                    return 'Usuario'
                    break;        
            default:
                return tipo
                break;
        }
    }

    formularioNuevoUser(){
        const { showcreacion, cargos, cargo, areas, guardandocambios, matrices } = this.state
        if(guardandocambios){
            return <div>
            <Spinner animation="border" />
            <h3>Guardando información...</h3>
        </div>
        }
        if(showcreacion){
            return <Card className="card-blue">
                <Card.Body className="text-white ">
                <Row>
                <Col md={12} >
                { cargo._id ? <h3>Editar evaluación {cargo.nombre ? cargo.nombre : 'usuario'} </h3> : <h3>Crear evaluación</h3> }
                </Col>
<Col md={3} >
    <label className="form-control-label d-block">Nombre</label>
    <input name="nombre" className="form-control" value={cargo.nombre ? cargo.nombre : ''} onChange={this.handleChangeRol} />
</Col>
<Col md={6} >
    <label className="form-control-label d-block">Descripción</label>
    <input name="descripcion" className="form-control" value={cargo.descripcion} onChange={this.handleChangeRol} />
</Col>
<Col md={3} >
    <label className="form-control-label d-block">Cargo</label>
    <select name="areaasignada" className="form-control" value={cargo.areaasignada ? cargo.areaasignada : ''} onChange={this.handleChangeRol} >
        <option value="">Seleccione</option>
        {
            cargos.length > 0 ? cargos.map(puesto => {
                return <option value={puesto._id['$oid']}> {puesto.nombre} </option>
            }) : false
        }
    </select>
</Col>
<Col md={3}>
<label className="form-control-label d-block">Click para { cargo._id ? 'editar' : 'crear' } </label>
    <Button style={{ background: 'white' }} onClick={()=>this.guardarCrear()} variant="outline-white">{ cargo._id ? 'GUARDAR CAMBIOS' : 'CREAR' }</Button>
</Col>
<Col xs={12}>
<p className="m-0 onclick" onClick={()=>this.setState({ cargo: {} })}>Reiniciar formulario</p>
</Col>
</Row>
                </Card.Body>
            </Card>
        }
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear()
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        return [year, month, day].join('-');
    }

    mostrarInsigniasAprobadas(){
        const { totalinsigniasaprobadas, filtroinsignia } = this.state
        const lowercasedFilter = filtroinsignia .toLowerCase();
        const evaluacionesFiltradas = totalinsigniasaprobadas.filter(item => {
            return Object.keys(item.informacioninsignia).some(key =>
                item.informacioninsignia[key].toString().toLowerCase().includes(lowercasedFilter)
            );
        });

        return <Card>
            <Card.Body>
            <h3 style={{ fontWeight:'lighter' }}>{totalinsigniasaprobadas.length} insignias aprobadas <b>en total</b></h3>
            <div className="form-group">
            <label className="form-control-label d-block">Filtrar resultados</label>
            <input name="filtroinsignia" className="form-control" onChange={this.handleChange} />
            </div>
            <hr />
            <Row>
            {
                evaluacionesFiltradas.map(insignia =>  {
                    return <Col md={6} className="mb-3">
                        <Toast>
                    <Toast.Header>
                      <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
                      <strong className="mr-auto">{insignia.informacioninsignia.nombre}</strong>
                      <small>{this.formatDate(insignia.creacion)}</small>
                    </Toast.Header>
                    <Toast.Body>{insignia.informacioninsignia.descripcion}<hr />
                    <h5 className="nomargin">Evaluador</h5>
                    <p className="nomargin">{insignia.evaluador.nombres} {`${insignia.evaluador.apellidopaterno} ${insignia.evaluador.apellidomaterno}`} </p>
                    </Toast.Body>
                  </Toast>
                    </Col>
                })
            }
            </Row>
            </Card.Body>
        </Card>
    }

    mostrarReportesInsignia(){
        const { loadingreporteinsignia, reporteinsignia } = this.state
        if(loadingreporteinsignia){
            return <div>
                <Spinner animation="border" />
                <h3>Cargando insignias</h3>
            </div>
        }
        if(reporteinsignia.length > 0){
            return <Card className="mb-3">
<Card.Body>
    <img src="icon6.jpg" className="iconsystem" />
<h4>Estadísticas de evaluaciones de insignias</h4>

<Table responsive hover className="mb-0">
            <thead>
              <tr>
                <th>Área</th>
                <th>Evaluados</th>
                <th>No evaluados</th>
                <th>A</th>
                <th>B</th>
                <th>C</th>
                <th>D</th>
                <th>E</th>
                <th>Pendiente</th>
              </tr>
            </thead>
            <tbody>
              { reporteinsignia.map((area,i)=>{
            let contarusuarios = {}
            let a = 0
            let b = 0
            let c = 0
            let d = 0
            let e = 0
            let pendientes = 0
            const usuariostotales = parseInt(area.usuarios ? area.usuarios : 0)
            if(area.evaluaciones){
                if(area.evaluaciones.length){
                    area.evaluaciones.map(ev=>{
                        contarusuarios[ev.usuarioevaluado.email] = ev.usuarioevaluado.email
                        if(!ev.categoria) return false
                        if(ev.categoria.toLowerCase() ==='a'){
                            a++
                        } else if(ev.categoria.toLowerCase() ==='b'){
                            b++
                        } else if(ev.categoria.toLowerCase() ==='c'){
                            c++
                        } else if(ev.categoria.toLowerCase() ==='d'){
                            d++
                        } else if(ev.categoria.toLowerCase() ==='e'){
                            e++
                        } else {
                            pendientes++
                        }
                    })
                }
            }
                  return <tr key={`area${i}`}>
                      <th>{area.nombre}</th>
                      <th>{Object.keys(contarusuarios).length}</th>
                      <th>{usuariostotales - Object.keys(contarusuarios).length}</th>
                      <th>{a}</th>
                      <th>{b}</th>
                      <th>{c}</th>
                      <th>{d}</th>
                      <th>{e}</th>
                      <th>{pendientes}</th>
                  </tr>
              }) }
            </tbody>
          </Table>
</Card.Body>
            </Card>
        }
    }

    crearCapacitacion(){
        const { capacitacion, user } = this.state
        
        if(!capacitacion.titulo||!capacitacion.descripcion){
            return toast.error("Todos los campos son requeridos", this.state.toaststyle)
        }
        capacitacion.propietario = user.propietario
        capacitacion.status = '1'
        capacitacion.creador = user
        capacitacion.creado = this.formatDate(new Date())

        if(capacitacion.usuarios.length < 1) return toast.error("No hay usuarios en el filtro", this.state.toaststyle)
        
        this.setState({ guardandocapacitacion: true })
        return fetch('https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_CrearCapacitacion',{
            method: 'POST',
            body: JSON.stringify(capacitacion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            toast.success('Creado exitosamente', this.state.toaststyle)
            this.setState({ guardandocapacitacion: false, capacitacion: { usuarios: [] } })
        })
        .catch(error => {
            this.setState({ guardandocapacitacion: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }


    seleccionarUsuariosDeHabilidades(habilidades){
        const { mostrar_habilidades } = this.state
        const usuarios = []
        if(mostrar_habilidades === 'Habilidades reprobadas'){
            habilidades.map(habi => {
                habi.reprobados.map(campo => {
                    usuarios.push(campo)
                })
            })
        } else if(mostrar_habilidades === 'Habilidades aprobadas'){
            habilidades.map(habi => {
            habi.logrados.map(campo => {
                usuarios.push(campo)
            })
            })
        } else if(mostrar_habilidades === 'Habilidades sobre calificadas'){
            habilidades.map(habi => {
            habi.mejores.map(campo => {
                usuarios.push(campo)
            })
            })
        }
        let idsusuarios = []
        usuarios.map(user => idsusuarios.push(user._id['$oid']))
        return this.consolidarUsuariosCapacitacion(idsusuarios,usuarios)
    }

    enviarACapacitacion(usuarios){
        let idsusuarios = []
        usuarios.map(user => idsusuarios.push(user._id['$oid']))
        return this.consolidarUsuariosCapacitacion(idsusuarios,usuarios)
    }

    consolidarUsuariosCapacitacion(idsusuarios,usuarios){

        if(idsusuarios.length < 1) return toast.error('Sin usuarios seleccionados', this.state.toaststyle)

        this.setState({ tipovisualizacion: 'capacitacion', capacitacion: { usuarios: idsusuarios }, usuarios_seleccionados: usuarios })


    }

    procesarHabilidadesParaMostrar(habilidades, mostrar_habilidades){
        const datos = []
        habilidades.map(hab => {
            if(!hab._id) return false
            const buscar = datos.findIndex(da => da._id['$oid'] === hab._id['$oid'])
            let habilidad = hab
                if(mostrar_habilidades === 'Habilidades reprobadas'){
                    if(Array.isArray(hab.reprobados !== true)) return false
                    if(hab.reprobados.length > 0){
                        habilidad.usuarios = hab.reprobados
                    }
                } else if(mostrar_habilidades === 'Habilidades aprobadas'){
                    if(Array.isArray(hab.logrados !== true)) return false
                    if(hab.logrados.length > 0){
                        habilidad.usuarios = hab.logrados
                    }
                } else if(mostrar_habilidades === 'Habilidades sobre calificadas'){
                    if(Array.isArray(hab.mejores !== true)) return false
                    if(hab.mejores.length > 0){
                        habilidad.usuarios = hab.mejores
                    }
                }
                datos.push(habilidad)
        })
        return datos
    }

    mostrarHabilidades(){
        const { loadingGraficos, graficos, filtro_habilidades, mostrar_habilidades } = this.state

        if(loadingGraficos){
            return <div>
                <Spinner animation="border" />
                <h3>Cargando habilidades</h3>
            </div>
        }

        let mostrar = true

        if(!graficos) mostrar = false
        if(!graficos.habilidades) mostrar = false

        
        if(!mostrar) return <Card className="mb-3">
            <Card.Body>
            <h4>Datos no disponibles</h4>
            </Card.Body>
        </Card>

        const total_aprobadas_array = graficos.habilidades.filter(ha => ha.logrados.length > 0)
        const total_reprobadas_array = graficos.habilidades.filter(ha => ha.reprobados.length > 0)
        const total_mejores_array = graficos.habilidades.filter(ha => ha.mejores.length > 0)

        let registros_habilidades = graficos.habilidades.filter(habi => {
            if(mostrar_habilidades === 'Habilidades reprobadas'){
                return habi.reprobados.length > 0
            } else if(mostrar_habilidades === 'Habilidades aprobadas'){
                return habi.logrados.length > 0
            } else if(mostrar_habilidades === 'Habilidades sobre calificadas'){
                return habi.mejores.length > 0
            }
        })

        const lowercasedFilterHabs = filtro_habilidades.toLowerCase();
        let registros_habilidades_filtradas = lowercasedFilterHabs ? registros_habilidades.filter(item => {
            return Object.keys(item).some(key =>
                {
                    if(!item[key]) return false
                    return item[key].toString().toLowerCase().includes(lowercasedFilterHabs)
                }
            );
        }) : registros_habilidades
        
        const habilidades_procesar = this.procesarHabilidadesParaMostrar(registros_habilidades_filtradas,mostrar_habilidades) 

        return <Card className="mb-3">
    <Card.Body>
    <Row>
        <Col xs={12}>
            <Row>
                <Col xs={4}>
                    <Card
                        border="danger"
                        text="danger"
                        className="mb-3">
                        <Card.Body>
                            <h5>Reprobadas</h5>
                            <h1 className="mb-0">{total_reprobadas_array.length}</h1>
                            <Button variant="dark" onClick={()=>this.setState({ mostrar_habilidades: 'Habilidades reprobadas' })} >VER</Button>
                        </Card.Body>
                        
                    </Card>
                </Col>
                <Col xs={4}>
                    <Card
                        border="primary"
                        text="primary"
                        className="mb-3">
                        <Card.Body>
                            <h5>Aprobadas</h5>
                            <h1 className="mb-0">{total_aprobadas_array.length}</h1>
                            <Button variant="dark" onClick={()=>this.setState({ mostrar_habilidades: 'Habilidades aprobadas' })} >VER</Button>
                        </Card.Body>
                        
                    </Card>
                </Col>
                <Col xs={4}>
                    <Card
                        border="success"
                        text="success"
                        className="mb-3">
                        <Card.Body>
                            <h5>Sobre calificadas</h5>
                            <h1 className="mb-0">{total_mejores_array.length}</h1>
                            <Button variant="dark" onClick={()=>this.setState({ mostrar_habilidades: 'Habilidades sobre calificadas' })} >VER</Button>
                        </Card.Body>
                        
                    </Card>
                </Col>
            </Row>
        </Col>

        <Col xs={12}>
        <h4>{mostrar_habilidades}</h4>
        <Button variant="outline-primary" className="mb-3" onClick={()=>this.seleccionarUsuariosDeHabilidades(registros_habilidades)} >SELECCIONAR ESTAS PERSONAS PARA CAPACITACIÓN</Button>

        <label className="form-control-label d-block mt-3">Buscar habilidad</label>
        <input className="form-control mb-3" name="filtro_habilidades" placeholder="Filtrar habilidad" onChange={this.handleChange} />

        <div className="table-responsive maxheight-600">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Cantidad</th>
                            <th>Nombre</th>
                            <th>Promedio</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            habilidades_procesar.map((habilidad,iusu) => {
                                let promedio_final = 0
                                
                                return <tr key={`iu${iusu}`}>
                                        <th>{habilidad.usuarios.length}</th>
                                        <th>{habilidad.nombre}</th>
                                        <th><button className="btn btn-sm btn-outline-primary" onClick={()=>this.enviarACapacitacion(habilidad.usuarios)} >ENVIAR A CAPACITACIÓN</button> </th>
                                    </tr>
                            })
                        }
                    </tbody>
                </table>
                </div>
        </Col>
    </Row>
</Card.Body>
        </Card>
    }

    mostrarFiltros(){
        const { areas, cargos, area_busqueda, cargo_busqueda } = this.state


        return <Col xs={12} className="mb-3">
        <Row>
        <Col md={3} >
    <label className="form-control-label d-block">Area de trabajo</label>
    <select name="area_busqueda" className="form-control" value={area_busqueda} onChange={this.handleChangeActualizar} >
        <option value="">Seleccione</option>
        {
            areas.length > 0 ? areas.map(area => {
                return <option value={area._id['$oid']}> {area.nombre} </option>
            }) : false
        }
    </select>
    </Col>
    <Col md={3} >
    <label className="form-control-label d-block">Cargo</label>
    <select name="cargo_busqueda" className="form-control" value={cargo_busqueda} onChange={this.handleChangeActualizar} >
        <option value="">Seleccione</option>
        {
            cargos.length > 0 ? cargos.map(cargo => {
                return <option value={cargo._id['$oid']}> {cargo.nombre} </option>
            }) : false
        }
    </select>
</Col>
        </Row>
    </Col>

    }

    mostrarGraficos(){
        const { loadingGraficos, graficos } = this.state

        if(loadingGraficos){
            return <div>
                <Spinner animation="border" />
                <h3>Cargando gráficos</h3>
            </div>
        }

        if(!graficos) return <Card className="mb-3">
            <Card.Body>
                {this.mostrarFiltros()}
            <h4>Gráficos no disponibles</h4>
            </Card.Body>
        </Card>

        if(!graficos.categorias) return <Card className="mb-3">
        <Card.Body>
            {this.mostrarFiltros()}
        <h4>Gráficos no disponibles</h4>
        </Card.Body>
        </Card>

        if(graficos.categorias.length < 1) return <Card className="mb-3">
        <Card.Body>
            {this.mostrarFiltros()}
        <h4>Gráficos no disponibles</h4>
        </Card.Body>
        </Card>

        let datos_chart = [
            ['Categoría', 'Valor']
        ]

        if(graficos.categorias){
            graficos.categorias.map(cat => {
                datos_chart.push([ cat.nombre, cat.usuarios.length ])
            })
        }

        return <Card className="mb-3">
<Card.Body>
    <Row>
    {this.mostrarFiltros()}

        <Col md={6}>
        <h4>Estadística de evaluaciones</h4>
<Chart
  width={'100%'}
  height={'300px'}
  chartType="PieChart"
  loader={<div>Cargando</div>}
  data={datos_chart}
  options={{
    is3D: true,
  }}
  rootProps={{ 'data-testid': '2' }}
/>
        </Col>
        <Col md={6}>
            <h4>Detalle de usuarios</h4>
        <Tabs defaultActiveKey={graficos.categorias[0].nombre} className="mb-3">
        {
            graficos.categorias.map(cat => {
                return <Tab eventKey={cat.nombre} key={cat.nombre} title={cat.nombre}>
                    <h5>{cat.usuarios.length} Usuarios encontrados</h5>
                <div className="table-responsive maxheight-100">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Nombres</th>
                            <th>Apellidos</th>
                            <th>Área</th>
                            <th>Cargo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            cat.usuarios.map((usuario,iusu) => {
                                return <tr key={`iu${iusu}`}>
                                        <th>{usuario.nombres}</th>
                                        <th>{usuario.apellidopaterno} {usuario.apellidomaterno}</th>
                                        <th>{this.ifAreaExist(usuario.area)}</th>
                                        <th>{this.ifCargoExist(usuario.cargo)}</th>
                                    </tr>
                            })
                        }
                    </tbody>
                </table>
                </div>

            </Tab>
            })
        }
        </Tabs>
        </Col>
    </Row>
</Card.Body>
        </Card>
    }

    tablaUsuarios(usuarios){


        return <div className="table-responsive maxheight-100">
        <table className="table">
            <thead>
                <tr>
                    <th>Acciones</th>
                    <th>Nombres</th>
                    <th>Apellidos</th>
                    <th>Área</th>
                    <th>Cargo</th>
                </tr>
            </thead>
            <tbody>
                {
                    usuarios.map((usuario,iusu) => {
                        return <tr key={`iu${iusu}`}>
                                <th><button className="btn btn-sm btn-outline-danger" onClick={()=>this.removerUsuarioSeleccionado(iusu)}>ELIMINAR</button> </th>
                                <th>{usuario.nombres}</th>
                                <th>{usuario.apellidopaterno} {usuario.apellidomaterno}</th>
                                <th>{this.ifAreaExist(usuario.area)}</th>
                                <th>{this.ifCargoExist(usuario.cargo)}</th>
                            </tr>
                    })
                }
            </tbody>
        </table>
        </div>
    }

    removerUsuarioSeleccionado(i){
        const { usuarios_seleccionados, capacitacion } = this.state
        usuarios_seleccionados.splice(i,1)
        capacitacion.usuarios.splice(i,1)
        return this.setState({ usuarios_seleccionados, capacitacion })
    }

    showByTipoVisualizacion(){
        const { tipovisualizacion } = this.state
        
        if(tipovisualizacion === 'informes') return this.visualizacionInformes() 
        if(tipovisualizacion === 'capacitacion') return this.visualizacionCapacitacion()
    }

    visualizacionCapacitacion(){
        const { capacitacion, usuarios_seleccionados } = this.state

        return <div>
            <Card>
                <Card.Body>
                <Row>
                    <Col xs={12}>
                        <h3>Crear capacitación</h3>
                        <Button variant="outline-danger" size="sm" className="mb-3" onClick={()=>this.setState({ capacitacion: { usuarios: [] }, tipovisualizacion: 'informes', usuarios_seleccionados: []  })} >CANCELAR</Button>
                    </Col>
            <Col md={4} >
                <div className="form-group">
                <label className="form-control-label d-block">Título</label>
                <input name="titulo" className="form-control" value={capacitacion.titulo ? capacitacion.titulo : ''} onChange={this.handleChangeCap} />
                </div>
            </Col>

            <Col md={4} >
            <div className="form-group">
                <label className="form-control-label d-block">Descripción</label>
                <input name="descripcion" className="form-control" value={capacitacion.descripcion ? capacitacion.descripcion : ''} onChange={this.handleChangeCap} />
                </div>
            </Col>
            <Col md={12} >
            <button className="btn btn-success" onClick={()=> this.crearCapacitacion()}>CREAR CAPACITACIÓN</button>
            </Col>

            <Col xs={12}>
                <h4>{capacitacion.usuarios.length} Usuarios</h4>
                {this.tablaUsuarios(usuarios_seleccionados)}
            </Col>
            </Row>
                </Card.Body>
            </Card>
        </div>
    }

    visualizacionInformes(){
        return <div>
        {this.mostrarGraficos()}
      {this.mostrarHabilidades()}
      {this.mostrarReportes()}
      {this.mostrarReportesInsignia()}
      {this.mostrarInsigniasAprobadas()}

        </div>
    }
    mostrarReportes(){
        const { loadingreporte, reporte } = this.state

        if(loadingreporte){
            return <div>
                <Spinner animation="border" />
                <h3>Cargando reporte</h3>
            </div>
        }

        if(reporte.length > 0){
            return <Card className="mb-3">
<Card.Body>
    <img src="icon2.jpg" className="iconsystem" />
<h4>Estadísticas de evaluaciones de cargo por área</h4>
<Table responsive hover className="mb-0">
            <thead>
              <tr>
                <th>Área</th>
                <th>Evaluados</th>
                <th>No evaluados</th>
                <th>A</th>
                <th>B</th>
                <th>C</th>
                <th>D</th>
                <th>E</th>
                <th>Porcentaje evaluado</th>
              </tr>
            </thead>
            <tbody>
              { reporte.map((area,i)=>{
            let contarusuarios = {}
            let a = 0
            let b = 0
            let c = 0
            let d = 0
            let e = 0
            let pendientes = 0
            const usuariostotales = parseInt(area.usuarios ? area.usuarios : 0)

            if(area.evaluaciones){
                if(area.evaluaciones.length){
                    area.evaluaciones.map(ev=>{
                        contarusuarios[ev.usuarioevaluado.email] = ev.usuarioevaluado.email
                        if(!ev.categoria) return false
                        if(ev.categoria.toLowerCase() ==='a'){
                            a++
                        } else if(ev.categoria.toLowerCase() ==='b'){
                            b++
                        } else if(ev.categoria.toLowerCase() ==='c'){
                            c++
                        } else if(ev.categoria.toLowerCase() ==='d'){
                            d++
                        } else if(ev.categoria.toLowerCase() ==='e'){
                            e++
                        } 
                    })
                }
            }
            let porcentaje = 0

            if(usuariostotales > 0) porcentaje = area.evaluaciones.length / usuariostotales * 100

                  return <tr key={`area${i}`}>
                      <th>{area.nombre}</th>
                      <th>{Object.keys(contarusuarios).length}</th>
                      <th>{usuariostotales - Object.keys(contarusuarios).length}</th>
                      <th>{a}</th>
                      <th>{b}</th>
                      <th>{c}</th>
                      <th>{d}</th>
                      <th>{e}</th>
                      <th><ProgressBar animated now={Math.round(porcentaje)} label={`${Math.round(porcentaje)}%`} /></th>
                  </tr>
              }) }
            </tbody>
          </Table>
</Card.Body>
            </Card>

        }
    }

    tablaDetalle(evaluacionesFiltradas){

        return evaluacionesFiltradas.map((cargo,i)=>{
        
        
            let usuariosevaluados = ''
    
            if(cargo.modalidad==='autoevaluacion'){
                usuariosevaluados = `${cargo.usuarioevaluado.nombres} - ${cargo.usuarioevaluado.rut}`
            } else if(cargo.modalidad === 'evaluacion') {
                if(cargo.listausuarios){
                    if(cargo.listausuarios.length > 0){
                        cargo.listausuarios.map(u => {
                            usuariosevaluados = usuariosevaluados + `${u.nombres}, `
                        })
                    }
                }
            }
            return <Card key={`user${i}`} >
                <Card.Body>
                <Row>
                    <Col xs={3}>
                        <h6>Fecha de creación</h6>
                        <h4>{cargo.creado}</h4>
                    </Col>
                    <Col xs={3}>
                        <h6>Estado</h6>
                        <h4>{this.statusUser(cargo.status)}</h4>
                    </Col>
                    <Col xs={3}>
                        <h6>Modalidad</h6>
                        <h4>{cargo.modalidad}</h4>
                    </Col>
                    <Col xs={3}>
                        <h6>Evaluado</h6>
                        <p>{usuariosevaluados}</p>
                    </Col>
                    <Col xs={3}>
                        <h6>Creador</h6>
                        <h4>{cargo.creador ? `${cargo.creador.nombres} ${cargo.creador.apellidopaterno}` : 'No encontrado'}</h4>
                    </Col>
                    <Col xs={2}>
                        <h6>Llenado</h6>
                        <h4>{cargo.porcentajellenado ? `${cargo.porcentajellenado}%` : 'Sin data' }</h4>
                    </Col>
                    <Col xs={2}>
                        <h6>Correctas</h6>
                        <h4>{cargo.porcentajecorrectas ? `${cargo.porcentajecorrectas}%` : 'Sin data' }</h4>
                    </Col>
                    <Col xs={2}>
                        <h6>Promedio</h6>
                        <h4>{cargo.resultadopromedio ? `${cargo.resultadopromedio}` : 'Sin data' }</h4>
                    </Col>
                    {
                        cargo.status === '1' ? <Col xs={2}>
                        <h6>Categoría</h6>
                        <h4 className="categoriaspan">{cargo.categoria ? cargo.categoria : 'Sin data'}</h4>
                    </Col> : false
                    }
                    <Col xs={12}><Link to={`detalle-evaluacion-${cargo._id['$oid']}`}> <button className="btn btn-outline-primary">Ver detalles</button> </Link></Col>
                </Row>
                </Card.Body>
                </Card>
        })
    }

    tablaListado(evaluacionesFiltradas){

        return <div>

<div className="form-group">
<label className="form-control-label">Filtrar resultados</label>
<input name="filtroevaluaciones" className="form-control mb-4" placeholder="Escribe cualquier palabra clave" onChange={this.handleChange} />
</div>

<Row>

        <Col xs={3}>
        <div className="form-group">
        <label className="form-control-label">Filtrar por tipo</label>
        <select name="filtrotipo" className="form-control"  onChange={this.handleChange} >
                <option value="">Todo</option>
                <option value="simple">Evaluación</option>
                <option value="insignia">Insignias</option>
            </select>
        </div>
        </Col>

        <Col xs={3}>
        <div className="form-group">
        <label className="form-control-label">Filtrar por categoria</label>
        <select name="filtrocategoria" className="form-control"  onChange={this.handleChange} >
                <option value="">Todo</option>
                <option value="A">A</option>
                <option value="B">B</option>
                <option value="C">C</option>
                <option value="D">D</option>
                <option value="E">E</option>
            </select>
        </div>
        </Col>

        <Col xs={12}>
            <p className="etiquetaclass">{evaluacionesFiltradas.length} coincidencias</p>
        </Col>

</Row>


<Table responsive hover>
        <thead>
          <tr>
            <th>Creación</th>
            <th>Estado</th>
            <th>Modalidad</th>
            <th>Tipo</th>
            <th>Llenado</th>
            <th>Correctas</th>
            <th>Promedio</th>
            <th>Categoría</th>
            <th>Usuarios evaluados</th>
          </tr>
        </thead>
        <tbody>
          { evaluacionesFiltradas.map((cargo,i)=>{
              
              
              let usuariosevaluados = ''
      
              if(cargo.modalidad==='autoevaluacion'){
                  usuariosevaluados = `${cargo.usuarioevaluado.nombres} - ${cargo.usuarioevaluado.rut}`
              } else if(cargo.modalidad === 'evaluacion') {
                  if(cargo.listausuarios){
                      if(cargo.listausuarios.length > 0){
                          cargo.listausuarios.map(u => {
                              usuariosevaluados = usuariosevaluados + `${u.nombres}, `
                          })
                      }
                  }
              }
              return <tr key={`user${i}`}>
                  <th>{cargo.creado}<br/> <Link className="onclick" to={`detalle-evaluacion-${cargo._id['$oid']}`}>Detalles</Link>  </th>
                  <th>{this.statusUser(cargo.status)}</th>
                  <th>{cargo.modalidad}</th>
                  <th><span className="spanclass" style={{ background: cargo.tipo === 'simple' ? '#208bff' : '#ffcb0f' }}>{cargo.tipo}</span></th>
                  <th>{cargo.porcentajellenado ? `${cargo.porcentajellenado}%` : 'Sin data' }</th>
                  <th>{cargo.porcentajecorrectas ? `${cargo.porcentajecorrectas}%` : 'Sin data' }</th>
                  <th>{cargo.resultadopromedio ? `${cargo.resultadopromedio}` : 'Sin data' }</th>
                  <th>{cargo.categoria ? <h6 className="categoriaspan">{cargo.categoria}</h6> : 'Sin data'}</th>
                  <th>{usuariosevaluados}</th>
              </tr>
          }) }
        </tbody>
      </Table>
        </div>
      
    }

    mostrarEvaluacionesByTipoUser(tipo, evaluacionesFiltradas){

        switch(tipo){
            case 'empleado':
                return this.tablaDetalle(evaluacionesFiltradas);
                default:
                    return this.tablaListado(evaluacionesFiltradas);
        }
    }

    mostrarEvaluaciones(){
        const { loadingcargos, filtroevaluaciones, filtrotipo, filtrocategoria, habilitarnuevaevaluacion, evaluaciones, user } = this.state

        if(loadingcargos){
            return <div>
                <Spinner animation="border" />
                <h3>Cargando información</h3>
            </div>
        }

        const lowercasedFilter = filtroevaluaciones.toLowerCase();
        const evaluacionesFiltradasByText = evaluaciones.filter(item => {
            return Object.keys(item).some(key => {
                    if(!item[key]) return false
                    return item[key].toString().toLowerCase().includes(lowercasedFilter)
                }
            );
        });
        const evaluacionesByCat = filtrocategoria ? evaluacionesFiltradasByText.filter(ev => ev.categoria === filtrocategoria) : evaluacionesFiltradasByText
        const evaluacionesFiltradas = filtrotipo ? evaluacionesByCat.filter(ev => ev.tipo === filtrotipo) : evaluacionesByCat
        
        return <Card style={{ width: '100%', marginBottom: 20 }}>
        <Card.Body>
          <Card.Title style={{ float: 'left' }}>{evaluaciones.length} evaluaciones encontradas</Card.Title>
          { habilitarnuevaevaluacion === true ? <Link to="/crear-evaluacion" style={{ float: 'right', marginBottom: 15 }}  className="btn btn-outline-primary">NUEVA EVALUACIÓN</Link> : false }
          <Link to="/crear-evaluacioninsignia" style={{ float: 'right', marginBottom: 15 }}  className="btn btn-outline-primary mr-3">POSTULAR A INSIGNIA</Link>
          <div style={{ clear: 'both' }}><hr /></div>
        {this.formularioNuevoUser()}
          <Row>
<Col xs={12} >
<img src="icon1.jpg" className="iconsystem" />
    <h4>Mi Evaluación</h4>
</Col>
            
            {filtroevaluaciones !== '' ? <Col xs={12}><label className="form-control-label">{evaluacionesFiltradas.length} Coindicencias</label></Col>:false}
          
          </Row>          

        { this.mostrarEvaluacionesByTipoUser(user.tipo, evaluacionesFiltradas) }
        </Card.Body>
      </Card>
    }

    ifCargoExist(idcargo){
        const { cargos } = this.state

        if(cargos.length > 0){
            const buscar = cargos.findIndex(ma => ma._id['$oid'] === idcargo)
            if(cargos[buscar]){
                return cargos[buscar].nombre
            }
        }

        return 'No encontrado'
    }

    ifAreaExist(idarea){
        const { areas } = this.state

        if(areas.length > 0){
            const buscar = areas.findIndex(ma => ma._id['$oid'] === idarea)
            if(areas[buscar]){
                return areas[buscar].nombre
            }
        }

        return 'No encontrado'
    }

    ifMatrixExist(idmatriz){
        const { matrices } = this.state

        if(matrices.length > 0){
            const buscar = matrices.findIndex(ma => ma._id['$oid'] === idmatriz)
            if(matrices[buscar]){
                return matrices[buscar].nombre
            }
        }

        return 'No encontrado'
    }
    
    render(){

        const {miusuario} = this.props
        const { errorLogin } = this.state
        return(
            <div className="fluid">
       <Header/>   
       <ToastContainer />
<div className="container-fluid">
  <div className="row">
   <Nav/>
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Informes</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
        </div>
      </div>

      {this.showByTipoVisualizacion()}

     { /* <Card className="mb-3 cardblue">
<Card.Body>

<Row className="leyenda">
<Col md={12}>
        <h4 className="text-white">Definición de clasificación</h4>
        <hr />
    </Col>

    <Col md={2}>
        <h3 className="etiqueta">A</h3>
        <p>Nota Mayor al 160% del promedio deseado</p>
    </Col>

    <Col md={2}>
        <h3 className="etiqueta">B</h3>
        <p>Nota Mayor al 130% del promedio deseado y menor al 160%</p>
    </Col>

    <Col md={2}>
        <h3 className="etiqueta">C</h3>
        <p>Nota mayor al promedio deseado y menor al 130% del promedio deseado</p>
    </Col>

    <Col md={2}>
        <h3 className="etiqueta">D</h3>
        <p>Nota mayor al 50% del promedio deseado y menor del promedio deseado</p>
    </Col>
    <Col md={2}>
        <h3 className="etiqueta">E</h3>
        <p>Nota menor al 50% del promedio deseado</p>
    </Col>
    <Col md={12}>
        <p style={{fontSize: 14, fontWeight: 'lighter' }}>Otras consideraciones Si el trabajador aprueba sobre el 85% de las habilidades, la clasificación mínima a la que accede es C, por otro lado, si el trabajador aprueba menos del 85%, entonces la clasificación máxima a la que accede es D</p>
    </Col>
</Row>

</Card.Body>
        </Card> */}
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(Informes);