import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button } from 'react-bootstrap'
import Header from '../Header'
import Nav from '../Nav'
import Spinner from 'react-bootstrap/Spinner'
import Table from 'react-bootstrap/Table'
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select'
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import 'react-toastify/dist/ReactToastify.css'

function anadirlogrado(array, inivel, levi, leveli, ihab, iduser){
    let newarray = []

    array.map(ar => {
        newarray.push(ar)
    })
    console.log(newarray[iduser])
    newarray[iduser].evaluacion.matriz.esquema.niveles[inivel].niveles[levi].niveles[leveli].habilidades[ihab].logrado = "aqui"

    return newarray
}

class NuevaEvaluacionInsignia extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            matrices: [],
            loadingUsuarios: true,
            filtroevaluaciones: '',
            evaluacion: {
                listausuarios: []
            },
            showcreacion: false,
            loadingcargos: true,
            cargos: [],
            areas: [],
            loadingareas: true,
            loadingmatrices: true,
            evaluaciones: [],
            guardandocambios: false,
            usuarios: [],
            evaluacionprevia: false,
            loadingconseciones: true,
            conseciones: false,
            areaseleccionada: false,
            toaststyle: { position: toast.POSITION.BOTTOM_RIGHT },
            loadinginsignias: true,
            insignias: [],
            filtroinsignias: '',
            insigniaseleccionada: false
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeRol = this.handleChangeRol.bind(this)
        this.handleChangeUser = this.handleChangeUser.bind(this)
        this.handleChangeCargo = this.handleChangeCargo.bind(this)
        this.handleChangeArea = this.handleChangeArea.bind(this)
        this.handleChangeEvaluacion = this.handleChangeEvaluacion.bind(this)
    }

    handleChangeArea(e){
        return this.setState({ areaseleccionada: e.value })
    }

    handleChangeEvaluacion(e){
        const { name, value } = e.target
        const { evaluacion } = this.state
        
        const inivel = e.target.getAttribute('inivel')
        const levi = e.target.getAttribute('levi')
        const leveli = e.target.getAttribute('leveli')
        const ihab = e.target.getAttribute('ihab')

        if(!evaluacion.listausuarios) return false

        const buscaruser = evaluacion.listausuarios.findIndex(usuario => usuario._id['$oid'] === name )
        if(!evaluacion.listausuarios[buscaruser]) return false
        if(!evaluacion.listausuarios[buscaruser].evaluacion) return false
        if(!evaluacion.listausuarios[buscaruser].evaluacion.matriz) return false
        console.log(ihab)
        
        if(evaluacion.listausuarios[buscaruser].evaluacion.matriz.esquema.niveles[inivel]){

            if(evaluacion.listausuarios[buscaruser].evaluacion.matriz.esquema.niveles[inivel].niveles[levi]){

                if(evaluacion.listausuarios[buscaruser].evaluacion.matriz.esquema.niveles[inivel].niveles[levi].niveles[leveli]){

                    if(evaluacion.listausuarios[buscaruser].evaluacion.matriz.esquema.niveles[inivel].niveles[levi].niveles[leveli].habilidades[ihab]){
                        evaluacion.listausuarios[buscaruser].evaluacion.matriz.esquema.niveles[inivel].niveles[levi].niveles[leveli].habilidades[ihab].logrado = value.toString()
                        console.log(evaluacion.listausuarios)
                        return this.setState({ evaluacion: evaluacion })
                    }

                }
            }

        } 
        
    }

    mostrarInsignias(){
        const { insignias, loadinginsignias, filtroinsignias, insigniaseleccionada } = this.state

        if(loadinginsignias){
            return <div>
                <Spinner animation="border" />
                <h3>Cargando información</h3>
            </div>
        }


        if(insignias.length < 1) return <h3>No hay insignias disponibles para evaluarse</h3>

        const lowercasedFilter = filtroinsignias.toLowerCase();
        const insigniasFiltradas = insignias.filter(item => {
            return Object.keys(item).some(key =>
                item[key].toString().toLowerCase().includes(lowercasedFilter)
            );
        });

        if(!insigniaseleccionada) return <div>

        <Row>

        <Col md={3}>
            <label className="form-control-label">Filtrar resultados</label>
            <input name="filtroinsignias" className="form-control mb-4" placeholder="Escribe cualquier palabra clave" onChange={this.handleChange} />
        </Col>

        <Col md={12}>
        <p>Toca una habilidad para evaluarte</p>
        </Col>
            
        <Col xs={12}>
            <Row>

            {
                insigniasFiltradas.map((insig,i) => {
                    return <Col xs={4}  className="onclick" onClick={()=>this.evaluarInsignia(insig._id['$oid'], insig.idmatriz)}>
                        <Card>
                            <Card.Body>
                            <img src="insignia.jpg" alt="insignia" style={{ width: 60 }} />
                            <h4 className="mb-0">{insig.nombre}</h4>
                            <p className="mb-0">{insig.descripcion} </p>
                            </Card.Body>
                        </Card>
                    </Col>
                })
            }

            </Row>
        </Col>
        </Row>

        </div>

        const detalle = insignias.filter(ins => ins._id['$oid'] === insigniaseleccionada)
        if(detalle.length > 0) return <div>
            <p className="link" onClick={()=>this.setState({ insigniaseleccionada: false })}>Atrás</p>
            <Card className="blue-card">
                <Card.Body >
                <img src="insignia.jpg" alt="insignia" style={{ width: 60 }} />
                            <h4 className="mb-0">{detalle[0].nombre}</h4>
                            <p className="mb-0">{detalle[0].descripcion} </p>
                </Card.Body>
            </Card>
            <hr />

            {this.mostrarEvaluaciones()}


        </div>


        return false
    }

    evaluarInsignia(id, idmatriz){
        const { evaluacion } = this.state
        this.setState({ insigniaseleccionada: id, loadingmatrices: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_MatrizById?id=${idmatriz}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            if(res){
                evaluacion.matriz = res
                return this.setState({ evaluacion: evaluacion, loadingmatrices: false })
            }
            return this.setState({ loadingmatrices: false })
        })
        .catch(error => {
            this.setState({ loadingmatrices: false })
        })
    }

    seleccionarHabilidadMasiva(valor, inivel, levi, leveli, ihab, iduser){

        const { evaluacion } = this.state

        if(evaluacion.listausuarios[iduser].evaluacion.matriz.esquema.niveles[inivel]){

            if(evaluacion.listausuarios[iduser].evaluacion.matriz.esquema.niveles[inivel].niveles[levi]){

                if(evaluacion.listausuarios[iduser].evaluacion.matriz.esquema.niveles[inivel].niveles[levi].niveles[leveli]){

                    if(evaluacion.listausuarios[iduser].evaluacion.matriz.esquema.niveles[inivel].niveles[levi].niveles[leveli].habilidades[ihab]){
                        evaluacion.listausuarios = anadirlogrado(evaluacion.listausuarios, inivel, levi, leveli, ihab, iduser)
                        return this.setState({ evaluacion: evaluacion })
                    }

                }
            }

        }
        return false
    }

    asignarUsuariosDeArea(){
        const { areaseleccionada, usuarios, evaluacion } = this.state
        const filtrados = usuarios.filter(user => user.area === areaseleccionada)
        if(filtrados.length < 1) return toast.error('No hay usuarios pertenecientes a esta área', this.state.toaststyle)
        evaluacion.listausuarios = filtrados
        toast.success(`${filtrados.length} suarios asignados, continua con la evaluación`, this.state.toaststyle)
        return this.setState({ evaluacion: evaluacion })
    }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    handleChangeUser(e){
        const { evaluacion, usuarios } = this.state
        const listausuarios = []
        e.map(persona => {
            const buscar = usuarios.findIndex(user => user._id['$oid'] === persona.value)
            if(usuarios[buscar]){
                listausuarios.push(usuarios[buscar])
            }
        })
        evaluacion.listausuarios = listausuarios
        return this.setState({ evaluacion: evaluacion })
    }
    
    handleChangeCargo(e){
        const { evaluacion, cargos, matrices, areas } = this.state
        const buscar = cargos.findIndex(puesto => puesto._id['$oid'] === e.value)
        if(cargos[buscar]){
            evaluacion.cargo = cargos[buscar]
            if(cargos[buscar].idmatriz){
                const buscarmatriz = matrices.findIndex(mat => mat._id['$oid'] === cargos[buscar].idmatriz)
                if(matrices[buscarmatriz]){
                    evaluacion.matriz = matrices[buscarmatriz]
                    if(evaluacion.listausuarios){
                        if(Array.isArray(evaluacion.listausuarios)){
                            if(evaluacion.listausuarios.length > 0){
                                evaluacion.listausuarios.map((user,iuser) => {

                                    evaluacion.listausuarios[iuser].evaluacion = {
                                        matriz:  matrices[buscarmatriz],
                                        usuarioevaluado: user,
                                        modalidad: 'evaluacion programada',
                                        cargo: cargos[buscar],
                                        propietario: user.propietario
                                    }

                                })
                            }
                        }
                    }
                }
            }
            if(cargos[buscar].areaasignada){
                const buscararea = areas.findIndex(area => area._id['$oid'] === cargos[buscar].areaasignada)
                if(areas[buscararea]){
                    evaluacion.cargo.area = areas[buscararea]
                }
            }
        }

        return this.setState({ evaluacion: evaluacion })
    }

    handleChangeRol(e){
        const { name, value } = e.target
        const { cargo } = this.state
        cargo[name] = value
        return this.setState({ cargo: cargo })
    }

    componentDidMount(){
        const { user, evaluacion } = this.state
        if(user.tipo==='empleado'){
            evaluacion.modalidad = 'autoevaluacion'
        } else {
            evaluacion.modalidad = 'evaluacion'
        }
        this.setState({ evaluacion: evaluacion })
        this.getUsuariosByTipo(user)
        this.getMatrices({ propietario: user.propietario })
        this.getEvaluaciones({ propietario: user.propietario })
        this.getAreas(user.propietario)
        this.getConseciones(user.propietario)
        this.getInsignias({ propietario: user.propietario })
    }

    getInsignias(condicion){
        console.log(condicion)
        this.setState({ loadinginsignias: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_GetInsigniasByPropietario`,{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadinginsignias: false, insignias: res })
        })
        .catch(error => {
            this.setState({ loadinginsignias: false })
            alert("Error al consultar la información, intente nuevamente")
        })

    }

    checkIfNuevaEvaluacion(){
        const { user, conseciones } = this.state
        if(!user.area) return false
        if(!conseciones) return false
        if(!conseciones.areas) return false
        const buscararea = conseciones.areas.filter(ar => ar.idarea === user.area)
        if(buscararea.length > 0){
            
            if( buscararea[0].activo !== true ) return window.location.replace('/evaluaciones')
            
        }
    }

    async getConseciones(propietario){
        
        this.setState({ loadingconseciones: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_campanasReclutamiento?propietario=${propietario}`)
        .then(res => res.json())
        .then(async res => {
            this.setState({ conseciones: res, loadingconseciones: false })
            this.checkIfNuevaEvaluacion()
        })
        .catch(error => {
            this.setState({ loadingconseciones: false })
            return toast.error("Error al consultar la información, intente recargar la página", this.state.toaststyle)
        })
  
    }

    getUsuariosByTipo(user){

        switch (user.tipo) {
            case 'admin':
                this.getRoles(user.tipo)
                return this.fetchUsuarios({ propietario: user.propietario })
                break;
            case 'evaluator':
                this.getRoles(user.tipo)
                return this.fetchUsuarios({ propietario: user.propietario, tipo: 'empleado' })
                break;
            default:
                this.setState({ loadingUsuarios: false })
                break;
        }

    }

    async actualizarData(id){
        const  { evaluacion, cargos, areas, matrices, user } = this.state
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_refreshUserData?id=${id}`)
        .then(res => res.json())
        .then(async res => {
            if(!res) return window.location.replace('/login')
            // res.tipo = 'usuario' // evitar 
            if(res.tipo==='empleado'){
                evaluacion.modalidad = 'autoevaluacion'
                if(res.cargo){
                    const buscar = cargos.findIndex(puesto => puesto._id['$oid'] === res.cargo)
                    if(cargos[buscar]){
                        evaluacion.cargo = cargos[buscar]
                        if(cargos[buscar].idmatriz){
                            const buscarmatriz = matrices.findIndex(mat => mat._id['$oid'] === cargos[buscar].idmatriz)
                            if(matrices[buscarmatriz]){
                                evaluacion.matriz = matrices[buscarmatriz]
                            }
                        }
                        if(cargos[buscar].areaasignada){
                            const buscararea = areas.findIndex(area => area._id['$oid'] === cargos[buscar].areaasignada)
                            if(areas[buscararea]){
                                evaluacion.cargo.area = areas[buscararea]
                            }
                        }

                        let comprobarhabilidades = []
                        const checkevaluacionprevia = await this.getEvaluacionDetalle({ modalidad: 'autoevaluacion', "usuarioevaluado.id": user._id['$oid'], tipo: 'insignia' })
                        if(checkevaluacionprevia.cargo){
                            if(checkevaluacionprevia.cargo.nombre===cargos[buscar].nombre){

                                checkevaluacionprevia.matriz.esquema.niveles.map(nivel => {
                                    if(!nivel.niveles) return false
                                    nivel.niveles.map(level=>{
                                        if(!level.niveles) return false
                                        level.niveles.map(le=>{
                                            if(!le.habilidades) return false
                                            le.habilidades.map(hab => {
                                                comprobarhabilidades.push(hab)
                                            })
                                        })
                                    })
                                })
                                
                                if(evaluacion.matriz){
                                    evaluacion.matriz.esquema.niveles.map((nivel,inivel) => {
                                        return nivel.niveles ? nivel.niveles.map((lev,levi) => {
                                                if(lev.niveles.length < 1) return false
                                                return lev.niveles ? lev.niveles.map((level,leveli) => {
                                                            return level.habilidades ? level.habilidades.map((hab,ihab) => {
                                                                const buscarhab = comprobarhabilidades.findIndex(h => h.nombre === hab.nombre)
                                                                if(comprobarhabilidades[buscarhab]){
                                                                    evaluacion.matriz.esquema.niveles[inivel].niveles[levi].niveles[leveli].habilidades[ihab].logrado = comprobarhabilidades[buscarhab].logrado.toString()
                                                                }
                                                                          }) : false
                                                        }) : false
                                                    }) : false
                                    })
                                }
                            }
                        }
                    }
    
                    evaluacion.usuarioevaluado = user
                    evaluacion.usuarioevaluado.id = user._id['$oid']
                    
                }

            } else {
                evaluacion.modalidad = 'evaluacion'
            }
            
            this.setState({ user: res, evaluacion: evaluacion })
        })
        .catch(error => {
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    getAreas(propietario){

        this.setState({ loadingareas: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getAreas?propietario=${propietario}`)
        .then(res => res.json())
        .then(res => {
            this.setState({ loadingareas: false, areas: res })
        })
        .catch(error => {
            this.setState({ loadingareas: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    getEvaluacionDetalle(condicion){

        this.setState({ loadingevaluacion: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_DetalleEvaluacion`,{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            this.setState({ loadingevaluacion: false, evaluacionprevia: res })
            return res
        })
        .catch(error => {
            this.setState({ loadingevaluacion: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    getEvaluaciones(condicion){

        this.setState({ loadingevaluaciones: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_VerEvaluaciones`,{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            this.setState({ loadingevaluaciones: false, evaluaciones: res })
        })
        .catch(error => {
            this.setState({ loadingevaluaciones: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    getMatrices(condicion){
        const { user } = this.state
        this.setState({ loadingmatrices: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_VerMatricesHabilidades`,{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            this.setState({ loadingmatrices: false, matrices: res })
            this.getCargos({ propietario: user.propietario })
        })
        .catch(error => {
            this.setState({ loadingmatrices: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    getCargos(condicion){
        const { user } = this.state
        this.setState({ loadingcargos: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_GetCargos`,{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {

            this.setState({ loadingcargos: false, cargos: res })
            this.actualizarData(user._id['$oid'])

        })
        .catch(error => {
            this.setState({ loadingcargos: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    getRoles(tipo){

        this.setState({ loadingcargos: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getRolesUsuario?tipo=${tipo}`)
        .then(res => res.json())
        .then(res => {
            this.setState({ loadingcargos: false, roles: res })
        })
        .catch(error => {
            this.setState({ loadingcargos: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    guardarCrear(){
        const { evaluacion, user, insigniaseleccionada } = this.state
        
        evaluacion.propietario = user.propietario
        evaluacion.creado = this.formatDate(new Date())
        evaluacion.idinsignia = insigniaseleccionada
        let llenado = 0
        let correctas = 0
        let cantidadhabilidades = 0
        let totalparapuntaje = 0
        let promediodeseado = 0
        evaluacion.matriz.esquema.niveles.map(nivel => {
            if(!nivel.niveles) return false
            nivel.niveles.map(level=>{
                if(!level.niveles) return false
                level.niveles.map(le=>{
                    if(!le.habilidades) return false
                    le.habilidades.map(hab=>{
                        cantidadhabilidades++
                        if(hab.logrado) llenado++
                        const esperado = hab.esperado ? parseInt(hab.esperado) : 0
                        const logrado = hab.logrado ? parseInt(hab.logrado) : 0
                        totalparapuntaje = totalparapuntaje + logrado
                        promediodeseado = promediodeseado + esperado
                        if(logrado>=esperado) correctas++
                    })
                })
            })
        })
        const deseado = Math.round(promediodeseado / cantidadhabilidades)
        const puntaje = (totalparapuntaje / cantidadhabilidades).toFixed(2)

        let categoria = 'E'

        if(puntaje > (deseado*0.5).toFixed(2) && puntaje <= deseado ) categoria = 'D'
        if(puntaje > deseado && puntaje < (deseado*1.3).toFixed(2) ) categoria = 'C'
        if(puntaje > (deseado*1.3).toFixed(2) && puntaje < (deseado*1.6).toFixed(2) ) categoria = 'B'
        if(puntaje > (deseado*1.6).toFixed(2) ) categoria = 'A'



        evaluacion.porcentajellenado = Math.round(llenado/cantidadhabilidades*100).toString()
        evaluacion.porcentajecorrectas = Math.round(correctas/cantidadhabilidades*100).toString()
        evaluacion.resultadopromedio = (totalparapuntaje / cantidadhabilidades).toFixed(2).toString()
        evaluacion.promediodeseado = (promediodeseado / cantidadhabilidades).toFixed(2).toString()
        evaluacion.categoria = categoria
        if(!evaluacion._id){
            evaluacion.status = '0'
        }
        evaluacion.tipo = 'insignia'
        this.setState({ guardandocambios: true })
        return fetch('https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_CrearEvaluacion',{
            method: 'POST',
            body: JSON.stringify(evaluacion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            const texto = evaluacion._id ? 'Evaluación modificada exitosamente' : 'Evaluación ingresada exitosamente'
            toast.success(texto, this.state.toaststyle)
            return this.setState({ guardandocambios: false })
        })
        .catch(error => {
            this.setState({ guardandocambios: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    fetchUsuarios(condicion){
        this.setState({ loadingUsuarios: true })
        return fetch('https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getUsersCustom',{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            this.setState({ loadingUsuarios: false, usuarios: res })
        })
        .catch(error => {
            this.setState({ loadingUsuarios: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    statusUser(tipo){
        switch (tipo) {
            case '1':
                    return 'Confirmada'
                    break;
            default:
                return 'No confirmada'
                break;
        }
    }

    tipoUser(tipo){
        switch (tipo) {
            case 'admin':
                    return 'Administrador'
                    break;
            case 'evaluator':
                    return 'Evaluador'
                    break;
            case 'empleado':
                    return 'Usuario'
                    break;        
            default:
                return tipo
                break;
        }
    }

    formularioNuevoUser(){
        const { showcreacion, cargos, cargo, areas, guardandocambios, matrices } = this.state

        if(guardandocambios){
            return <div>
            <Spinner animation="border" />
            <h3>Guardando información...</h3>
        </div>
        }
        if(showcreacion){

            return <Card className="card-blue">
                
                <Card.Body className="text-white ">
                <Row>
                <Col md={12} >
                { cargo._id ? <h3>Editar evaluación {cargo.nombre ? cargo.nombre : 'usuario'} </h3> : <h3>Crear evaluación</h3> }
                </Col>

<Col md={3} >
    <label className="form-control-label d-block">Nombre</label>
    <input name="nombre" className="form-control" value={cargo.nombre ? cargo.nombre : ''} onChange={this.handleChangeRol} />
</Col>

<Col md={6} >
    <label className="form-control-label d-block">Descripción</label>
    <input name="descripcion" className="form-control" value={cargo.descripcion} onChange={this.handleChangeRol} />
</Col>

<Col md={3} >
    <label className="form-control-label d-block">Cargo</label>
    <select name="areaasignada" className="form-control" value={cargo.areaasignada ? cargo.areaasignada : ''} onChange={this.handleChangeRol} >
        <option value="">Seleccione</option>
        {
            cargos.length > 0 ? cargos.map(puesto => {
                return <option value={puesto._id['$oid']}> {puesto.nombre} </option>
            }) : false
        }
    </select>
</Col>

<Col md={3}>
<label className="form-control-label d-block">Click para { cargo._id ? 'editar' : 'crear' } </label>
    <Button style={{ background: 'white' }} onClick={()=>this.guardarCrear()} variant="outline-white">{ cargo._id ? 'GUARDAR CAMBIOS' : 'CREAR' }</Button>
</Col>

<Col xs={12}>
<p className="m-0 onclick" onClick={()=>this.setState({ cargo: {} })}>Reiniciar formulario</p>

</Col>

</Row>
                </Card.Body>
            </Card>
        }


    }

    mostrarEvaluaciones(){
        const { loadingUsuarios, user, usuarios, evaluacion, evaluaciones, cargos, showcreacion } = this.state

        if(loadingUsuarios){
            return <div>
                <Spinner animation="border" />
                <h3>Cargando información</h3>
            </div>
        }

        let options = []
        let optionscargos = []

        if(cargos.length > 0){
            cargos.map(puesto => {
                optionscargos.push({
                    value: puesto._id['$oid'],
                    label: puesto.nombre
                })
            })
        }

        if(usuarios.length > 0){
            usuarios.map(user => {
                options.push({
                    value: user._id['$oid'],
                    label: user.nombres
                })
            })
        }       
        
        return this.mostrarEvaluacionByTipo(evaluacion, optionscargos, options)
    }

    mostrarPuntajes(evaluacion){

        const { evaluacionprevia } = this.state

        if(!evaluacion.matriz) return <div>
        <Spinner animation="border" />
        <h3>Espera un momento...</h3>
    </div>
        let llenado = 0
        let correctas = 0
        let cantidadhabilidades = 0
        let totalparapuntaje = 0
        let promediodeseado = 0
        let categoria = 'E'

        if(evaluacion.matriz){
            evaluacion.matriz.esquema.niveles.map(nivel => {
                if(!nivel.niveles) return false
                nivel.niveles.map(level=>{
                    if(!level.niveles) return false
                    level.niveles.map(le=>{
                        if(!le.habilidades) return false
                        le.habilidades.map(hab=>{
                            cantidadhabilidades++
                            if(hab.logrado) llenado++
                            const esperado = hab.esperado ? parseInt(hab.esperado) : 0
                            const logrado = hab.logrado ? parseInt(hab.logrado) : 0
                            totalparapuntaje = totalparapuntaje + logrado
                            promediodeseado = promediodeseado + esperado
                            if(logrado>=esperado) correctas++
                        })
                    })
                })
            })
            const deseado = Math.round(promediodeseado / cantidadhabilidades)
            const puntaje = (totalparapuntaje / cantidadhabilidades).toFixed(2)
    
    
            if(puntaje > (deseado*0.5).toFixed(2) && puntaje <= deseado ) categoria = 'D'
            if(puntaje > deseado && puntaje < (deseado*1.3).toFixed(2) ) categoria = 'C'
            if(puntaje > (deseado*1.3).toFixed(2) && puntaje < (deseado*1.6).toFixed(2) ) categoria = 'B'
            if(puntaje > (deseado*1.6).toFixed(2) ) categoria = 'A'
    
        }
        return <Row>

        <Col xs={3}>
            <h6>Porcentaje llenado</h6>
            <h5>{Math.round(llenado/cantidadhabilidades*100)}%</h5>
        </Col>

        <Col xs={3}>
            <h6>Porcentaje correctas</h6>
            <h5>{Math.round(correctas/cantidadhabilidades*100)}%</h5>
        </Col>

        <Col xs={2}>
            <h6>Promedio</h6>
            <h5>{ (totalparapuntaje / cantidadhabilidades).toFixed(2) }</h5>
        </Col>

        {
            evaluacionprevia ? <Col xs={3}>
            
<h6>Evaluación anterior</h6>
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> {evaluacionprevia.creado ? `Presentada el ${evaluacionprevia.creado}` : false } </Tooltip>}>
  <span className="d-inline-block">
    <Button style={{ padding: 0 }} variant="link" disabled >
    <h5 className="m-0" >{ evaluacionprevia.resultadopromedio }</h5>
    </Button>
  </span>
</OverlayTrigger>
        </Col> : false
        }
        
        </Row>
    }


    mostrarEvaluacionByTipo(evaluacion, optionscargos, options){
        const { areas, loadingmatrices } = this.state
        let optionsareas = []

        areas.map(ar => {
            optionsareas.push({
                value: ar._id['$oid'],
                label: ar.nombre
            })
        })

        const { user } = this.state
        switch (evaluacion.modalidad) {
            case 'evaluacion':
                
                return <Card style={{ width: '100%' }}>
            <Card.Body>
              <Card.Title style={{ float: 'left' }}>Crea una evaluación llenando el siguiente formulario</Card.Title>
              <div style={{ clear: 'both' }}><hr /></div>
              <Row>

              {
                user.tipo === 'admin' || user.tipo === 'evaluator' ? <Col xs={12}>
    
                <Row>
                <Col md={6}>
                <label className="form-control-label">Buscar por area</label>
                <Select options={optionsareas} placeholder="Seleccionar area" onChange={this.handleChangeArea} />
              </Col>

              <Col md={6}>
                <label className="form-control-label d-block">Click para agregar a los usuarios de esta área</label>
                <button className="btn btn-outline-primary" onClick={() => this.asignarUsuariosDeArea()} >ASIGNAR</button>
              </Col>
                <Col md={6}>
                <label className="form-control-label">Buscar usuarios</label>
                <Select options={options} isMulti={true} placeholder="Seleccionar usuarios" onChange={this.handleChangeUser} />
              </Col>
    
              <Col md={6}>
                <label className="form-control-label">Buscar cargo a postular</label>
                <Select options={optionscargos} placeholder="Seleccionar cargo" onChange={this.handleChangeCargo}  />
              </Col>
    
              <Col xs={12} className="mt-4">
                <h4 className="text-primary">Detalles de la evaluación</h4>
                <hr />

              </Col>
    
              {
                  evaluacion.usuario ? <Col xs={6} >
                      
                      <Card>
                          <Card.Body>
                          <p className="nomargin">Usuario designado para evaluar</p>
                          <hr />
                          <h4 className="nomargin">{evaluacion.usuario.nombres} <small>{evaluacion.usuario.rut} </small> </h4>
                          <p className="nomargin">{evaluacion.usuario.apellidopaterno} {evaluacion.usuario.apellidomaterno} </p>
                          <p className="nomargin">{evaluacion.usuario.email}</p>
                          </Card.Body>
                      </Card>
    
                  </Col> : false
              }  
    
            {
                  evaluacion.cargo ? <Col xs={6} >
                      
                      <Card>
                          <Card.Body>
                          <p className="nomargin">Información del cargo a postular</p>
                          <hr />
                          <h4 className="nomargin">{evaluacion.cargo.nombre}</h4>
                          <p className="nomargin">{evaluacion.cargo.descripcion} </p>
                          <p className="nomargin">AREA DE TRABAJO: {evaluacion.cargo.area ? evaluacion.cargo.area.nombre : 'Área no encontrada'} </p>
                          </Card.Body>
                      </Card>
    
                  </Col> : false
              }  
                </Row>
    
                </Col> : false
            }
             
            <Col xs={12} className="">
                <h4 className="text-primary">Detalles de habilidades</h4>
              </Col>
                {  
                  evaluacion.matriz ? this.showMatriz2(evaluacion.matriz) : <Col xs={12}><p>Selecciona un cargo para visualizar el esquema de tu evaluación</p></Col>
                }  
              <Col xs={12}>
    
              </Col>
    
              </Row>          
    
    
            </Card.Body>
          </Card>
        
            default:
                
            return <Card style={{ width: '100%' }}>
            <Card.Body>
              <Card.Title style={{ float: 'left' }}>Completa la evaluación llenando el siguiente formulario</Card.Title>
              <div style={{ clear: 'both' }}><hr /></div>
              <Row>
    
            
             
             <Col xs={12}>

            {
                user.tipo === 'admin' || user.tipo === 'evaluator' ? <Row >

            <Col xs={12} className="mt-4">
                <h4 className="text-primary">Detalles de habilidades</h4>
                {
                    loadingmatrices ? <div>
                    <Spinner animation="border" />
                    <h3>Cargando matriz de la evaluación...</h3>
                </div> : false
                }
              </Col>
                {  
                  evaluacion.matriz ? this.showMatriz(evaluacion.matriz) : <Col xs={12}><p>Selecciona un cargo para visualizar el esquema de tu evaluación</p></Col>
                }  
              <Col xs={12}>
    
              </Col>

                    </Row> : <Row>

                    <Col xs={12}>

                        {this.mostrarPuntajes(evaluacion)}
              </Col>

                <Col xs={3} >

                    {
                        evaluacion.matriz ? evaluacion.matriz.esquema.niveles.map(nivel => {
                            if(!nivel.niveles) return false
                            return <Card className="titualr" style={{ border: 'none'}}>
                                
                                <h5 className="m-0 p-2 text-white">{nivel.nombre}</h5>
                                {
                                    nivel.niveles.map(level=>{
                                        if(!level.niveles) return false
                                        let habilidades = 0
                                        
                                                const contar = level.niveles.map(le=>{
                                                    if(!le.habilidades) return false
                                                    return le.habilidades.map(hab => {
                                                        habilidades++
                                                    })
                                                })
                                            
                                        return <div className="p-2 bg-white">
                                            <a className="m-0" style={{ float: 'left' }} href={`#${level.nombre}`} >{level.nombre}</a>
                                            <small className="m-0 circleoptioncontador" style={{ float: 'right' }}>{habilidades}</small>
                                            <div style={{ clear: 'both' }}></div>
                                        </div>
                                    })
                                }
                                
                            </Card>
                        }) : false


                    }
                
                </Col>

                {  
                  evaluacion.matriz ? this.showMatriz(evaluacion.matriz) : <Col xs={12}><p>No tienes ninguna matriz asignada a tu cargo para crear una evaluación</p></Col>
                }  
              

                    </Row>
            }


    
    </Col>
    
              </Row>          
    
    
            </Card.Body>
          </Card>
        }
    }

    showMatriz2(matriz){
        const { evaluacion } = this.state
        if(!matriz.esquema) return <h4>No hay esquema asignado en esta matriz</h4>
        if(!matriz.esquema.niveles||matriz.esquema.niveles.length < 1) return <h4>No hay niveles creados en esta matriz</h4>

        return <Col xs={12}>
                <Card>
                      <Card.Body>
                      <Table responsive hover bordered>
                        <thead>
                        <tr>
                        <th>Habilidad</th>
                        { evaluacion.listausuarios.map((user,i)=>{
                            return <th key={`user${i}`}>{user.nombres}</th>
                        }) }
                        </tr>
                        </thead>
                        <tbody>
            {
                matriz.esquema.niveles.map((nivel,inivel) => {
                return nivel.niveles ? nivel.niveles.map((lev,levi) => {
                    if(lev.niveles.length < 1) return false
                    return lev.niveles ? lev.niveles.map((level,leveli) => {
                                return level.habilidades ? level.habilidades.map((hab,ihab) => {
                                                const popover = (
                                                    <Popover id="popover-basic" >
                                                      <Popover.Title as="h3">Detalles</Popover.Title>
                                                      <Table responsive hover bordered style={{ margin: 0 }}>
                                                            <tbody>
                                                                    <tr><th>Desc: {hab.descripcion}</th></tr>
                                                                    <tr><th>Nota 1: {hab.nota1}</th></tr>
                                                                    <tr><th>Nota 2: {hab.nota2}</th></tr>
                                                                    <tr><th>Nota 3: {hab.nota3}</th></tr>
                                                                    <tr><th>Nota 4: {hab.nota4}</th></tr>
                                                                    <tr><th>Nota 5: {hab.nota5}</th></tr>
                                                            </tbody>
                                                        </Table>
                                                    </Popover>
                                                  );


                                                return <tr>
                                                    <th><p className="nomargin">{hab.nombre}</p>
                                                    <OverlayTrigger trigger="click" placement="right" overlay={popover} 
                                                    rootClose={true}>
                                                        <Button variant="link" style={{ padding:0 }}>Más info</Button>
                                                    </OverlayTrigger>
                                                    <small className="d-block">Nivel esperado</small>
                                                    <p className="esperado" >{hab.esperado} </p>
                                                    </th>
                                                    { evaluacion.listausuarios.map((user,iuser)=>{
        const buscaruser = evaluacion.listausuarios.findIndex(usuario => usuario._id['$oid'] === user._id['$oid'] )

        

                                                        // seleccionarHabilidad('1', inivel, levi, leveli, ihab )}
                                                        return <th key={`user${iuser}`}> <input max="5" name={user._id['$oid']} type="number" inivel={inivel} levi={levi} leveli={leveli} ihab={ihab} min="1" className="form-control" onChange={this.handleChangeEvaluacion} /></th>
                                                    }) }
                                                </tr>
                                              }) : <h5>Sin habilidades</h5>
                                    
                            }) : false
                        }) : false
                    })
            }
            </tbody>
            </Table>
            </Card.Body>
            </Card>
            </Col>
    }

    showMatriz(matriz){
        const { evaluacion, guardandocambios } = this.state
        if(!matriz.esquema) return <h4>No hay esquema asignado en esta matriz</h4>
        if(!matriz.esquema.niveles||matriz.esquema.niveles.length < 1) return <h4>No hay niveles creados en esta matriz</h4>

        return <Col xs={9}> 
        <Card>
                      <Card.Body>

                      <Table responsive hover bordered>
                        <thead>
                        <tr>
                        <th>Habilidad</th>
                        <th style={{ width: 105, textAlign:'center' }}>Nivel deseado</th>
                        <th className="numeros">1</th>
                        <th className="numeros">2</th>
                        <th className="numeros">3</th>
                        <th className="numeros">4</th>
                        <th className="numeros">5</th>
                        </tr>
                        </thead>
                        <tbody>
                          {
            matriz.esquema.niveles.map((nivel,inivel) => {
            return nivel.niveles ? nivel.niveles.map((lev,levi) => {
                    if(lev.niveles.length < 1) return false
                    return lev.niveles ? lev.niveles.map((level,leveli) => {
                                return level.habilidades ? level.habilidades.map((hab,ihab) => {
                                                const popover = (
                                                    <Popover id="popover-basic" >
                                                      <Popover.Title as="h3">Detalles</Popover.Title>
                                                      <Table responsive hover bordered style={{ margin: 0 }}>
                                                            <tbody>
                                                                    <tr><th>Desc: {hab.descripcion}</th></tr>
                                                                    <th>Nivel deseado</th>
                                                                    <tr><th>Nota 1: {hab.nota1}</th></tr>
                                                                    <tr><th>Nota 2: {hab.nota2}</th></tr>
                                                                    <tr><th>Nota 3: {hab.nota3}</th></tr>
                                                                    <tr><th>Nota 4: {hab.nota4}</th></tr>
                                                                    <tr><th>Nota 5: {hab.nota5}</th></tr>
                                                            </tbody>
                                                        </Table>
                                                    </Popover>
                                                  );


                                                return <tr id={`${lev.nombre}`} >
                                                    <th><p className="nomargin">{hab.nombre}</p>
                                                    <OverlayTrigger trigger="click" placement="right" overlay={popover} 
                                                    rootClose={true}>
                                                        <Button variant="link" style={{ padding:0 }}>Más info</Button>
                                                    </OverlayTrigger>
                                                    </th>
                                                    <th style={{ textAlign:'center' }}><p className="esperado" >{hab.esperado} </p> </th>
                                                    <th><p className={hab.logrado==='1'? `circleoptiondanger circleoption` :`circleoptionoff circleoption`} onClick={()=>this.seleccionarHabilidad('1', inivel, levi, leveli, ihab )}>1</p></th>
                                                    <th><p className={hab.logrado==='2'? `circleoptiondanger circleoption` :`circleoptionoff circleoption`} onClick={()=>this.seleccionarHabilidad('2', inivel, levi, leveli, ihab )}>2</p></th>
                                                    <th><p className={hab.logrado==='3'? `circleoptiongreen circleoption` :`circleoptionoff circleoption`} onClick={()=>this.seleccionarHabilidad('3', inivel, levi, leveli, ihab )}>3</p></th>
                                                    <th><p className={hab.logrado==='4'? `circleoptiongreen circleoption` :`circleoptionoff circleoption`} onClick={()=>this.seleccionarHabilidad('4', inivel, levi, leveli, ihab )}>4</p></th>
                                                    <th><p className={hab.logrado==='5'? `circleoptiongreen circleoption` :`circleoptionoff circleoption`} onClick={()=>this.seleccionarHabilidad('5', inivel, levi, leveli, ihab )}>5</p></th>
                                                </tr>
                                              }) : <h5>Sin habilidades</h5>
                                    
                            }) : false
                        }) : false
            
        }) }
</tbody>
            </Table>
{ guardandocambios ? <div>
            <Spinner animation="border" />
            <h3>Espera un momento...</h3>
        </div> : <button className="btn btn-success" onClick={()=>this.guardarCrear()} > { evaluacion._id ? 'GUARDAR' : 'ENVIAR EVALUACIÓN' } </button> }

        </Card.Body>
            </Card>
        </Col>
    }

    seleccionarHabilidad(valor, inivel, levi, leveli, ihab){

        const { evaluacion } = this.state

        if(evaluacion.matriz.esquema.niveles[inivel]){
            if(evaluacion.matriz.esquema.niveles[inivel].niveles[levi]){
                if(evaluacion.matriz.esquema.niveles[inivel].niveles[levi].niveles[leveli]){
                    if(evaluacion.matriz.esquema.niveles[inivel].niveles[levi].niveles[leveli].habilidades[ihab]){
                        evaluacion.matriz.esquema.niveles[inivel].niveles[levi].niveles[leveli].habilidades[ihab].logrado = valor.toString()
                        return this.setState({ evaluacion: evaluacion })
                    }

                }
            }

        }
    }

    ifAreaExist(idarea){
        const { areas } = this.state

        if(areas.length > 0){
            const buscar = areas.findIndex(ma => ma._id['$oid'] === idarea)
            if(areas[buscar]){
                return areas[buscar].nombre
            }
        }

        return 'No encontrado'
    }

    ifMatrixExist(idmatriz){
        const { matrices } = this.state

        if(matrices.length > 0){
            const buscar = matrices.findIndex(ma => ma._id['$oid'] === idmatriz)
            if(matrices[buscar]){
                return matrices[buscar].nombre
            }
        }

        return 'No encontrado'
    }
    
    render(){

        const {miusuario} = this.props
        const { errorLogin } = this.state
        return(
            <div className="fluid">
       <Header/>   
       <ToastContainer />
<div className="container-fluid">
  <div className="row">
   <Nav/>
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Nueva Evaluación</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
          <div className="btn-group mr-2">
            <button type="button" className="btn btn-sm btn-outline-secondary">Compartir</button>
            <button type="button" className="btn btn-sm btn-outline-secondary">Exportar</button>
          </div>
          <button type="button" className="btn btn-sm btn-outline-secondary dropdown-toggle">
          <i className="fas fa-calendar-alt"></i> Esta semana
          </button>
        </div>
      </div>

      {this.mostrarInsignias()}
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(NuevaEvaluacionInsignia);