const control = {}

control.checkForPermissions = ( user,nombre,accion) => {
    const permiso = user.matriz.esquema.findIndex(es => es.slug === nombre)
    if(permiso < 0) return false
    const revisar = user.matriz.esquema[permiso].permisos.findIndex(es => es.nombre === accion)
    if(revisar < 0) return false
    const retornar = user.matriz.esquema[permiso].permisos[revisar].status
    return retornar
}

control.calcularHabilidadesReprobadas = (evaluaciones,tipo) => {
    const usuarios = []
    evaluaciones.map(evaluacion => {
        if(evaluacion.matriz){
            evaluacion.matriz.esquema.niveles.map((nivel,inivel) => {
                return nivel.niveles ? nivel.niveles.map((lev,levi) => {
                        if(lev.niveles.length < 1) return false
                        return lev.niveles ? lev.niveles.map((level,leveli) => {
                                    return level.habilidades ? level.habilidades.map((hab,ihab) => {
                                        const valor_esperado = parseInt(hab.esperado)
                                        const valor_logrado = parseInt(hab.logrado)
                                        if(isNaN(valor_esperado) === true || isNaN(valor_logrado) === true) return false
                                        if(valor_logrado < valor_esperado){
                                            if(!evaluacion.usuarioevaluado ) return false
                                            if( typeof evaluacion.usuarioevaluado !== 'object' ) return false
                                            const validar_si_user_existe = usuarios.findIndex(us => us.id === evaluacion.usuarioevaluado._id['$oid'])
                                            if(validar_si_user_existe > -1){
                                                usuarios[validar_si_user_existe].habilidades.push(hab)
                                            } else {
                                                usuarios.push({
                                                    id: evaluacion.usuarioevaluado._id['$oid'],
                                                    tipo,
                                                    habilidades: [
                                                        hab
                                                    ]
                                                })
                                            }
                                        }

                                                  }) : false
                                }) : false
                            }) : false
            })
        }
    })
    return usuarios
}

control.calcularCategoria = (puntaje, deseado) => {
    let categoria = 'E'
    if(puntaje > (deseado*0.5).toFixed(2) && puntaje <= (deseado*0.85).toFixed(2) ) categoria = 'D'
    if(puntaje > (deseado*0.85).toFixed(2) && puntaje < (deseado*1.3).toFixed(2) ) categoria = 'C'
    if(puntaje > (deseado*1.3).toFixed(2) && puntaje < (deseado*1.6).toFixed(2) ) categoria = 'B'
    if(puntaje > (deseado*1.6).toFixed(2) ) categoria = 'A'
    return categoria
}

module.exports = control