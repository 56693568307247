import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button } from 'react-bootstrap'
import Header from '../Header'
import Nav from '../Nav'
import Spinner from 'react-bootstrap/Spinner'
import Table from 'react-bootstrap/Table'
import * as XLSX from 'xlsx'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

class Insignias extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            matrices: [],
            loadingUsuarios: true,
            filtroroles: '',
            cargo: {},
            showcreacion: false,
            loadingcargos: true,
            cargos: [],
            areas: [],
            loadingareas: true,
            loadingmatrices: true,
            hojas: [],
            procesandoexcel: false,
            guardando: false,
            toaststyle: { position: toast.POSITION.BOTTOM_RIGHT }
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeRol = this.handleChangeRol.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
    }

    handleInputChange (event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        this.setState({
          [name]: value
        })
        let hojas = []
        if (name === 'file') {
            this.setState({procesandoexcel:true})
          let reader = new FileReader()
          reader.readAsArrayBuffer(target.files[0])
          reader.onloadend = (e) => {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, {type: 'array'});
    
            workbook.SheetNames.forEach(function(sheetName) {
              var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
              hojas.push(XL_row_object)
            })
            
            const requeridos = [
                'nombre',
                'descripcion'
            ]
            const columnasincompletas = []
            hojas[0].forEach((row,i) => {
                const faltantes = []
                requeridos.map(req => {
                    if(!row[req]) faltantes.push(req)
                })
                if(faltantes.length > 0) columnasincompletas.push(`La columna ${i+1} le faltan campos: ${faltantes.join(', ')}`)
            })

            if(columnasincompletas.length > 0){
                this.setState({ procesandoexcel: false })
                columnasincompletas.push('Corrige el documento e inténtalo de nuevo')
                return toast.error( columnasincompletas.join('. '), this.state.toaststyle )
            }
            
            this.setState({
              selectedFileDocument: target.files[0],
              hojas: hojas[0],
              procesandoexcel: false
            })
          }
        }
      }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    handleChangeRol(e){
        const { name, value } = e.target
        const { cargo } = this.state
        cargo[name] = value
        console.log(cargo)
        return this.setState({ cargo: cargo })
    }

    componentDidMount(){
        const { user } = this.state
        this.getCargos({ propietario: user.propietario })
        this.getMatrices({ propietario: user.propietario, tipo: 'insignia' })
        this.getAreas(user.propietario)
    }

    getAreas(propietario){

        this.setState({ loadingareas: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getAreas?propietario=${propietario}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingareas: false, areas: res })
        })
        .catch(error => {
            this.setState({ loadingareas: false })
            alert("Error al consultar la información, intente nuevamente")
        })

    }

    getMatrices(condicion){

        this.setState({ loadingmatrices: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_VerMatricesHabilidades`,{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingmatrices: false, matrices: res })
        })
        .catch(error => {
            this.setState({ loadingmatrices: false })
            alert("Error al consultar la información, intente nuevamente")
        })

    }

    getCargos(condicion){

        this.setState({ loadingcargos: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_GetInsigniasByPropietario`,{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingcargos: false, cargos: res })
        })
        .catch(error => {
            this.setState({ loadingcargos: false })
            alert("Error al consultar la información, intente nuevamente")
        })

    }

    getRoles(tipo){

        this.setState({ loadingcargos: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getRolesUsuario?tipo=${tipo}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingcargos: false, roles: res })
        })
        .catch(error => {
            this.setState({ loadingcargos: false })
            alert("Error al consultar la información, intente nuevamente")
        })

    }

    guardarCrear(){
        const { cargo, user } = this.state
        
        if(!cargo.nombre||!cargo.idmatriz||!cargo.areaasignada){
            return alert("Todos los campos son requeridos")
        }
        cargo.propietario = user.propietario
        cargo.status = '1'
        cargo.creador = user
        this.setState({ guardandocambios: true })
        return fetch('https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_EditarCrearInsignia',{
            method: 'POST',
            body: JSON.stringify(cargo),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            const texto = cargo._id ? 'Modificado exitosamente' : 'Creado exitosamente'
            toast.success(texto, this.state.toaststyle)
            this.setState({ guardandocambios: false, area: {} })
            this.getCargos({ propietario: user.propietario })
        })
        .catch(error => {
            this.setState({ guardandocambios: false })
            alert("Error al consultar la información, intente nuevamente")
        })
    }

    fetchUsuarios(condicion){
        this.setState({ loadingUsuarios: true })
        return fetch('https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getUsersCustom',{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingUsuarios: false, usuarios: res })
        })
        .catch(error => {
            this.setState({ loadingUsuarios: false })
            alert("Error al consultar la información, intente nuevamente")
        })
    }

    statusUser(tipo){
        switch (tipo) {
            case '1':
                    return 'Activo'
                    break;
            default:
                return 'Inactivo'
                break;
        }
    }

    tipoUser(tipo){
        switch (tipo) {
            case 'admin':
                    return 'Administrador'
                    break;
            case 'evaluator':
                    return 'Evaluador'
                    break;
            case 'empleado':
                    return 'Usuario'
                    break;        
            default:
                return tipo
                break;
        }
    }

    formularioNuevoUser(){
        const { showcreacion, cargo, areas, guardandocambios, matrices } = this.state

        if(guardandocambios){
            return <div>
            <Spinner animation="border" />
            <h3>Guardando información...</h3>
        </div>
        }
        if(showcreacion){

            return <Card className="card-blue">
                
                <Card.Body className="text-white ">
                <Row>
                <Col md={12} >
                { cargo._id ? <h3>Editar insignia {cargo.nombre ? cargo.nombre : 'usuario'} </h3> : <h3>Crear insignia</h3> }
                </Col>

<Col md={3} >
    <label className="form-control-label d-block">Nombre</label>
    <input name="nombre" className="form-control" value={cargo.nombre ? cargo.nombre : ''} onChange={this.handleChangeRol} />
</Col>

<Col md={6} >
    <label className="form-control-label d-block">Descripción</label>
    <input name="descripcion" className="form-control" value={cargo.descripcion} onChange={this.handleChangeRol} />
</Col>

<Col md={3} >
    <label className="form-control-label d-block">Matriz habilidades</label>
    <select name="idmatriz" className="form-control" value={cargo.idmatriz ? cargo.idmatriz : ''} onChange={this.handleChangeRol} >
        <option value="">Seleccione</option>
        {
            matrices.length > 0 ? matrices.map(matriz => {
                return <option value={matriz._id['$oid']}> {matriz.nombre} </option>
            }) : false
        }
    </select>
</Col>

<Col md={3} >
    <label className="form-control-label d-block">Area de trabajo</label>
    <select name="areaasignada" className="form-control" value={cargo.areaasignada ? cargo.areaasignada : ''} onChange={this.handleChangeRol} >
        <option value="">Seleccione</option>
        {
            areas.length > 0 ? areas.map(area => {
                return <option value={area._id['$oid']}> {area.nombre} </option>
            }) : false
        }
    </select>
</Col>

<Col md={3}>
<label className="form-control-label d-block">Click para { cargo._id ? 'editar' : 'crear' } </label>
    <Button style={{ background: 'white' }} onClick={()=>this.guardarCrear()} variant="outline-white">{ cargo._id ? 'GUARDAR CAMBIOS' : 'CREAR' }</Button>
</Col>

<Col xs={12}>
<p className="m-0 onclick" onClick={()=>this.setState({ cargo: {} })}>Reiniciar formulario</p>

</Col>

</Row>
                </Card.Body>
            </Card>
        }


    }

    mostrarExcel(){
        const { hojas, procesandoexcel, guardando } = this.state

        if(procesandoexcel){
            return <div>
            <Spinner animation="border" />
            <h4>Analizando documento...</h4>
            </div>
        }

        if(hojas.length > 0){
            return <div>
             {
                 guardando ? <Spinner animation="border" /> : <div>
                    <div className="table-responsive">

                <h5>Encontramos {hojas.length} registros en tu documento excel </h5>
                <Table responsive hover> 
                    <thead>
                        <tr>
                            <th>Nombre</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            hojas.map((usuario,iu) => {
                                return <tr key={`user${iu}`}>
                            <th>{usuario.nombre}</th>
                                </tr>
                            })
                        }
                    </tbody>
                </Table>


                        </div>
            {
                hojas.map(usuario => {

                })
            }

            <button className="btn btn-success" onClick={() => this.subirAreas()}>SUBIR ARCHIVO Y ACTUALIZAR BASE DE DATOS</button>
            
            </div>
             }   
            </div>
        }
    }

    subirAreas(){


        return toast.warn('Esta función no se encuentra 100% terminada', this.state.toaststyle)


    }

    mostrarRoles(){
        const { loadingcargos, filtroroles, cargos, showcreacion } = this.state

        if(loadingcargos){
            return <div>
                <Spinner animation="border" />
                <h3>Cargando información</h3>
            </div>
        }

        const lowercasedFilter = filtroroles.toLowerCase();
        const cargosFiltrados = cargos.filter(item => {
            return Object.keys(item).some(key =>
                item[key].toString().toLowerCase().includes(lowercasedFilter)
            );
        });
        
        return <Card style={{ width: '100%' }}>
        <Card.Body>
          <Card.Title style={{ float: 'left' }}>{cargos.length} insignias encontradas</Card.Title>
          <Button style={{ float: 'right', marginBottom: 15 }} onClick={()=> this.setState({ showcreacion: showcreacion ? false : true })}  variant="outline-primary">CREAR</Button>
          <div style={{ clear: 'both' }}><hr /></div>
        {this.formularioNuevoUser()}
          <Row>

          <Col xs={12}>
            <img src="insignia.jpg" className="iconsystem" />
            <h4>Las insignias representan un conjunto de habilidades importantes para diversos perfiles</h4>
            <hr />
            <div className="form-group">
            <label className="form-control-label">Carga tu documento excel con la base de tus insignias</label>
            <div className="mb-2">
            <a href="cargos.xlsx" className="btn btn-sm btn-outline-warning" >DESCARGAR MODELO EXCEL</a>
            </div>
            <input 
            required 
            type="file" 
            name="file" 
            id="file" 
            className="form-control"
            onChange={this.handleInputChange} 
            placeholder="Archivo de excel" 
            />
            </div>

            {this.mostrarExcel()}
            <hr />
            </Col>

            <Col md={3}>
            <label className="form-control-label">Filtrar resultados</label>
            <input name="filtroroles" className="form-control mb-4" placeholder="Escribe cualquier palabra clave" onChange={this.handleChange} />
            </Col>

            {filtroroles !== '' ? <Col xs={12}><label className="form-control-label">{cargosFiltrados.length} Coindicencias</label></Col>:false}
          
          </Row>          

          <Table responsive hover>
  <thead>
    <tr>
      <th>Nombre</th>
      <th>Estado</th>
      <th>Matriz habilidades</th>
      <th>Area de trabajo</th>
      <th>Creador</th>
      <th>Creación</th>
    </tr>
  </thead>
  <tbody>
    { cargosFiltrados.map((cargo,i)=>{
        console.log(cargo)
        return <tr key={`user${i}`}>
            <th>{cargo.nombre}<br/><small className="text-primary onclick" onClick={()=>this.setState({ showcreacion: true, cargo: cargo  })}>Editar</small></th>
            <th>{this.statusUser(cargo.status)}</th>
            <th>{this.ifMatrixExist(cargo.idmatriz)}</th>
            <th>{this.ifAreaExist(cargo.areaasignada)}</th>
            <th>{cargo.creador ? `${cargo.creador.nombres} ${cargo.creador.apellidopaterno}` : 'No encontrado'}</th>
            <th></th>
        </tr>
    }) }
  </tbody>
</Table>

        </Card.Body>
      </Card>
    }

    ifAreaExist(idarea){
        const { areas } = this.state

        if(areas.length > 0){
            const buscar = areas.findIndex(ma => ma._id['$oid'] === idarea)
            if(areas[buscar]){
                return areas[buscar].nombre
            }
        }

        return 'No encontrado'
    }

    ifMatrixExist(idmatriz){
        const { matrices } = this.state

        if(matrices.length > 0){
            const buscar = matrices.findIndex(ma => ma._id['$oid'] === idmatriz)
            if(matrices[buscar]){
                return matrices[buscar].nombre
            }
        }

        return 'No encontrado'
    }
    
    render(){

        const {miusuario} = this.props
        const { errorLogin } = this.state
        return(
            <div className="fluid">
       <Header/>   
       <ToastContainer 
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          />
<div className="container-fluid">
  <div className="row">
   <Nav/>
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Insignias</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
         
        </div>
      </div>

      {this.mostrarRoles()}
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(Insignias);