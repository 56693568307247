import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card,  Button } from 'react-bootstrap'
import Header from '../Header'
import Nav from '../Nav'
import Spinner from 'react-bootstrap/Spinner'
import Accordion from 'react-bootstrap/Accordion'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Table from 'react-bootstrap/Table'
import * as XLSX from 'xlsx'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Select from 'react-select'

class editarMatrizHabilidades extends Component {
    constructor(props){
        super(props)
        this.state = {
            idmatriz: this.props.match.params.idmatriz,
            user: JSON.parse(localStorage.getItem('user')),
            matrices: [],
            loadingUsuarios: true,
            filtroroles: '',
            rol: {},
            showcreacion: false,
            textguardado: '',
            loadingroles: true,
            roles: [],
            loadingmatrices: true,
            matriz: {},
            hojas: [],
            procesandoexcel: false,
            guardando: false,
            toaststyle: { position: toast.POSITION.BOTTOM_RIGHT },
            habilidades: [],
            idhabilidadseleccion: ''
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeRol = this.handleChangeRol.bind(this)
        this.handleChange2 = this.handleChange2.bind(this)
        this.handleChange3 = this.handleChange3.bind(this)
        this.handleChangeHabilidad = this.handleChangeHabilidad.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleChangeHabilidadAgregar = this.handleChangeHabilidadAgregar.bind(this)
    }

    handleChangeHabilidadAgregar(e){
        return this.setState({ idhabilidadseleccion: e.value })
    }

    handleInputChange (event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        this.setState({
          [name]: value
        })
        const { habilidades, matriz } = this.state
        let hojas = []
        if (name === 'file') {
            this.setState({procesandoexcel:true})
          let reader = new FileReader()
          reader.readAsArrayBuffer(target.files[0])
          reader.onloadend = (e) => {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, {type: 'array'});
    
            workbook.SheetNames.forEach(function(sheetName) {
              var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
              hojas.push(XL_row_object)
            })
            
            const requeridos = [
                'level1',
                'level2',
                'level3',
                'habilidad',
                'esperado'
            ]
            const columnasincompletas = []
            hojas[0].forEach((row,i) => {
                const faltantes = []
                requeridos.map(req => {
                    if(!row[req]) faltantes.push(req)
                })
                if(faltantes.length > 0) columnasincompletas.push(`La columna ${i+1} le faltan campos: ${faltantes.join(', ')}`)
            })

            if(columnasincompletas.length > 0){
                this.setState({ procesandoexcel: false })
                columnasincompletas.push('Corrige el documento e inténtalo de nuevo')
                return toast.error( columnasincompletas.join('. '), this.state.toaststyle )
            }
            
            let esquema = {}
            let errores = []
            hojas[0].forEach((row,i) => {
                if(!esquema.niveles) esquema.niveles = []
                // CHEQUEAR NIVEL 1
                let buscarnivel1 = esquema.niveles.filter(esq => esq.nombre === row.level1)
                if(buscarnivel1.length < 1) {
                esquema.niveles.push({
                    nombre: row.level1,
                    descripcion:'',
                    niveles: []
                })
            }
            // CHEQUEAR NIVEL 2
                buscarnivel1 = esquema.niveles.findIndex(esq => esq.nombre === row.level1)
                let buscarnivel2 = esquema.niveles[buscarnivel1].niveles.filter(esq => esq.nombre === row.level2)
                if(buscarnivel2.length < 1) esquema.niveles[buscarnivel1].niveles.push({
                    nombre: row.level2,
                    descripcion:'',
                    niveles: []
                })
            
                // CHEQUEAR NIVEL 3
                buscarnivel2 = esquema.niveles[buscarnivel1].niveles.findIndex(esq => esq.nombre === row.level2)
                let buscarnivel3 = esquema.niveles[buscarnivel1].niveles[buscarnivel2].niveles.filter(esq => esq.nombre === row.level3)
                if(buscarnivel3.length < 1) esquema.niveles[buscarnivel1].niveles[buscarnivel2].niveles.push({
                    nombre: row.level3,
                    descripcion:'',
                    niveles: []
                })

                buscarnivel3 = esquema.niveles[buscarnivel1].niveles[buscarnivel2].niveles.findIndex(esq => esq.nombre === row.level3)

                const buscarhabilidad = habilidades.filter(hab => hab.nombre.toLowerCase() === row.habilidad.toLowerCase())
                if(buscarhabilidad.length < 1) return errores.push(`Habilidad ${row.habilidad} no existe en la columna ${i+1}`)
                if(esquema.niveles[buscarnivel1].niveles[buscarnivel2].niveles[buscarnivel3]){
                    if(!esquema.niveles[buscarnivel1].niveles[buscarnivel2].niveles[buscarnivel3].habilidades) esquema.niveles[buscarnivel1].niveles[buscarnivel2].niveles[buscarnivel3].habilidades = []
                    if(buscarhabilidad[0]){
                        buscarhabilidad[0].esperado = row.esperado ? row.esperado.toString() : row.esperado
                        esquema.niveles[buscarnivel1].niveles[buscarnivel2].niveles[buscarnivel3].habilidades.push(buscarhabilidad[0])
                    }
                }
            })

            if(errores.length > 0){
                this.setState({ procesandoexcel: false })
                errores.push('Corrige el documento e inténtalo de nuevo')
                return toast.error( errores.join('. '), this.state.toaststyle )
            }
            matriz.esquema = esquema
            console.log(matriz)
            this.setState({
              selectedFileDocument: target.files[0],
              matriz: matriz,
              procesandoexcel: false
            })
          }
        }
      }

    handleChange(e){
        const { name, value } = e.target
        const pos = e.target.getAttribute('pos')
        const { matriz } = this.state

        matriz.esquema.niveles[pos][name] = value
        console.log(matriz)
        return this.setState({ matriz: matriz })
    }

    handleChangeRol(e){
        const { name, value } = e.target
        const { rol } = this.state
        rol[name] = value
        console.log(rol)
        return this.setState({ rol: rol })
    }

    handleChange2(e){
        const { name, value } = e.target
        const pos = e.target.getAttribute('pos')
        const poslevel = e.target.getAttribute('poslevel')
        const { matriz } = this.state

        matriz.esquema.niveles[pos].niveles[poslevel][name] = value
        return this.setState({ matriz: matriz })
    }

    handleChange3(e){
        const { name, value } = e.target
        const pos = e.target.getAttribute('pos')
        const poslevel = e.target.getAttribute('poslevel')
        const level = e.target.getAttribute('level')
        const { matriz } = this.state

        matriz.esquema.niveles[pos].niveles[poslevel].niveles[level][name] = value
        return this.setState({ matriz: matriz })
    }

    handleChangeHabilidad(e){
        const { name, value } = e.target
        const pos = e.target.getAttribute('pos')
        const poslevel = e.target.getAttribute('poslevel')
        const level = e.target.getAttribute('level')
        const ihab = e.target.getAttribute('ihab')
        const { matriz } = this.state

        matriz.esquema.niveles[pos].niveles[poslevel].niveles[level].habilidades[ihab][name] = value
        return this.setState({ matriz: matriz })
    }

    componentDidMount(){
        const { idmatriz, user } = this.state
        this.getMatriz(idmatriz, user.propietario)
        
        //this.getMatrices({ propietario: user.propietario })
    }

    getHabilidades(propietario, tipo){

        this.setState({ loadinghabilidades: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/we_GetEvaluacionesByTipo?propietario=${propietario}&tipo=${tipo}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadinghabilidades: false, habilidades: res })
        })
        .catch(error => {
            this.setState({ loadinghabilidades: false })
            alert("Error al consultar la información, intente nuevamente")
        })

    }
    
    getMatrices(condicion){

        this.setState({ loadingmatrices: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_VerMatricesHabilidades`,{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingmatrices: false, matrices: res })
        })
        .catch(error => {
            this.setState({ loadingmatrices: false })
            alert("Error al consultar la información, intente nuevamente")
        })

    }

    getMatriz(id, propietario){

        this.setState({ loadingmatrices: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_VerMatriz?id=${id}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            if(res.tipo){
                this.getHabilidades(propietario, res.tipo)
            }
            this.setState({ loadingmatrices: false, matriz: res })
        })
        .catch(error => {
            this.setState({ loadingmatrices: false })
            alert("Error al consultar la información, intente nuevamente")
        })

    }
    
    guardarCrear(){
        const { matriz } = this.state

        this.setState({ guardandocambios: true })
        return fetch('https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_editarMatriz',{
            method: 'POST',
            body: JSON.stringify(matriz),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            toast.success('Actualizado exitosamente', this.state.toaststyle)
            this.setState({ guardandocambios: false })
        })
        .catch(error => {
            this.setState({ guardandocambios: false, textguardado: 'Error en la actualización' })
            alert("Error al consultar la información, intente nuevamente")
        })
    }

    fetchUsuarios(condicion){
        this.setState({ loadingUsuarios: true })
        return fetch('https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getUsersCustom',{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingUsuarios: false, usuarios: res })
        })
        .catch(error => {
            this.setState({ loadingUsuarios: false })
            alert("Error al consultar la información, intente nuevamente")
        })
    }
    statusUser(tipo){
        switch (tipo) {
            case '1':
                    return 'Activo'
            default:
                return 'Inactivo'
        }
    }


    formularioNuevoUser(){
        const { showcreacion, rol, guardandocambios } = this.state

        if(guardandocambios){
            return <div>
            <Spinner animation="border" />
            <h3>Guardando información...</h3>
        </div>
        }
        if(showcreacion){

            return <Card className="card-blue">
                
                <Card.Body className="text-white ">
                <Row>
                <Col md={12} >
                { rol._id ? <h3>Editar matriz de {rol.nombre ? rol.nombre : 'usuario'} </h3> : <h3>Crear matriz</h3> }
                </Col>

<Col md={3} >
    <label className="form-control-label">Nombre</label>
    <input name="nombre" className="form-control" value={rol.nombre ? rol.nombre : ''} onChange={this.handleChangeRol} />
</Col>

<Col md={3}>
<label className="form-control-label d-block">Click para { rol._id ? 'editar' : 'crear' } </label>
    { guardandocambios ? <Spinner animation="border" /> : <Button style={{ background: 'white' }} onClick={()=>this.guardarCrear()} variant="outline-white">{ rol._id ? 'GUARDAR CAMBIOS' : 'CREAR' }</Button>}
</Col>

<Col xs={12}>
<p className="m-0" onClick={()=>this.setState({ rol: {} })}>Reiniciar formulario</p>

</Col>

</Row>
                </Card.Body>
            </Card>
        }


    }

    anadirNivel1(){
        const { matriz } = this.state

            if(!matriz.esquema.niveles){
                matriz.esquema = {
                    niveles: []
                }
            }
        

        matriz.esquema.niveles.push({
            nombre:'',
            descripcion:'',
            niveles: []
        })
        console.log(matriz)

        return this.setState({ matriz: matriz })
    }

    anadirNivel2(pos){
        const { matriz } = this.state

        if(!matriz.esquema.niveles[pos].niveles) matriz.esquema.niveles[pos].niveles = []
        matriz.esquema.niveles[pos].niveles.push({
            nombre:'',
            descripcion:'',
            niveles: []
        })

        return this.setState({ matriz: matriz })
    }

    anadirNivel3(posnivel,poslevel){
        const { matriz } = this.state

        if(!matriz.esquema.niveles[posnivel].niveles[poslevel]) matriz.esquema.niveles[posnivel].niveles[poslevel].niveles = []
        matriz.esquema.niveles[posnivel].niveles[poslevel].niveles.push({
            nombre:'',
            descripcion:'',
            habilidades: []
        })

        return this.setState({ matriz: matriz })
    }

    anadirHabilidad(posnivel,poslevel,possublevel){
        const { matriz, idhabilidadseleccion, habilidades } = this.state

        if(!idhabilidadseleccion) return toast.error('Debes seleccionar una habilidad en el buscador', this.state.toaststyle)
        const buscarhab = habilidades.find(hab => hab._id['$oid'] === idhabilidadseleccion )
        if(!buscarhab) return false



        if(!matriz.esquema.niveles[posnivel].niveles[poslevel].niveles[possublevel].habilidades) matriz.esquema.niveles[posnivel].niveles[poslevel].niveles[possublevel].habilidades = []
        const buscarduplicado = matriz.esquema.niveles[posnivel].niveles[poslevel].niveles[possublevel].habilidades.filter(hab => {
            if(!hab._id) return false
            return hab._id['$oid'] === idhabilidadseleccion
        })
        if(buscarduplicado.length > 0) return toast.warn('Ya agregaste anteriormente esta habilidad a este nivel', this.state.toaststyle)
        matriz.esquema.niveles[posnivel].niveles[poslevel].niveles[possublevel].habilidades.push(buscarhab)

        return this.setState({ matriz: matriz })
    }


    eliminarHab(i,po,posicion,ihab){
        confirmAlert({
            title: `¿Deseas eliminar esta habilidad?`,
            //message: '¿Deseas eliminar esta actividad?',
            buttons: [
              {
                label: 'ACEPTAR',
                onClick: () => this.borrarHab(i,po,posicion,ihab)
              },
              {
                label: 'CANCELAR',
              }
            ]
          });
    }

    borrarHab(i,po,posicion,ihab){
        const { matriz } = this.state
        matriz.esquema.niveles[i].niveles[po].niveles[posicion].habilidades.splice(ihab,1)
        return this.setState({ matriz: matriz })
    }

    eliminarElemento = (nivel, i, pos, posicion) => {
        
        
        confirmAlert({
          title: `¿Deseas eliminar este elemento?`,
          //message: '¿Deseas eliminar esta actividad?',
          buttons: [
            {
              label: 'ACEPTAR',
              onClick: () => this.eliminarNivel(nivel, i, pos, posicion)
            },
            {
              label: 'CANCELAR',
            }
          ]
        });
      };

    eliminarNivel(nivel,i, pos, posicion){
        const { matriz } = this.state
        switch (nivel) {
            case 1:
                matriz.esquema.niveles.splice(i,1)
                break;
            case 2:
                matriz.esquema.niveles[i].niveles.splice(pos,1)
                break;
            case 3:
                matriz.esquema.niveles[i].niveles[pos].niveles.splice(posicion,1)
                break;
            default:
                break;
        }
        return this.setState({ matriz: matriz })
    }


    mostrarExcel(){
        const { hojas, procesandoexcel, guardando } = this.state

        if(procesandoexcel){
            return <div>
            <Spinner animation="border" />
            <h4>Analizando documento...</h4>
            </div>
        }

        if(hojas.length > 0){
            return <div>
             {
                 guardando ? <Spinner animation="border" /> : <div>
                    <div className="table-responsive">

                <h5>Encontramos {hojas.length} registros en tu documento excel </h5>
                <Table responsive hover> 
                    <thead>
                        <tr>
                            <th>Nombre</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            hojas.map((usuario,iu) => {
                                return <tr key={`user${iu}`}>
                            <th>{usuario.nombre}</th>
                                </tr>
                            })
                        }
                    </tbody>
                </Table>


                        </div>

            <button className="btn btn-success" onClick={() => this.subirAreas()}>SUBIR ARCHIVO Y ACTUALIZAR BASE DE DATOS</button>
            
            </div>
             }   
            </div>
        }
    }

    subirAreas(){


        return toast.warn('Esta función no se está disponible', this.state.toaststyle)


    }

    mostrarMatriz(){
        const { loadingmatrices, habilidades, textguardado, guardandocambios, matriz } = this.state

        if(loadingmatrices){
            return <div>
                <Spinner animation="border" />
                <h3>Cargando información</h3>
            </div>
        }

        let optionshabilidades = []
        habilidades.map(hab => {
            optionshabilidades.push({
                value: hab._id['$oid'],
                label: hab.nombre
            })
        })
        
        return <Card style={{ width: '100%' }}>
        <Card.Body>
          <Card.Title style={{ float: 'left' }}><h2 className="mb-0">{matriz.nombre}</h2>
          <span className="spanclass" style={{ background: matriz.tipo === 'evaluacion' ? '#208bff' : '#ffcb0f' }}>{matriz.tipo}</span>
          </Card.Title>
          { guardandocambios ? <Spinner animation="border" /> : <Button style={{ float: 'right', marginBottom: 15 }} onClick={()=> this.guardarCrear()}  variant="outline-success">GUARDAR CAMBIOS</Button>}
          <div style={{ clear: 'both' }}><hr /></div>
        { textguardado ? <h2> {textguardado} </h2> : false }

          <Row>

              <Col xs={12}>

            <button className="btn btn-outline-primary mb-3" onClick={()=>this.anadirNivel1()}>AÑADIR NIVEL</button>
            <hr />
            <div className="mb-2">
            <a href="matrizhabilidades.xlsx" className="btn btn-sm btn-outline-warning" >DESCARGAR MODELO EXCEL</a>
            </div>
            <div className="form-group">
            <label className="form-control-label">Carga tu documento excel con la base de esta matriz</label>
            <input 
            required 
            type="file" 
            name="file" 
            id="file" 
            className="form-control"
            onChange={this.handleInputChange} 
            placeholder="Archivo de excel" 
            />
            </div>

            {this.mostrarExcel()}
            <hr />
            { matriz.esquema.niveles ? <h4>{matriz.esquema.niveles.length} Niveles </h4> : false } 
            <Accordion>
            {
                matriz.esquema.niveles ? <div>

                        {
                            matriz.esquema.niveles.length > 0 ? matriz.esquema.niveles.map((nivel,i) => {
                                return  <Card key={`item-${i}`} className="mb-3">
                                <Accordion.Toggle as={Card.Header} eventKey={i+1}>
                                    <label className="form-control-label d-block">Título</label>
                                  <input value={nivel.nombre} autoComplete="off" className="titularaccordion" placeholder="Escribe un nombre para esta sección" name="nombre" pos={i} onChange={this.handleChange} />
                                  <button className="btn btn-sm btn-outline-danger onclick" style={{ float: 'right' }} onClick={()=>this.eliminarElemento(1,i)}>ELIMINAR</button>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={i+1}>
                                  <Card.Body>
                                  <label className="form-control-label d-block">Descripción</label>
                                  <input value={nivel.descripcion} style={{ width: '100%'}} autoComplete="off" className="titularaccordion mb-3" placeholder="Escribe una descripción para esta sección" name="descripcion" pos={i} onChange={this.handleChange} />
                                  <button className="btn btn-sm btn-primary mb-3" onClick={()=>this.anadirNivel2(i)}>AÑADIR NIVEL</button>
                                  <Accordion>
                                    {
                                        nivel.niveles.length > 0 ? <div>
                                            <h4>{nivel.niveles.length} Niveles </h4>
                                            {
                                                nivel.niveles.map((level,po) => {
                                                    return  <Card key={`level-${po}`} className="mb-3">
                                                    <Accordion.Toggle as={Card.Header} eventKey={`level${po}`}>
                                                        <label className="form-control-label d-block">Título</label>
                                                        <input value={level.nombre} autoComplete="off" className="titularaccordion" placeholder="Escribe un nombre para esta sección" name="nombre" pos={i} poslevel={po} onChange={this.handleChange2} />
                                                        <button className="btn btn-sm btn-outline-danger" style={{ float: 'right' }} onClick={()=>this.eliminarElemento(2,i,po)}>ELIMINAR</button>
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey={`level${po}`}>
                                                      <Card.Body>
                                                      <button className="btn btn-sm btn-primary mb-3" onClick={()=>this.anadirNivel3(i,po)}>AÑADIR NIVEL</button>
                                          <Accordion>
        
                                          {
                                                level.niveles.length > 0 ? <div>
                                                    <h4>{level.niveles.length} Niveles </h4>
                                                    {
                                                        level.niveles.map((levex,posicion) => {
                                                            return  <Card key={`level-${posicion}`} className="mb-3">
                                                            <Accordion.Toggle as={Card.Header} eventKey={`levelx${posicion}`}>
                                                                <label className="form-control-label d-block">Título</label>
                                                                <input value={levex.nombre} autoComplete="off" className="titularaccordion" placeholder="Escribe un nombre para esta sección" name="nombre" pos={i} poslevel={po} level={posicion} onChange={this.handleChange3} />
                                                                <button className="btn btn-sm btn-outline-danger" style={{ float: 'right' }} onClick={()=>this.eliminarElemento(3,i,po,posicion)}>ELIMINAR</button>
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse eventKey={`levelx${posicion}`}>
                                                              <Card.Body>
                                                              <label className="form-control-label d-block">Biblioteca de habilidades</label>
                                                              <Select options={optionshabilidades} className="mb-3" placeholder="Busca aquí una habilidad para añadirla" onChange={this.handleChangeHabilidadAgregar}  />
                                                              <button className="btn btn-sm btn-success mb-3" onClick={()=>this.anadirHabilidad(i,po,posicion)}>AÑADIR HABILIDAD</button>
                                                                  {
                                                                      levex.habilidades.length > 0 ? <div>
                                                                          <h4>{levex.habilidades.length} Habilidades </h4>
                                                                          <hr className="mb-2 mt-2" />
                                                                          {
                                                                              levex.habilidades.map((hab,ihab) => {
                                                                                  return <div key={`habi-${ihab}`}>
                                                                                      <h4 className="mb-0"><i className="fas fa-arrow-right"></i> {hab.nombre ? hab.nombre : `Habilidad ${ihab+1}`}</h4>
                                                                                      <small className="text-primary onclick" style={{ marginLeft: 28 }} onClick={()=>this.showHabilidad(i,po,posicion,ihab,hab)}>EDITAR</small>
                                                                                      <small className="text-danger ml-3 onclick" onClick={()=>this.eliminarHab(i,po,posicion,ihab)}>ELIMINAR</small>
                                                                                      <hr className="mb-2 mt-2" />
                                                                                      </div>
                                                                              })
                                                                          }
                                                                      </div> : false
                                                                  }
                                                              </Card.Body>
                                                            </Accordion.Collapse>
                                                          </Card>
                                                        })
                                                    }
                                                </div> : false
                                            }
        
                                          </Accordion>
                                                          
                                                      </Card.Body>
                                                    </Accordion.Collapse>
                                                  </Card>
                                                })
                                            }
                                        </div> : false
                                    }
                                    </Accordion>
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            }) : false
                        }

                </div> : <div><h4>No hay un esquema asignado a esta matriz</h4><p>Añade un nivel a esta matriz de habilidades para comenzar a estructurar tu esquema</p></div>
            }
            </Accordion>


              </Col>

          </Row>          

          

        </Card.Body>
      </Card>
    }

    showHabilidad(i,po,posicion,ihab,habilidad){

        return confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="custom-ui">

                    <h3>Editar habilidad</h3>

                    <label className="form-control-label">Nombre de habilidad</label>
                    <input className="form-control mb-2" name="nombre" defaultValue={habilidad.nombre} pos={i} poslevel={po} level={posicion} ihab={ihab}  onChange={this.handleChangeHabilidad} />

                    <label className="form-control-label">Descripción de habilidad</label>
                    <input className="form-control mb-2" name="descripcion" defaultValue={habilidad.descripcion} pos={i} poslevel={po} level={posicion} ihab={ihab}  onChange={this.handleChangeHabilidad}/>

                    <label className="form-control-label">Nota 1</label>
                    <input className="form-control mb-2" name="nota1" defaultValue={habilidad.nota1} pos={i} poslevel={po} level={posicion} ihab={ihab}  onChange={this.handleChangeHabilidad}/>

                    <label className="form-control-label">Nota 2</label>
                    <input className="form-control mb-2" name="nota2" defaultValue={habilidad.nota2} pos={i} poslevel={po} level={posicion} ihab={ihab}  onChange={this.handleChangeHabilidad}/>

                    <label className="form-control-label">Nota 3</label>
                    <input className="form-control mb-2" name="nota3" defaultValue={habilidad.nota3} pos={i} poslevel={po} level={posicion} ihab={ihab}  onChange={this.handleChangeHabilidad}/>

                    <label className="form-control-label">Nota 4</label>
                    <input className="form-control mb-2" name="nota4" defaultValue={habilidad.nota4} pos={i} poslevel={po} level={posicion} ihab={ihab}  onChange={this.handleChangeHabilidad}/>

                    <label className="form-control-label">Nota 5</label>
                    <input className="form-control mb-2" name="nota5" defaultValue={habilidad.nota5} pos={i} poslevel={po} level={posicion} ihab={ihab}  onChange={this.handleChangeHabilidad}/>

                    <label className="form-control-label">Nivel esperado por defecto</label>
                    <input className="form-control mb-2" type="number" min="1" max="5" name="esperado" defaultValue={habilidad.esperado} pos={i} poslevel={po} level={posicion} ihab={ihab}  onChange={this.handleChangeHabilidad}/>
                    
                </div>
              )
        }
    })
    }
    
    render(){

        return(
            <div className="fluid">
       <Header/>
       <ToastContainer 
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          />   
<div className="container-fluid">
  <div className="row">
   <Nav/>
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Matriz de habilidades</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
          <div className="btn-group mr-2">
            <button type="button" className="btn btn-sm btn-outline-secondary">Compartir</button>
            <button type="button" className="btn btn-sm btn-outline-secondary">Exportar</button>
          </div>
          <button type="button" className="btn btn-sm btn-outline-secondary dropdown-toggle">
          <i className="fas fa-calendar-alt"></i> Esta semana
          </button>
        </div>
      </div>
      {this.mostrarMatriz()}
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(editarMatrizHabilidades);