import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button } from 'react-bootstrap'
import Header from '../Header'
import Nav from '../Nav'
import Spinner from 'react-bootstrap/Spinner'
import Table from 'react-bootstrap/Table'
import * as XLSX from 'xlsx'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { checkForPermissions } from '../../helpers/index'

class Usuarios extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            usuarios: [],
            loadingUsuarios: true,
            filtrousuario: '',
            usuario: {},
            tipousuario: '',
            showcreacion: false,
            textguardado: '',
            loadingroles: true,
            roles: [],
            loadingcargos: true,
            cargos: [],
            areas: [],
            loadingareas: true,
            hojas: [],
            procesandoexcel: false,
            guardando: false,
            toaststyle: { position: toast.POSITION.BOTTOM_RIGHT }

        }

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeUsuario = this.handleChangeUsuario.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)

    }

    handleInputChange (event) {
        const { areas, cargos} = this.state
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        this.setState({
          [name]: value
        })
        let hojas = []
        if (name === 'file') {
            this.setState({procesandoexcel:true})
          let reader = new FileReader()
          reader.readAsArrayBuffer(target.files[0])
          reader.onloadend = (e) => {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, {type: 'array'});
    
            workbook.SheetNames.forEach(function(sheetName) {
              var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
              hojas.push(XL_row_object)
            })
            
            const requeridos = [
                'nombres',
                'apellidopaterno',
                'apellidomaterno',
                'rut',
                'fechanacimiento',
                'email',
                'password',
                'area',
                'cargo'
            ]

            const convertirtexto = [
                'nombres',
                'apellidopaterno',
                'apellidomaterno',
                'rut',
                'fechanacimiento',
                'email',
                'password',
                //'area',
                //'cargo'
            ]
            const columnasincompletas = []
            hojas[0].forEach((row,i) => {
                const faltantes = []
                requeridos.map(req => {
                    if(!row[req]) faltantes.push(req)
                })
                if(row.rut) hojas[0][i].rut = row.rut.replace(/[\W_]+/g, "")

                convertirtexto.map(campo => {
                    if(row[campo]) hojas[0][i][campo] = row[campo].toString()
                })

                if(row.area){
                    const iarea = areas.findIndex(ar => ar.nombre === row.area)
                    if(areas[iarea]){
                        hojas[0][i].area = areas[iarea]._id['$oid']
                    } else {
                        columnasincompletas.push(`El área ${row.area} no existe, columna ${i+1}`)
                    }
                }

                if(row.cargo){
                    const icargo = cargos.findIndex(ar => ar.nombre === row.cargo)
                    if(cargos[icargo]){
                        hojas[0][i].cargo = cargos[icargo]._id['$oid']
                    } else {
                        columnasincompletas.push(`El cargo ${row.cargo} no existe, columna ${i+1}`)
                    }
                }

                if(faltantes.length > 0) columnasincompletas.push(`La columna ${i+1} le faltan campos: ${faltantes.join(', ')}`)
            })

            if(columnasincompletas.length > 0){
                this.setState({ procesandoexcel: false })
                columnasincompletas.push('Corrige el documento e inténtalo de nuevo')
                return toast.error( columnasincompletas.join('. '), this.state.toaststyle )
            }
            
            this.setState({
              selectedFileDocument: target.files[0],
              hojas: hojas[0],
              procesandoexcel: false
            })
          }
        }
      }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    handleChangeUsuario(e){
        const { name, value } = e.target
        const { usuario } = this.state
        usuario[name] = value
        if(name==='rut') usuario[name] = value.replace(/[\W_]+/g, "")
        console.log(usuario)
        return this.setState({ usuario: usuario })
    }

    componentDidMount(){
        const { user } = this.state
        this.getUsuariosByTipo(user)
        this.getCargos({ propietario: user.propietario })
        this.getAreas(user.propietario)
    }

    getAreas(propietario){

        this.setState({ loadingareas: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getAreas?propietario=${propietario}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingareas: false, areas: res })
        })
        .catch(error => {
            this.setState({ loadingareas: false })
            alert("Error al consultar la información, intente nuevamente")
        })

    }

    getCargos(condicion){
        console.log(condicion)
        this.setState({ loadingcargos: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_GetCargos`,{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingcargos: false, cargos: res })
        })
        .catch(error => {
            this.setState({ loadingcargos: false })
            alert("Error al consultar la información, intente nuevamente")
        })

    }

    getRoles(tipo){

        this.setState({ loadingroles: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getRolesUsuario?tipo=${tipo}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingroles: false, roles: res })
        })
        .catch(error => {
            this.setState({ loadingroles: false })
            alert("Error al consultar la información, intente nuevamente")
        })

    }

    validarEmail(email){
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }
    
    guardarCrear(){
        const { usuario, user } = this.state

        if(usuario._id){
            const autorizar = checkForPermissions( user,'usuarios','editar')
        if(!autorizar) return toast.error('No estás autorizado para realizar esta operación', this.state.toaststyle)
        } else {
            const autorizarv = checkForPermissions( user,'usuarios','crear')
            if(!autorizarv) return toast.error('No estás autorizado para realizar esta operación', this.state.toaststyle)
        }
        let requeridos = [ 'nombres', 'rut', 'email' , 'tipo']
        let faltantes = []
        requeridos.map(val => {
            if(!usuario[val]) faltantes.push(val)
            return true
        })
        if(faltantes.length > 0) return toast.warning(`Faltan campos: ${faltantes.join(', ')}`, this.state.toaststyle)
        const validar_correo = this.validarEmail(usuario.email)
        if(!validar_correo) return toast.error('Email inválido', this.state.toaststyle)
        usuario.propietario = user.propietario
        usuario.email = usuario.email ? usuario.email.toLowerCase() : ''
        this.setState({ guardandocambios: true })
        return fetch('https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_editarCrearUsuario',{
            method: 'POST',
            body: JSON.stringify(usuario),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            if(res.errorMessage){
                toast.error(res.errorMessage, this.state.toaststyle)
                this.setState({ guardandocambios: false })
            }
            const texto = usuario._id ? 'Usuario modificado exitosamente' : 'Usuario creado exitosamente'
            toast.success(texto, this.state.toaststyle)
            this.setState({ guardandocambios: false })
            this.getUsuariosByTipo(user)
        })
        .catch(error => {
            this.setState({ guardandocambios: false })
            alert("Error al consultar la información, intente nuevamente")
        })
    }

    fetchUsuarios(condicion){
        this.setState({ loadingUsuarios: true })
        return fetch('https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getUsersCustom',{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingUsuarios: false, usuarios: res })
        })
        .catch(error => {
            this.setState({ loadingUsuarios: false })
            alert("Error al consultar la información, intente nuevamente")
        })
    }
    getUsuariosByTipo(user){

        switch (user.tipo) {
            case 'admin':
                this.getRoles(user.tipo)
                return this.fetchUsuarios({ propietario: user.propietario })
                break;
            case 'evaluator':
                this.getRoles(user.tipo)
                return this.fetchUsuarios({ propietario: user.propietario, tipo: 'empleado' })
                break;
            default:
                this.setState({ loadingUsuarios: false })
                break;
        }
    }

    statusUser(tipo){
        switch (tipo) {
            case '1':
                    return 'Activo'
                    break;
            default:
                return 'Inactivo'
                break;
        }
    }

    tipoUser(tipo){
        switch (tipo) {
            case 'admin':
                    return 'Administrador'
                    break;
            case 'evaluator':
                    return 'Evaluador'
                    break;
            case 'empleado':
                    return 'Usuario'
                    break;        
            default:
                return tipo
                break;
        }
    }

    formularioNuevoUser(){
        const { showcreacion, usuario, areas, cargos, guardandocambios, roles } = this.state

        if(guardandocambios){
            return <div>
            <Spinner animation="border" />
            <h3>Guardando información...</h3>
        </div>
        }
        if(showcreacion){

            return <Card className="card-blue" id="formulario">
                
                <Card.Body className="text-white ">
                <Row>
                <Col md={12} >
                { usuario._id ? <h3>Editar {usuario.nombres ? usuario.nombres : 'usuario'} </h3> : <h3>Crear usuario</h3> }
                </Col>

<Col md={3} >
    <label className="form-control-label">Nombres</label>
    <input name="nombres" className="form-control" value={usuario.nombres ? usuario.nombres : ''} onChange={this.handleChangeUsuario} />
</Col>

<Col md={3} >
    <label className="form-control-label">Apellido paterno</label>
    <input name="apellidopaterno" className="form-control" value={usuario.apellidopaterno ? usuario.apellidopaterno : ''} onChange={this.handleChangeUsuario} />
</Col>

<Col md={3} >
    <label className="form-control-label">Apellido materno</label>
    <input name="apellidomaterno" className="form-control" value={usuario.apellidomaterno ? usuario.apellidomaterno : ''} onChange={this.handleChangeUsuario} />
</Col>

<Col md={3} >
    <label className="form-control-label">Rut</label>
    <input name="rut" className="form-control" value={usuario.rut ? usuario.rut : ''} onChange={this.handleChangeUsuario} />
</Col>

<Col md={3} >
    <label className="form-control-label">Fecha nacimiento</label>
    <input name="fechanacimiento" type="date" className="form-control" value={usuario.fechanacimiento ? usuario.fechanacimiento : ''} onChange={this.handleChangeUsuario} />
</Col>

<Col md={3} >
    <label className="form-control-label">Email</label>
    <input name="email" className="form-control" value={usuario.email ? usuario.email : ''} onChange={this.handleChangeUsuario} />
</Col>

<Col md={3} >
    <label className="form-control-label">Password</label>
    <input name="password" className="form-control" value={usuario.password ? usuario.password : ''} onChange={this.handleChangeUsuario} />
</Col>

<Col md={3} >
    <label className="form-control-label">Tipo</label>
    <select name="tipo" className="form-control" value={usuario.tipo ? usuario.tipo : ''} onChange={this.handleChangeUsuario} >
        <option value="">Seleccione</option>
        {
            roles.length > 0 ? roles.map(rol => {
                return <option value={rol.slug}> {rol.nombre} </option>
            }) : false
        }
    </select>
</Col>

<Col md={3} >
    <label className="form-control-label d-block">Area de trabajo</label>
    <select name="area" className="form-control" value={usuario.area ? usuario.area : ''} onChange={this.handleChangeUsuario} >
        <option value="">Seleccione</option>
        {
            areas.length > 0 ? areas.map(area => {
                return <option value={area._id['$oid']}> {area.nombre} </option>
            }) : false
        }
    </select>
</Col>

<Col md={3} >
    <label className="form-control-label d-block">Cargo</label>
    <select name="cargo" className="form-control" value={usuario.cargo ? usuario.cargo : ''} onChange={this.handleChangeUsuario} >
        <option value="">Seleccione</option>
        {
            cargos.length > 0 ? cargos.map(area => {
                return <option value={area._id['$oid']}> {area.nombre} </option>
            }) : false
        }
    </select>
</Col>

<Col md={3} >
    <label className="form-control-label d-block">Estado</label>
    <select name="status" className="form-control" value={usuario.status ? usuario.status : ''} onChange={this.handleChangeUsuario} >
        <option value="1">Activo</option>
        <option value="2">Inactivo</option>
    </select>
</Col>

<Col md={12} style={{ padding: 15 }}>
    <Button style={{ background: 'white' }} onClick={()=>this.guardarCrear()} variant="outline-white">{ usuario._id ? 'GUARDAR CAMBIOS' : 'CREAR' }</Button>
    <p className="m-0 onclick" onClick={()=>this.setState({ usuario: {} })}>Reiniciar formulario</p>
</Col>

</Row>
                </Card.Body>
            </Card>
        }


    }

    mostrarExcel(){
        const { hojas, procesandoexcel, guardando, areas, cargos } = this.state

        if(procesandoexcel){
            return <div>
            <Spinner animation="border" />
            <h4>Analizando documento...</h4>
            </div>
        }

        if(hojas.length > 0){
            return <div>
             {
                 guardando ? <Spinner animation="border" /> : <div>
                    <div className="table-responsive">

                <h5>Encontramos {hojas.length} registros en tu documento excel </h5>
                <Table responsive hover> 
                    <thead>
                        <tr>
                            <th>Nombres</th>
                            <th>Apellido Paterno</th>
                            <th>Apellido Materno</th>
                            <th>Rut</th>
                            <th>Fecha de nacimiento</th>
                            <th>Email</th>
                            <th>Password</th>
                            <th>Área de trabajo</th>
                            <th>Cargo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            hojas.map((usuario,iu) => {
                                const iarea = areas.findIndex(ar => ar._id['$oid'] === usuario.area)
                                const icargo = cargos.findIndex(ar => ar._id['$oid'] === usuario.cargo)
                                let area = ''
                                let cargo = ''
                                if(areas[iarea]){
                                    area = areas[iarea].nombre
                                }
                                if(cargos[icargo]){
                                    cargo = cargos[icargo].nombre
                                }

                                return <tr key={`user${iu}`}>
                            <th>{usuario.nombres}</th>
                            <th>{usuario.apellidopaterno}</th>
                            <th>{usuario.apellidomaterno}</th>
                            <th>{usuario.rut}</th>
                            <th>{usuario.fechanacimiento}</th>
                            <th>{usuario.email}</th>
                            <th>{usuario.password}</th>
                            <th>{area}</th>
                            <th>{cargo}</th>
                                </tr>
                            })
                        }
                    </tbody>
                </Table>


                        </div>
            {
                hojas.map(usuario => {

                })
            }

            { guardando ? <div>
            <Spinner animation="border" />
            <h3>Espere un momento...</h3>
        </div> : <button className="btn btn-success" onClick={() => this.subirUsuarios()}>SUBIR ARCHIVO Y ACTUALIZAR BASE DE DATOS</button> } 
            
            </div>
             }   
            </div>
        }
    }

    subirUsuarios(){
        const { user, hojas } = this.state
        this.setState({ guardando: true })

        return fetch('https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_CrearUsuariosBatch',{
            method: 'POST',
            body: JSON.stringify({
                usuarios: hojas,
                propietario: user.propietario
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            this.setState({ guardando: false, hojas: [] })
            toast.success('Usuarios cargados exitosamente', this.state.toaststyle )
            this.getUsuariosByTipo(user)
        })
        .catch(error => {
            this.setState({ guardando: false })
            return toast.error('Error al consultar la información, intente nuevamente', this.state.toaststyle)
        })
    }

    mostrarUsuarios(){
        const { loadingUsuarios, usuarios, cargos, areas, filtrousuario, textguardado, tipousuario, showcreacion } = this.state

        if(loadingUsuarios){
            return <div>
                <Spinner animation="border" />
                <h3>Cargando información</h3>
            </div>
        }

        const lowercasedFilter = filtrousuario.toLowerCase();
        const usuariosFiltrados = usuarios.filter(item => {
            return Object.keys(item).some(key =>
                item[key].toString().toLowerCase().includes(lowercasedFilter)
            );
        });
        const filtradosportipo = tipousuario ? usuariosFiltrados.filter(user => user.tipo===tipousuario) : usuariosFiltrados

        return <Card style={{ width: '100%' }}>
        <Card.Body>
          <Card.Title style={{ float: 'left' }}>{usuarios.length} usuarios encontrados</Card.Title>
          <Button style={{ float: 'right', marginBottom: 15 }} onClick={()=> this.setState({ showcreacion: showcreacion ? false : true, textguardado: '' })}  variant="outline-primary">CREAR</Button>
          <div style={{ clear: 'both' }}><hr /></div>
        { textguardado ? <h2> {textguardado} </h2> : false }
        {this.formularioNuevoUser()}
          <Row>

            <Col xs={12}>
            <img src="icon3.jpg" alt="icono" className="iconsystem" />
            <h4>Usuarios que forman parte de esta empresa</h4>
            <hr />
            <div className="form-group">
            <label className="form-control-label">Carga tu documento excel con la base de tus usuarios</label>
            <div className="mb-2">
            <a href="usuarios.xlsx" className="btn btn-sm btn-outline-warning" >DESCARGAR MODELO EXCEL</a>
            </div>
            <input 
            required 
            type="file" 
            name="file" 
            id="file" 
            className="form-control"
            onChange={this.handleInputChange} 
            placeholder="Archivo de excel" 
            />
            </div>

            {this.mostrarExcel()}
            <hr />
            </Col>

            <Col md={3}>
            <label className="form-control-label">Filtrar resultados</label>
            <input name="filtrousuario" className="form-control mb-4" placeholder="Escribe cualquier palabra clave" onChange={this.handleChange} />
            </Col>

            <Col md={3}>
            <label className="form-control-label">Filtrar tipo</label>
            <select name="tipousuario" className="form-control mb-4" onChange={this.handleChange}>
                <option value="">Seleccione</option>
                <option value="admin">Administrador</option>
                <option value="evaluator">Evaluador</option>
                <option value="empleado">Usuario</option>
            </select>
            </Col>

            {filtrousuario !== '' || tipousuario !== '' ? <Col xs={12}><label className="form-control-label">{filtradosportipo.length} Coincicencias</label></Col>:false}
          
          </Row>          

          <Table responsive hover>
  <thead>
    <tr>
      <th>Nombres</th>
      <th>Apellido paterno</th>
      <th>Apellido materno</th>
      <th>Estado</th>
      <th>Rut</th>
      <th>Área</th>
      <th>Cargo</th>
      <th>Tipo</th>
      <th>Email</th>
      <th>Fecha nacimiento</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    { filtradosportipo.map((user,i)=>{

        let cargo = {}
        let area = {}
        if(user.cargo){
            if(cargos.length > 0){
                cargo = cargos.find(carg => carg._id['$oid']===user.cargo)
            }
        }
        if(user.area){
            if(areas.length > 0){
                area = areas.find(ar => ar._id['$oid']===user.area)
            }
        }
        console.log(user)
        return <tr key={`user${i}`}>
            <th>{user.nombres}<br/><small className="text-primary onclick" onClick={()=>{
                this.setState({ showcreacion: true, usuario: user, textguardado: '' })
                setTimeout(() => {
                    const div = document.getElementById('formulario')
                    if(div) div.scrollIntoView()
                }, 500);
                }}>Editar</small></th>
            <th>{user.apellidopaterno}</th>
            <th>{user.apellidomaterno}</th>
            <th>{this.statusUser(user.status)}</th>
            <th>{user.rut}</th>
            <th>{area._id ? area.nombre : 'Sin área asignada'}</th>
            <th>{cargo._id ? cargo.nombre : 'Sin cargo asignado'}</th>
            <th>{this.tipoUser(user.tipo)}</th>
            <th>{user.email}</th>
            <th>{user.fechanacimiento}</th>
            <th></th>
        </tr>
    }) }
  </tbody>
</Table>

        </Card.Body>
      </Card>
    }
    
    render(){
        return(
            <div className="fluid">
       <Header/>
       <ToastContainer />   
<div className="container-fluid">
  <div className="row">
   <Nav/>
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2"> Usuarios</h1>
      </div>

      {this.mostrarUsuarios()}
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(Usuarios);