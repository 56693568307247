import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Row, Col, Card, Button } from 'react-bootstrap'
import Header from '../Header'
import Nav from '../Nav'
import Spinner from 'react-bootstrap/Spinner'
import Table from 'react-bootstrap/Table'
import { ToastContainer, toast } from 'react-toastify';

class MatrizHabilidades extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            matrices: [],
            loadingUsuarios: true,
            filtroroles: '',
            rol: {},
            showcreacion: false,
            textguardado: '',
            loadingroles: true,
            roles: [],
            loadingmatrices: true,
            toaststyle: { position: toast.POSITION.BOTTOM_RIGHT }
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeRol = this.handleChangeRol.bind(this)
    }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    handleChangeRol(e){
        const { name, value } = e.target
        const { rol } = this.state
        rol[name] = value
        console.log(rol)
        return this.setState({ rol: rol })
    }

    componentDidMount(){
        const { user } = this.state
        //this.getRoles(user.tipo)
        this.getMatrices({ propietario: user.propietario })
    }

    getMatrices(condicion){

        this.setState({ loadingmatrices: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_VerMatricesHabilidades`,{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingmatrices: false, matrices: res })
        })
        .catch(error => {
            this.setState({ loadingmatrices: false })
            alert("Error al consultar la información, intente nuevamente")
        })

    }

    getRoles(tipo){

        this.setState({ loadingroles: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getRolesUsuario?tipo=${tipo}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingroles: false, roles: res })
        })
        .catch(error => {
            this.setState({ loadingroles: false })
            alert("Error al consultar la información, intente nuevamente")
        })

    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }
    
    guardarCrear(){
        const { rol, user } = this.state

        if(!rol.nombre){
            return toast.warn("Debes escribir el nombre de tu matriz", this.state.toaststyle)
        }

        if(!rol.tipo){
            return toast.warn("Debes seleccionar un tipo de matriz", this.state.toaststyle)
        }

        const tipospermitidos = [
            'insignia',
            'evaluacion'
        ]
        if(!tipospermitidos.includes(rol.tipo)) return toast.warn(`Tipo de habilidad no válida, permitidos: insignia o evaluacion`)


        rol.propietario = user.propietario
        rol.status = '1'
        rol.creacion = this.formatDate(new Date())

        this.setState({ guardandocambios: true })
        return fetch('https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_CrearMatriz',{
            method: 'POST',
            body: JSON.stringify(rol),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            this.setState({ guardandocambios: false, rol: {}, crear: false })
            this.getMatrices({ propietario: user.propietario })
            return toast.success('Guardado exitosamente', this.state.toaststyle)
        })
        .catch(error => {
            this.setState({ guardandocambios: false })
            alert("Error al consultar la información, intente nuevamente")
        })
    }

    fetchUsuarios(condicion){
        this.setState({ loadingUsuarios: true })
        return fetch('https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getUsersCustom',{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingUsuarios: false, usuarios: res })
        })
        .catch(error => {
            this.setState({ loadingUsuarios: false })
            alert("Error al consultar la información, intente nuevamente")
        })
    }
    getUsuariosByTipo(user){

        switch (user.tipo) {
            case 'admin':
                this.getRoles(user.tipo)
                return this.fetchUsuarios({ propietario: user.propietario })
                break;
            case 'evaluator':
                this.getRoles(user.tipo)
                return this.fetchUsuarios({ propietario: user.propietario, tipo: 'empleado' })
                break;
            default:
                this.setState({ loadingUsuarios: false })
                break;
        }

    }

    statusUser(tipo){
        switch (tipo) {
            case '1':
                    return 'Activo'
                    break;
            default:
                return 'Inactivo'
                break;
        }
    }

    tipoUser(tipo){
        switch (tipo) {
            case 'admin':
                    return 'Administrador'
                    break;
            case 'evaluator':
                    return 'Evaluador'
                    break;
            case 'empleado':
                    return 'Usuario'
                    break;        
            default:
                return tipo
                break;
        }
    }

    formularioNuevoUser(){
        const { showcreacion, rol, guardandocambios, matrices } = this.state

        if(guardandocambios){
            return <div>
            <Spinner animation="border" />
            <h3>Guardando información...</h3>
        </div>
        }
        if(showcreacion){

            return <Card className="card-blue">
                
                <Card.Body className="text-white ">
                <Row>
                <Col md={12} >
                { rol._id ? <h3>Editar matriz de {rol.nombre ? rol.nombre : 'usuario'} </h3> : <h3>Crear matriz</h3> }
                </Col>

<Col md={3} >
    <label className="form-control-label">Nombre</label>
    <input name="nombre" className="form-control" value={rol.nombre ? rol.nombre : ''} onChange={this.handleChangeRol} />
</Col>

<Col md={3} >
    <label className="form-control-label">Tipo de habilidad</label>
    <select className="form-control" name="tipo" value={rol.tipo ? rol.tipo : ''} onChange={this.handleChangeRol} >
        <option value="" >Selecciona una opción</option>
        <option value="insignia" >Insignia</option>
        <option value="evaluacion">Evaluación</option>
    </select>
</Col>

<Col md={3}>
<label className="form-control-label d-block">Click para { rol._id ? 'editar' : 'crear' } </label>
    <Button style={{ background: 'white' }} onClick={()=>this.guardarCrear()} variant="outline-white">{ rol._id ? 'GUARDAR CAMBIOS' : 'CREAR' }</Button>
</Col>

<Col xs={12}>
<p className="m-0" onClick={()=>this.setState({ rol: {} })}>Reiniciar formulario</p>

</Col>

</Row>
                </Card.Body>
            </Card>
        }


    }

    mostrarRoles(){
        const { loadingmatrices, filtroroles, textguardado, matrices, showcreacion } = this.state

        if(loadingmatrices){
            return <div>
                <Spinner animation="border" />
                <h3>Cargando información</h3>
            </div>
        }

        const lowercasedFilter = filtroroles.toLowerCase();
        const matricesFiltradas = matrices.filter(item => {
            return Object.keys(item).some(key =>
                {
                    if(!item[key]) return false
                    return item[key].toString().toLowerCase().includes(lowercasedFilter)
                }
            );
        });
        
        return <Card style={{ width: '100%' }}>
        <Card.Body>
          <Card.Title style={{ float: 'left' }}>{matrices.length} matrices encontradas</Card.Title>
          <Button style={{ float: 'right', marginBottom: 15 }} onClick={()=> this.setState({ showcreacion: showcreacion ? false : true, textguardado: '' })}  variant="outline-primary">CREAR</Button>
          <div style={{ clear: 'both' }}><hr /></div>
        { textguardado ? <h2> {textguardado} </h2> : false }
        {this.formularioNuevoUser()}
          <Row>

            <Col md={3}>
            <label className="form-control-label">Filtrar resultados</label>
            <input name="filtroroles" className="form-control mb-4" placeholder="Escribe cualquier palabra clave" onChange={this.handleChange} />
            </Col>

            

            {filtroroles !== '' ? <Col xs={12}><label className="form-control-label">{matricesFiltradas.length} Coindicencias</label></Col>:false}
          
          </Row>          

          <Table responsive hover>
  <thead>
    <tr>
      <th>Nombre</th>
      <th>Tipo</th>
      <th>Estado</th>
      <th>Creación</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    { matricesFiltradas.map((rol,i)=>{
        console.log(rol)
        return <tr key={`user${i}`}>
            <th>{rol.nombre}<br/><Link to={`/editarhab-${rol._id['$oid']}`}> <small className="text-primary" >Editar</small></Link> <small style={{ fontWeight: 600 }} onClick={()=>this.duplicarElemento(rol._id['$oid'])} className="text-primary onclick">Duplicar</small> </th>
            <th><span className="spanclass" style={{ background: rol.tipo === 'evaluacion' ? '#208bff' : '#ffcb0f' }}>{rol.tipo}</span></th>
            <th>{this.statusUser(rol.status)}</th>
            <th>{rol.creacion}</th>
            <th></th>
        </tr>
    }) }
  </tbody>
</Table>

        </Card.Body>
      </Card>
    }


    duplicarElemento(id){
        const { user } = this.state
        this.setState({ loadingmatrices: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_duplicarMatriz?id=${id}`)
        .then(res => res.json())
        .then(res => {

            this.setState({ loadingmatrices: false})
            this.getMatrices({ propietario: user.propietario })

        })
        .catch(error => {
            this.setState({ loadingmatrices: false })
            alert("Error al consultar la información, intente nuevamente")
        })
    }
    
    render(){

        const {miusuario} = this.props
        const { errorLogin } = this.state
        return(
            <div className="fluid">
       <Header/>   
       <ToastContainer />
<div className="container-fluid">
  <div className="row">
   <Nav/>
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Matriz de habilidades</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
        </div>
      </div>

      {this.mostrarRoles()}
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(MatrizHabilidades);