import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button } from 'react-bootstrap'
import Header from '../Header'
import Nav from '../Nav'
import Spinner from 'react-bootstrap/Spinner'
import Table from 'react-bootstrap/Table'
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { es } from 'date-fns/locale'
import Accordion from 'react-bootstrap/Accordion'

const isObject = (obj) => {
    return Object.prototype.toString.call(obj) === '[object Object]';
};

class Capacitaciones extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            matrices: [],
            loadingUsuarios: true,
            filtroevaluaciones: '',
            cargo: {},
            capacitacion: {},
            showcreacion: false,
            loadingcargos: true,
            cargos: [],
            areas: [],
            loadingareas: true,
            loadingmatrices: true,
            evaluaciones: [],
            reporte: [],
            toaststyle: { position: toast.POSITION.BOTTOM_RIGHT },
            habilitarnuevaevaluacion: false,
            conseciones: false,
            filtrotipo: '',
            filtrocategoria: '',
            reporteinsignia: [],
            loadingreporteinsignia: true,
            capacitaciones: [],
            selectionRange: {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection'
            },
            usuarioslista: [],
            loadingusuarioslista: false,
            usuariosfiltrados: [],
            loadingusuariosfiltrados: false,
            usuarios_detalles: []
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeCap = this.handleChangeCap.bind(this)
    }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    handleChangeCap(e){
        const { name, value } = e.target
        const { capacitacion } = this.state
        capacitacion[name] = value
        const refrescar = [
            'calificacion',
            'tipo',
            'area'
        ]
        if(refrescar.includes(name) !== false) this.setState({ usuariosfiltrados: [] })
        return this.setState({ capacitacion: capacitacion })
    }

    componentDidMount(){
        const { user } = this.state
        this.getMatrices({ propietario: user.propietario })
        this.getCapacitaciones({ propietario: user.propietario })
        this.getAreas(user.propietario)
    }

    checkIfNuevaEvaluacion(){
        const { user, conseciones } = this.state
        if(!user.area) return false
        if(!conseciones) return false
        if(!conseciones.areas) return false
        const buscararea = conseciones.areas.filter(ar => ar.idarea === user.area)
        if(buscararea.length > 0){
            return this.setState({ habilitarnuevaevaluacion: buscararea[0].activo })
        }
    }

    cambiarRangoFecha(item){
        this.setState({ selectionRange: item.selection, usuariosfiltrados: [] })
    }

    async getConseciones(propietario){
        
        this.setState({ loadingconseciones: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_campanasReclutamiento?propietario=${propietario}`)
        .then(res => res.json())
        .then(async res => {
            this.setState({ conseciones: res, loadingconseciones: false })
            this.checkIfNuevaEvaluacion()
        })
        .catch(error => {
            this.setState({ loadingconseciones: false })
            return toast.error("Error al consultar la información, intente recargar la página", this.state.toaststyle)
        })
  
    }

    getAreas(propietario){

        this.setState({ loadingareas: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getAreas?propietario=${propietario}`)
        .then(res => res.json())
        .then(res => {
            this.setState({ loadingareas: false, areas: res })
        })
        .catch(error => {
            this.setState({ loadingareas: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    getEvaluaciones(condicion){
        const { user } = this.state
        let url = 'https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_VerEvaluaciones'

        if(user.tipo==='admin'){
            url = 'https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getMultiplesEvaluaciones'
        } else if(user.tipo==='evaluator'){
            if(user.area){
                url = 'https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getMultiplesEvaluaciones'
            }
        } 

        this.setState({ loadingevaluaciones: true })
        return fetch(url,{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            
            res.map((info,ival) => {
                res[ival].buscar = JSON.stringify(info.usuarioevaluado)
            })

            this.setState({ loadingevaluaciones: false, evaluaciones: res })
        })
        .catch(error => {
            this.setState({ loadingevaluaciones: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    getCapacitaciones(condicion){
        this.setState({ loadingcapacitaciones: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getCapacitaciones`,{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingcapacitaciones: false, capacitaciones: res })
        })
        .catch(error => {
            this.setState({ loadingcapacitaciones: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    getMatrices(condicion){

        this.setState({ loadingmatrices: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_VerMatricesHabilidades`,{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            this.setState({ loadingmatrices: false, matrices: res })
        })
        .catch(error => {
            this.setState({ loadingmatrices: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    getRoles(tipo){

        this.setState({ loadingcargos: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getRolesUsuario?tipo=${tipo}`)
        .then(res => res.json())
        .then(res => {
            this.setState({ loadingcargos: false, roles: res })
        })
        .catch(error => {
            this.setState({ loadingcargos: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    guardarCrear(){
        const { capacitacion, usuariosfiltrados, user } = this.state
        
        if(!capacitacion.titulo||!capacitacion.descripcion){
            return toast.error("Todos los campos son requeridos", this.state.toaststyle)
        }
        capacitacion.propietario = user.propietario
        capacitacion.status = '1'
        capacitacion.creador = user
        capacitacion.usuarios = usuariosfiltrados
        capacitacion.creado = this.formatDate(new Date())
        
        this.setState({ guardandocambios: true })
        return fetch('https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_CrearCapacitacion',{
            method: 'POST',
            body: JSON.stringify(capacitacion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            const texto = capacitacion._id ? 'Registro modificado exitosamente' : 'Registro creado exitosamente'
            toast.success(texto, this.state.toaststyle)
            this.setState({ guardandocambios: false, capacitacion: {}, showcreacion: false })
            this.getCapacitaciones({ propietario: user.propietario })
        })
        .catch(error => {
            this.setState({ guardandocambios: false, showcreacion: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    fetchUsuarios(condicion){
        this.setState({ loadingUsuarios: true })
        return fetch('https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getUsersCustom',{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            this.setState({ loadingUsuarios: false, usuarios: res })
        })
        .catch(error => {
            this.setState({ loadingUsuarios: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    statusUser(tipo){
        switch (tipo) {
            case '1':
                    return <small className="text-success"><i className="far fa-check-circle"></i> APROBADA</small>
                    break;
            default:
                return <small ><i className="far fa-clock"></i> EN REVISIÓN</small>
                break;
        }
    }

    tipoUser(tipo){
        switch (tipo) {
            case 'admin':
                    return 'Administrador'
                    break;
            case 'evaluator':
                    return 'Evaluador'
                    break;
            case 'empleado':
                    return 'Usuario'
                    break;        
            default:
                return tipo
                break;
        }
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    addMoreDays(date, days){
        var result = new Date(date)
        result.setDate(result.getDate() + days)
        return this.formatDate(result)
    }

    refrescarRegistros(estado, loading){
        const { selectionRange, user, capacitacion } = this.state

        const requeridos = [
            'titulo',
            'descripcion'
        ]
        let errores = []

        requeridos.map(item => {
            if(!capacitacion[item]) errores.push(`${item} es requerido`)
        })

        if(errores.length > 0) return toast.error(errores.join(', '), this.state.toaststyle)

        const desde = this.formatDate(selectionRange.startDate)
        const hasta = this.formatDate(selectionRange.endDate)
        return this.getUsuariosLista(desde, this.addMoreDays(hasta,2), capacitacion.calificacion, capacitacion.tipo, capacitacion.area, user.propietario, estado, loading)
    }

    getUsuariosLista(desde, hasta, clasificacion, tipo, area, propietario, estado, loading){
        this.setState({ [loading]: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_GetListaBetweenDates?desde=${desde}&hasta=${hasta}&propietario=${propietario}&clasificacion=${clasificacion ? clasificacion : ''}&tipo=${tipo ? tipo : ''}&area=${area ? area : ''}`)
        .then(res => res.json())
        .then(res => {

            let usuarios = []
            let usuarios_detalles = []

            res.map(evaluacion => {
                if(evaluacion.usuarioevaluado){
                    if(isObject(evaluacion.usuarioevaluado) !== false){
                        if(evaluacion.usuarioevaluado._id){
                            if(!evaluacion.usuarioevaluado._id['$oid']) return false
                            if(usuarios.includes(evaluacion.usuarioevaluado._id['$oid']) !== true){
                                usuarios.push(evaluacion.usuarioevaluado._id['$oid'])
                                usuarios_detalles.push(evaluacion.usuarioevaluado)
                            }
                        }
                    }
                }
            })

            if(usuarios.length < 1) toast.warn('Sin usuarios encontrados', this.state.toaststyle)
                        
            this.setState({ [loading]: false, [estado]: usuarios, usuarios_detalles })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    formularioNuevoUser(){
        const { showcreacion, cargos, usuariosfiltrados, loadingusuariosfiltrados, capacitacion, areas, guardandocambios, matrices, selectionRange } = this.state

        if(guardandocambios){
            return <div>
            <Spinner animation="border" />
            <h3>Guardando información...</h3>
        </div>
        }
        if(showcreacion){

            return <Card className="card-blue">
                
                <Card.Body className="text-white ">
                <Row>
                <Col md={12} >
                { capacitacion._id ? <h3>Editar capacitación {capacitacion.titulo ? capacitacion.titulo : 'registro'} </h3> : <h3>Crear capacitación</h3> }
                <p>En base a las evaluaciones y resultados de los usuarios en tiempos específicos, puedes filtrar y crear capacitaciones</p>
                </Col>

<Col md={4} >
    <div className="form-group">
    <label className="form-control-label d-block">Título</label>
    <input name="titulo" className="form-control" value={capacitacion.titulo ? capacitacion.titulo : ''} onChange={this.handleChangeCap} />
    </div>
</Col>

<Col md={5} >
<div className="form-group">
    <label className="form-control-label d-block">Descripción</label>
    <input name="descripcion" className="form-control" value={capacitacion.descripcion ? capacitacion.descripcion : ''} onChange={this.handleChangeCap} />
    </div>
</Col>

<Col md={3} >
    <div className="form-group">
    <label className="form-control-label d-block">Calificación</label>
    <select className="form-control" name="calificacion" value={capacitacion.calificacion ? capacitacion.calificacion : ''} onChange={this.handleChangeCap} >
        <option value="">Selecciona</option>
        <option value="A">A</option>
        <option value="B">B</option>
        <option value="C">C</option>
        <option value="D">D</option>
        <option value="E">E</option>
    </select>
    </div>
</Col>

<Col md={3} >
<div className="form-group">
    <label className="form-control-label d-block">Tipo de evaluación</label>
    <select name="tipo" className="form-control" value={capacitacion.tipo ? capacitacion.tipo : ''} onChange={this.handleChangeCap} >
        <option value="">Todos</option>
        <option value="simple">Simple</option>
        <option value="insignia">Insignia</option>
    </select>
</div>
</Col>

<Col md={3} >
<div className="form-group">
    <label className="form-control-label d-block">Area de trabajo</label>
    <select name="area" className="form-control" value={capacitacion.area ? capacitacion.area : ''} onChange={this.handleChangeCap} >
        <option value="">Todas las áreas</option>
        {
            areas.length > 0 ? areas.map(area => {
                return <option value={area._id['$oid']}> {area.nombre} </option>
            }) : false
        }
    </select>
    </div>
</Col>

<Col md={12}>
<div className="form-group">
<label className="form-control-label d-block">Buscar usuarios en base a evaluaciones</label>
    <DateRangePicker
    locale={es}
    ranges={[selectionRange]}
    onChange={item => this.cambiarRangoFecha(item)}
    direction="vertical"
    scroll={{ enabled: true }}
  />

</div>
<div className="form-group">
<button className="btn btn-outline-light d-block mb-3" onClick={()=>this.refrescarRegistros('usuariosfiltrados', 'loadingusuariosfiltrados')}>PREVISUALIZAR FILTRO DE ESTA CAPACITACIÓN</button>
{ loadingusuariosfiltrados ? <div> <Spinner animation="border" /></div> : false }
{this.ifusuariosfiltrados(usuariosfiltrados)}
</div>
</Col>

{
    usuariosfiltrados.length > 0 ? <Col md={3}>
    <label className="form-control-label d-block">Click para { capacitacion._id ? 'editar' : 'crear' } </label>
    <Button style={{ background: 'white' }} onClick={()=>this.guardarCrear()} variant="outline-white">{ capacitacion._id ? 'GUARDAR CAMBIOS' : 'CREAR' }</Button>
    </Col> : false
}


<Col xs={12}>
<p className="m-0 onclick" onClick={()=>this.setState({ capacitacion: {}, usuariosfiltrados: [], usuarios_detalles: [] })}>Reiniciar formulario</p>

</Col>

</Row>
                </Card.Body>
            </Card>
        }


    }


    ifusuariosfiltrados(usuarios){
        const { usuarios_detalles } = this.state
        if(usuarios.length < 1) return false

        return <Accordion>
        <hr />
    <Card>
      <Accordion.Toggle as={Card.Header} className="card text-dark" style={{ borderRadius: 3 }} eventKey="1">
        <h5 className="nomargin">{usuarios.length} USUARIOS ENCONTRADOS <span style={{ color: '#adacac', fontSize: 15 }}>Ver detalles</span> </h5>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="1">
        <Card.Body>

        <Table responsive hover className="mb-0">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Apellido P</th>
                <th>Apellido M</th>
                <th>Rut</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              { usuarios_detalles.map((user,icap)=>{
                 
                  return <tr key={`cap${icap}`}>
                      <th>{user.nombres}</th>
                      <th>{user.apellidopaterno}</th>
                      <th>{user.apellidomaterno }</th>
                      <th>{user.rut}</th>
                      <th>{user.email}</th>
                  </tr>
              }) }
            </tbody>
          </Table>

        </Card.Body>
      </Accordion.Collapse>
    </Card>
  </Accordion>
    }

    mostrarCapacitaciones(){
        const { loadingcapacitaciones, capacitaciones, showcreacion } = this.state

        if(loadingcapacitaciones){
            return <div>
                <Spinner animation="border" />
                <h3>Cargando datos</h3>
            </div>
        }

        if(capacitaciones.length > 0){
            return <Card className="mb-3">
<Card.Body>
<Button style={{ float: 'right', marginBottom: 15 }} onClick={()=> this.setState({ showcreacion: showcreacion ? false : true, textguardado: '' })}  variant="outline-primary">CREAR</Button>
<div style={{ clear: 'both' }}><hr /></div>
<img src="icon6.jpg" className="iconsystem" />

<p>Puedes crear capacitaciones basadas en resultados de las personas evaluadas en períodos de tiempo específicos</p>
<Table responsive hover className="mb-0">
            <thead>
              <tr>
                <th>Área</th>
                <th>Fecha creación</th>
                <th>Usuarios</th>
                <th>Eventos</th>
              </tr>
            </thead>
            <tbody>
              { capacitaciones.map((cap,icap)=>{
                 
                  return <tr key={`cap${icap}`}>
                      <th>{cap.titulo}
                          <Link className="d-block" to={`detalle-capacitacion-${cap._id['$oid']}`}>Detalles</Link>
                      </th>
                      <th>{cap.creado}</th>
                      <th>{cap.usuarios.length}</th>
                      <th>{cap.eventos ? cap.eventos.length : 0 }</th>
                  </tr>
              }) }
            </tbody>
          </Table>
</Card.Body>
            </Card>

        }

        return <Card className="mb-3">
        <Card.Body>
        <Button style={{ float: 'right', marginBottom: 15 }} onClick={()=> this.setState({ showcreacion: showcreacion ? false : true, textguardado: '' })}  variant="outline-primary">CREAR</Button>
          <div style={{ clear: 'both' }}><hr /></div>

            <h5>No hay capacitaciones creadas</h5>

        </Card.Body>
        </Card>

    }
    

    ifAreaExist(idarea){
        const { areas } = this.state

        if(areas.length > 0){
            const buscar = areas.findIndex(ma => ma._id['$oid'] === idarea)
            if(areas[buscar]){
                return areas[buscar].nombre
            }
        }

        return 'No encontrado'
    }

    ifMatrixExist(idmatriz){
        const { matrices } = this.state

        if(matrices.length > 0){
            const buscar = matrices.findIndex(ma => ma._id['$oid'] === idmatriz)
            if(matrices[buscar]){
                return matrices[buscar].nombre
            }
        }

        return 'No encontrado'
    }
    
    render(){

        const {miusuario} = this.props
        const { errorLogin } = this.state
        return(
            <div className="fluid">
       <Header/>   
       <ToastContainer />
<div className="container-fluid">
  <div className="row">
   <Nav/>
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Capacitaciones</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
        </div>
      </div>
      {this.formularioNuevoUser()}
            {this.mostrarCapacitaciones()}

     { /* <Card className="mb-3 cardblue">
<Card.Body>

<Row className="leyenda">
<Col md={12}>
        <h4 className="text-white">Definición de clasificación</h4>
        <hr />
    </Col>

    <Col md={2}>
        <h3 className="etiqueta">A</h3>
        <p>Nota Mayor al 160% del promedio deseado</p>
    </Col>

    <Col md={2}>
        <h3 className="etiqueta">B</h3>
        <p>Nota Mayor al 130% del promedio deseado y menor al 160%</p>
    </Col>

    <Col md={2}>
        <h3 className="etiqueta">C</h3>
        <p>Nota mayor al promedio deseado y menor al 130% del promedio deseado</p>
    </Col>

    <Col md={2}>
        <h3 className="etiqueta">D</h3>
        <p>Nota mayor al 50% del promedio deseado y menor del promedio deseado</p>
    </Col>
    <Col md={2}>
        <h3 className="etiqueta">E</h3>
        <p>Nota menor al 50% del promedio deseado</p>
    </Col>
    <Col md={12}>
        <p style={{fontSize: 14, fontWeight: 'lighter' }}>Otras consideraciones Si el trabajador aprueba sobre el 85% de las habilidades, la clasificación mínima a la que accede es C, por otro lado, si el trabajador aprueba menos del 85%, entonces la clasificación máxima a la que accede es D</p>
    </Col>
</Row>

</Card.Body>
        </Card> */}
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(Capacitaciones);