import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button } from 'react-bootstrap'
import Header from '../Header'
import Nav from '../Nav'
import Spinner from 'react-bootstrap/Spinner'
import Table from 'react-bootstrap/Table'
import { ToastContainer, toast } from 'react-toastify';
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import 'react-toastify/dist/ReactToastify.css'
import Accordion from 'react-bootstrap/Accordion'
import { calcularCategoria, checkForPermissions } from '../../helpers/index'

class DetalleEvaluacion extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            matrices: [],
            loadingUsuarios: true,
            filtroevaluaciones: '',
            loadingEvaluacion: true,
            evaluacion: false,
            showcreacion: false,
            loadingcargos: true,
            cargos: [],
            areas: [],
            loadingareas: true,
            loadingmatrices: true,
            evaluaciones: [],
            guardandocambios: false,
            usuarios: [],
            evaluacionprevia: false,
            idevaluacion: this.props.match.params.id,
            toaststyle: { position: toast.POSITION.BOTTOM_RIGHT },
            loadingevaluaciones: true,
            cargandoinfoinsignia: false,
            informacioninsignia: false
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeRol = this.handleChangeRol.bind(this)
        this.handleChangeUser = this.handleChangeUser.bind(this)
        this.handleChangeCargo = this.handleChangeCargo.bind(this)
    }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    handleChangeUser(e){
        const { evaluacion, usuarios } = this.state
        const listausuarios = []
        e.map(persona => {
            const buscar = usuarios.findIndex(user => user._id['$oid'] === persona.value)
            if(usuarios[buscar]){
                listausuarios.push(usuarios[buscar])
            }
        })
        evaluacion.listausuarios = listausuarios
        return this.setState({ evaluacion: evaluacion })
    }
    
    handleChangeCargo(e){
        const { evaluacion, cargos, matrices, areas } = this.state
        const buscar = cargos.findIndex(puesto => puesto._id['$oid'] === e.value)
        if(cargos[buscar]){
            evaluacion.cargo = cargos[buscar]
            if(cargos[buscar].idmatriz){
                const buscarmatriz = matrices.findIndex(mat => mat._id['$oid'] === cargos[buscar].idmatriz)
                if(matrices[buscarmatriz]){
                    evaluacion.matriz = matrices[buscarmatriz]
                }
            }
            if(cargos[buscar].areaasignada){
                const buscararea = areas.findIndex(area => area._id['$oid'] === cargos[buscar].areaasignada)
                if(areas[buscararea]){
                    evaluacion.cargo.area = areas[buscararea]
                }
            }
        }

        return this.setState({ evaluacion: evaluacion })
    }

    handleChangeRol(e){
        const { name, value } = e.target
        const { cargo } = this.state
        cargo[name] = value
        return this.setState({ cargo: cargo })
    }

    componentDidMount(){
        const { user, idevaluacion } = this.state
        this.getEvaluacionDetalle(idevaluacion)
        
    }


    async actualizarData(id){
        const  { evaluacion, cargos, areas, matrices, user } = this.state
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_refreshUserData?id=${id}`)
        .then(res => res.json())
        .then(async res => {
            if(!res) return window.location.replace('/login')            
            this.setState({ user: res })
        })
        .catch(error => {
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    getEvaluaciones(condicion){

        this.setState({ loadingevaluaciones: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getMultiplesEvaluaciones`,{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingevaluaciones: false, evaluaciones: res })
        })
        .catch(error => {
            this.setState({ loadingevaluaciones: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    mostrarHistorial(){
        const { evaluaciones, loadingevaluaciones, filtroevaluaciones } = this.state

        return false 

        const lowercasedFilter = filtroevaluaciones.toLowerCase();
        const evaluacionesFiltradas = evaluaciones.filter(item => {
            return Object.keys(item).some(key =>
                item[key].toString().toLowerCase().includes(lowercasedFilter)
            );
        });

        if(loadingevaluaciones){
            return <div>
            <Spinner animation="border" />
            <h3>Cargando información...</h3>
        </div>
        }

        return <Accordion>
            <hr />
        <Card>
          <Accordion.Toggle as={Card.Header} className="card-blue text-white" style={{ borderRadius: 3 }} eventKey="1">
            <h5 className="nomargin">VER HISTORIAL DE EVALUACIONES</h5>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
                
          <Card.Title style={{ float: 'left' }}>{evaluaciones.length} evaluaciones encontradas</Card.Title>
          <div style={{ clear: 'both' }}></div>
        {this.formularioNuevoUser()}
          <Row>

          
          </Row>          

          <Table responsive hover>
  <thead>
    <tr>
      <th>Creación</th>
      <th>Estado</th>
      <th>Modalidad</th>
      <th>Llenado</th>
      <th>Correctas</th>
      <th>Promedio</th>
      <th>Categoría</th>
      <th>Usuarios evaluados</th>
      <th>Creador</th>
    </tr>
  </thead>
  <tbody>
    { evaluacionesFiltradas.map((cargo,i)=>{
        
        
        let usuariosevaluados = ''

        if(cargo.modalidad==='autoevaluacion'){
            usuariosevaluados = `${cargo.usuarioevaluado.nombres} - ${cargo.usuarioevaluado.rut}`
        } else if(cargo.modalidad === 'evaluacion') {
            if(cargo.listausuarios){
                if(cargo.listausuarios.length > 0){
                    cargo.listausuarios.map(u => {
                        usuariosevaluados = usuariosevaluados + `${u.nombres}, `
                    })
                }
            }
        }
        return <tr key={`user${i}`}>
            <th>{cargo.creado}<br/></th>
            <th>{this.statusUser(cargo.status)}</th>
            <th>{cargo.modalidad}</th>
            <th>{cargo.porcentajellenado ? `${cargo.porcentajellenado}%` : 'Sin data' }</th>
            <th>{cargo.porcentajecorrectas ? `${cargo.porcentajecorrectas}%` : 'Sin data' }</th>
            <th>{cargo.resultadopromedio ? `${cargo.resultadopromedio}` : 'Sin data' }</th>
            <th>{cargo.categoria ? cargo.categoria : 'Sin data'}</th>
            <th>{usuariosevaluados}</th>
            <th>{cargo.creador ? `${cargo.creador.nombres} ${cargo.creador.apellidopaterno}` : 'No encontrado'}</th>
        </tr>
    }) }
  </tbody>
</Table>

            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    }

    getDetalleInsignia(id){
        this.setState({ cargandoinfoinsignia: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_GetInsigniaById?id=${id}`)
        .then(res => res.json())
        .then(res => {
            return this.setState({ cargandoinfoinsignia: false, informacioninsignia: res })
        })
        .catch(error => {
            this.setState({ cargandoinfoinsignia: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    getEvaluacionDetalle(id){
        const { user } = this.state
        this.setState({ loadingEvaluacion: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_EvaluacionById?id=${id}`)
        .then(res => res.json())
        .then(res => {
            
            if(res.usuarioevaluado){
                if(res.usuarioevaluado._id['$oid']){
                    if(res.tipo ==='insignia'){
                        if(res.idinsignia){
                            this.getDetalleInsignia(res.idinsignia)
                            this.getEvaluaciones({ "usuarioevaluado.id": res.usuarioevaluado._id['$oid'], tipo: res.tipo, idinsignia: res.idinsignia })
                        }
                    } else {
                        this.getEvaluaciones({ "usuarioevaluado.id": res.usuarioevaluado._id['$oid'], tipo: res.tipo })
                    }
                }
            }
            if(user.tipo==='admin'||user.tipo==='evaluator'){
                if(res.borrador){
                    return this.setState({ loadingEvaluacion: false, evaluacion: res.borrador, evaluacionoriginal: res })
                }
            }
            return this.setState({ loadingEvaluacion: false, evaluacion: res, evaluacionoriginal: res })
        })
        .catch(error => {
            this.setState({ loadingEvaluacion: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }


    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    guardarCrear(status){
        const { evaluacion, user, evaluacionoriginal } = this.state

        const autorizar = checkForPermissions( user,'evaluaciones','editar')
        if(!autorizar) return toast.error('No estás autorizado para realizar esta operación', this.state.toaststyle)

        evaluacion.propietario = user.propietario
        evaluacion.creado = this.formatDate(new Date())
        
        let llenado = 0
        let correctas = 0
        let cantidadhabilidades = 0
        let totalparapuntaje = 0
        let promediodeseado = 0
        evaluacion.matriz.esquema.niveles.map(nivel => {
            if(!nivel.niveles) return false
            nivel.niveles.map(level=>{
                if(!level.niveles) return false
                level.niveles.map(le=>{
                    if(!le.habilidades) return false
                    le.habilidades.map(hab=>{
                        cantidadhabilidades++
                        if(hab.logrado) llenado++
                        const esperado = hab.esperado ? parseInt(hab.esperado) : 0
                        const logrado = hab.logrado ? parseInt(hab.logrado) : 0
                        totalparapuntaje = totalparapuntaje + logrado
                        promediodeseado = promediodeseado + esperado
                        if(logrado>=esperado) correctas++
                    })
                })
            })
        })
        const deseado = Math.round(promediodeseado / cantidadhabilidades)
        const puntaje = (totalparapuntaje / cantidadhabilidades).toFixed(2)

        let categoria = 'E'

        if(puntaje > (deseado*0.5).toFixed(2) && puntaje <= deseado ) categoria = 'D'
        if(puntaje > deseado && puntaje < (deseado*1.3).toFixed(2) ) categoria = 'C'
        if(puntaje > (deseado*1.3).toFixed(2) && puntaje < (deseado*1.6).toFixed(2) ) categoria = 'B'
        if(puntaje > (deseado*1.6).toFixed(2) ) categoria = 'A'



        evaluacion.porcentajellenado = Math.round(llenado/cantidadhabilidades*100).toString()
        evaluacion.porcentajecorrectas = Math.round(correctas/cantidadhabilidades*100).toString()
        evaluacion.resultadopromedio = (totalparapuntaje / cantidadhabilidades).toFixed(2).toString()
        evaluacion.promediodeseado = (promediodeseado / cantidadhabilidades).toFixed(2).toString()
        evaluacion.categoria = categoria
        evaluacion.status = status.toString()
        evaluacion.fechaedicion = this.formatDate(new Date())

        let urlenviar = ''
        let bodyenviar = {evaluacion: evaluacion, evaluacionoriginal: evaluacionoriginal}
        if(status==='0'){
            urlenviar = 'https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_EditarEvaluacion'
            bodyenviar = evaluacion
        } else if(status==='1'){
            evaluacion.fechaconfirmacion = this.formatDate(new Date())
            urlenviar = 'https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_ConfirmarEvaluacion'
        } else {
            return false
        }

        //return console.log(bodyenviar)
        this.setState({ guardandocambios: true })
        return fetch(urlenviar,{
            method: 'POST',
            body: JSON.stringify(bodyenviar),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            const texto = evaluacion._id ? 'Evaluación modificada exitosamente' : 'Evaluación ingresada exitosamente'
            toast.success(texto, this.state.toaststyle)
            this.getEvaluacionDetalle(this.state.idevaluacion)
            return this.setState({ guardandocambios: false })
        })
        .catch(error => {
            this.setState({ guardandocambios: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    fetchUsuarios(condicion){
        this.setState({ loadingUsuarios: true })
        return fetch('https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getUsersCustom',{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            this.setState({ loadingUsuarios: false, usuarios: res })
        })
        .catch(error => {
            this.setState({ loadingUsuarios: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    statusUser(tipo){
        switch (tipo) {
            case '1':
                    return 'Confirmada'
                    break;
            default:
                return 'No confirmada'
                break;
        }
    }

    tipoUser(tipo){
        switch (tipo) {
            case 'admin':
                    return 'Administrador'
                    break;
            case 'evaluator':
                    return 'Evaluador'
                    break;
            case 'empleado':
                    return 'Usuario'
                    break;        
            default:
                return tipo
                break;
        }
    }

    formularioNuevoUser(){
        const { showcreacion, cargos, cargo, areas, guardandocambios, matrices } = this.state

        if(guardandocambios){
            return <div>
            <Spinner animation="border" />
            <h3>Guardando información...</h3>
        </div>
        }
        if(showcreacion){

            return <Card className="card-blue">
                
                <Card.Body className="text-white ">
                <Row>
                <Col md={12} >
                { cargo._id ? <h3>Editar evaluación {cargo.nombre ? cargo.nombre : 'usuario'} </h3> : <h3>Crear evaluación</h3> }
                </Col>

<Col md={3} >
    <label className="form-control-label d-block">Nombre</label>
    <input name="nombre" className="form-control" value={cargo.nombre ? cargo.nombre : ''} onChange={this.handleChangeRol} />
</Col>

<Col md={6} >
    <label className="form-control-label d-block">Descripción</label>
    <input name="descripcion" className="form-control" value={cargo.descripcion} onChange={this.handleChangeRol} />
</Col>

<Col md={3} >
    <label className="form-control-label d-block">Cargo</label>
    <select name="areaasignada" className="form-control" value={cargo.areaasignada ? cargo.areaasignada : ''} onChange={this.handleChangeRol} >
        <option value="">Seleccione</option>
        {
            cargos.length > 0 ? cargos.map((puesto,pu) => {
                return <option key={`puesto-${pu}`} value={puesto._id['$oid']}> {puesto.nombre} </option>
            }) : false
        }
    </select>
</Col>

<Col md={3}>
<label className="form-control-label d-block">Click para { cargo._id ? 'editar' : 'crear' } </label>
    <Button style={{ background: 'white' }} onClick={()=>this.guardarCrear()} variant="outline-white">{ cargo._id ? 'GUARDAR CAMBIOS' : 'CREAR' }</Button>
</Col>

<Col xs={12}>
<p className="m-0 onclick" onClick={()=>this.setState({ cargo: {} })}>Reiniciar formulario</p>

</Col>

</Row>
                </Card.Body>
            </Card>
        }


    }

    mostrarEvaluaciones(){
        const { loadingEvaluacion, evaluacion } = this.state

        if(loadingEvaluacion){
            return <div>
                <Spinner animation="border" />
                <h3>Cargando información</h3>
            </div>
        }

        let options = []
        let optionscargos = []

        return this.mostrarEvaluacionByTipo(evaluacion, optionscargos, options)
    }

    mostrarPuntajes(evaluacion){

        const { evaluacionprevia } = this.state
        if(!evaluacion.matriz) return <div>
        <Spinner animation="border" />
        <h3>Espera un momento...</h3>
    </div>
        let llenado = 0
        let correctas = 0
        let cantidadhabilidades = 0
        let totalparapuntaje = 0
        let promediodeseado = 0
        let categoria = 'E'

        if(evaluacion.matriz){
            evaluacion.matriz.esquema.niveles.map(nivel => {
                if(!nivel.niveles) return false
                nivel.niveles.map(level=>{
                    if(!level.niveles) return false
                    level.niveles.map(le=>{
                        if(!le.habilidades) return false
                        le.habilidades.map(hab=>{
                            if(!hab) return false
                            cantidadhabilidades++
                            if(hab.logrado) llenado++
                            const esperado = hab.esperado ? parseInt(this.capturarNumeroSiEsObjetoMongo(hab.esperado)) : 0
                            const logrado = hab.logrado ? parseInt(this.capturarNumeroSiEsObjetoMongo(hab.logrado)) : 0
                            totalparapuntaje = totalparapuntaje + logrado
                            promediodeseado = promediodeseado + esperado
                            if(logrado>=esperado) correctas++
                        })
                    })
                })
            })
            const deseado = Math.round(promediodeseado / cantidadhabilidades)
            const puntaje = (totalparapuntaje / cantidadhabilidades).toFixed(2)
    
            categoria = calcularCategoria(puntaje, deseado)
    
        }
        return <Row>

        <Col xs={3}>
            <h6>Porcentaje llenado</h6>
            <h5>{Math.round(llenado/cantidadhabilidades*100)}%</h5>
        </Col>

        <Col xs={3}>
            <h6>Porcentaje correctas</h6>
            <h5>{Math.round(correctas/cantidadhabilidades*100)}%</h5>
        </Col>

        <Col xs={2}>
            <h6>Promedio</h6>
            <h5>{ (totalparapuntaje / cantidadhabilidades).toFixed(2) }</h5>
        </Col>

        <Col xs={3}>
            <h6>Fecha</h6>
            <h5>{ evaluacion.creado }</h5>
        </Col>

        <Col xs={3}>
            <h6>Categoría</h6>
            <h5>{ categoria }</h5>
        </Col>

        {
            evaluacionprevia ? <Col xs={3}>
            
<h6>Evaluación anterior</h6>
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> {evaluacionprevia.creado ? `Presentada el ${evaluacionprevia.creado}` : false } </Tooltip>}>
  <span className="d-inline-block">
    <Button style={{ padding: 0 }} variant="link" disabled >
    <h5 className="m-0" >{ evaluacionprevia.resultadopromedio }</h5>
    </Button>
  </span>
</OverlayTrigger>
        </Col> : false
        }
        
        </Row>
    }


    mostrarEvaluacionByTipo(evaluacion, optionscargos, options){
        const { user } = this.state
        switch (evaluacion.modalidad) {
            case 'evaluacion':
                
                return <Card style={{ width: '100%' }}>
            <Card.Body>
              <Card.Title style={{ float: 'left' }}></Card.Title>
              <div style={{ clear: 'both' }}><hr /></div>
              <Row>

              {
                user.tipo === 'admin' || user.tipo === 'evaluator' ? <Col xs={12}>
    
                <Row>
             
              {
                  evaluacion.usuario ? <Col xs={6} >
                      
                      <Card>
                          <Card.Body>
                          <p className="nomargin">Usuario designado para evaluar</p>
                          <hr />
                          <h4 className="nomargin">{evaluacion.usuario.nombres} <small>{evaluacion.usuario.rut} </small> </h4>
                          <p className="nomargin">{evaluacion.usuario.apellidopaterno} {evaluacion.usuario.apellidomaterno} </p>
                          <p className="nomargin">{evaluacion.usuario.email}</p>
                          </Card.Body>
                      </Card>
    
                  </Col> : false
              }  
    
            
                </Row>
    
                </Col> : false
            }
             
            <Col xs={12} className="">
                <h4 className="text-primary">Detalles de habilidades</h4>
              </Col>
                {  
                  evaluacion.matriz ? this.showMatriz2(evaluacion.matriz) : <Col xs={12}><p>Selecciona un cargo para visualizar el esquema de tu evaluación</p></Col>
                }  
              <Col xs={12}>
    
              </Col>
    
              </Row>          
    
    
            </Card.Body>
          </Card>
        
            default:
                
            return <Card style={{ width: '100%' }}>
            <Card.Body>
              <Card.Title style={{ float: 'left' }}>{
                    evaluacion.status==='1' ? <h6 className="text-success"><i className="far fa-check-circle"></i> Esta evaluación ha sido revisada y aprobada por un evaluador</h6> : <h6 className="text-dark"><i className="far fa-clock"></i> Evaluación pendiente de revisión</h6>
                }</Card.Title>
              <div style={{ clear: 'both' }}><hr /></div>
              <Row>
             
             <Col xs={12}>
                 <h3>{evaluacion.titulo}</h3>
                 { evaluacion.planificacion ? <p>{evaluacion.planificacion.descripcion}</p> : false }

<Row>
{
                  evaluacion.usuarioevaluado ? <Col xs={6} >
                      
                      <Card>
                          <Card.Body>
                          <p className="nomargin">Información del usuario evaluado</p>
                          <hr />
                          <h4 className="nomargin">{evaluacion.usuarioevaluado.nombres} - {evaluacion.usuarioevaluado.rut}</h4>
                          <p className="nomargin">{evaluacion.usuarioevaluado.apellidopaterno} {evaluacion.usuarioevaluado.apellidomaterno} </p>
                          <p className="nomargin"> {evaluacion.usuarioevaluado.email} </p>
                          </Card.Body>
                      </Card>
    
                  </Col> : false
              }  

              {this.showifType(evaluacion)} 

<Col xs={12}>

{this.mostrarHistorial()}
    
<hr/>
{this.mostrarPuntajes(evaluacion)}
</Col>

{  
                  evaluacion.matriz ? this.showMatriz(evaluacion.matriz) : <Col xs={12}><p>No tienes ninguna matriz asignada a tu cargo para crear una evaluación</p></Col>
                }  
    </Row>
    </Col>
    
              </Row>          
    
    
            </Card.Body>
          </Card>
        }
    }

    showifType(evaluacion){
        const { cargandoinfoinsignia, informacioninsignia } = this.state
        if(evaluacion.tipo==='evaluacion') return <Col xs={6} >

            {
                  evaluacion.cargo ? <div>
                      <Card>
                          <Card.Body>
                          <p className="nomargin">Información del cargo a postular</p>
                          <hr />
                          <h4 className="nomargin">{evaluacion.cargo.nombre}</h4>
                          <p className="nomargin">{evaluacion.cargo.descripcion} </p>
                          <p className="nomargin">AREA DE TRABAJO: {evaluacion.cargo.area ? evaluacion.cargo.area.nombre : 'Área no encontrada'} </p>
                          </Card.Body>
                      </Card>
    
                    </div> : false
            } 

        </Col>

if(evaluacion.tipo==='insignia'){
    if(cargandoinfoinsignia) return <Col xs={6}>
    <Card><Card.Body>
    <Spinner animation="border" />
    <h3>Cargando información de insignia...</h3>
    </Card.Body></Card>
    </Col>

    return <Col xs={6} >

{
      informacioninsignia ? <div>
          <Card>
              <Card.Body>
              <p className="nomargin">Información de la insignia</p>
              <hr />
              <div className="row">
                  <div className="col-md-2">
                  <img src="insignia.jpg" className="iconsystem" />
                  </div>
                  <div className="col-md-10">
                  <h4 className="nomargin">{informacioninsignia.nombre}</h4>
                  <p className="nomargin">{informacioninsignia.descripcion} </p>
                  </div>
              </div>
              </Card.Body>
          </Card>

        </div> : <h3>No hay información de esta insignia</h3>
} 

</Col>
}
    }

    showMatriz2(matriz){
        const { evaluacion } = this.state
        if(!matriz.esquema) return <h4>No hay esquema asignado en esta matriz</h4>
        if(!matriz.esquema.niveles||matriz.esquema.niveles.length < 1) return <h4>No hay niveles creados en esta matriz</h4>

        return matriz.esquema.niveles.map((nivel,ii) => {
            return <Col xs={12} key={`col-${ii}`}>
                <Card>
                      <Card.Body>
                      <Table responsive hover bordered>
                        <thead>
                        <tr>
                        <th>Habilidad</th>
                        { evaluacion.listausuarios.map((user,i)=>{
                        return <th key={`user${i}`}>{user.nombres}</th>
                        }) }
                        </tr>
                        </thead>
                        <tbody>
            { /* <h2><i className="far fa-bookmark"></i> {nivel.nombre}</h2>*/}
            {
                nivel.niveles ? nivel.niveles.map((lev,levi) => {
                    if(lev.niveles.length < 1) return false
                    return lev.niveles ? lev.niveles.map((level,leveli) => {
                                return level.habilidades ? level.habilidades.map((hab,ihab) => {
                                    if(!hab) return false
                                                const popover = (
                                                    <Popover id="popover-basic" >
                                                      <Popover.Title as="h3">Detalles</Popover.Title>
                                                      <Table responsive hover bordered style={{ margin: 0 }}>
                                                            <tbody>
                                                                    <tr><th>Desc: {hab.descripcion}</th></tr>
                                                                    <tr><th>Nota 1: {hab.nota1}</th></tr>
                                                                    <tr><th>Nota 2: {hab.nota2}</th></tr>
                                                                    <tr><th>Nota 3: {hab.nota3}</th></tr>
                                                                    <tr><th>Nota 4: {hab.nota4}</th></tr>
                                                                    <tr><th>Nota 5: {hab.nota5}</th></tr>
                                                            </tbody>
                                                        </Table>
                                                    </Popover>
                                                  );


                                                return <tr>
                                                    <th><p className="nomargin">{hab.nombre}</p>
                                                    <OverlayTrigger trigger="click" placement="right" overlay={popover} 
                                                    rootClose={true}>
                                                        <Button variant="link" style={{ padding:0 }}>Más info</Button>
                                                    </OverlayTrigger>
                                                    <small className="d-block">Nivel esperado</small>
                                                    <p className="esperado" >{this.capturarNumeroSiEsObjetoMongo(hab.esperado)} </p>
                                                    </th>
                                                    { evaluacion.listausuarios.map((user,i)=>{
                                                        return <th key={`user${i}`}><input max="5" type="number" min="1" className="form-control" /></th>
                                                    }) }
                                                </tr>
                                              }) : <h5>Sin habilidades</h5>
                                    
                            }) : false
                        }) : false
            }
            </tbody>
            </Table>
            </Card.Body>
            </Card>
            </Col>
        })
    }

    capturarNumeroSiEsObjetoMongo(numero){
        if(typeof numero === 'object'){
            if(numero['$numberInt']) return numero['$numberInt']
        }
        return numero
    }

    showMatriz(matriz){
        const { evaluacion, guardandocambios } = this.state
        if(!matriz.esquema) return <h4>No hay esquema asignado en esta matriz</h4>
        if(!matriz.esquema.niveles||matriz.esquema.niveles.length < 1) return <h4>No hay niveles creados en esta matriz</h4>

        
            return <Col xs={12}>
                      
                      <Table responsive hover bordered>
                        <thead>
                        <tr>
                        <th>Habilidad</th>
                        <th style={{ width: 105, textAlign:'center' }}>Nivel deseado</th>
                        <th className="numeros">1</th>
                        <th className="numeros">2</th>
                        <th className="numeros">3</th>
                        <th className="numeros">4</th>
                        <th className="numeros">5</th>
                        </tr>
                        </thead>
                        <tbody>
            { matriz.esquema.niveles.map((nivel,inivel) => {
                return nivel.niveles ? nivel.niveles.map((lev,levi) => {
                    if(lev.niveles.length < 1) return false
                    return lev.niveles ? lev.niveles.map((level,leveli) => {
                                return level.habilidades ? level.habilidades.map((hab,ihab) => {
                                    if(!hab) return false
                                                const popover = (
                                                    <Popover id="popover-basic" >
                                                      <Popover.Title as="h3">Detalles</Popover.Title>
                                                      <Table responsive hover bordered style={{ margin: 0 }}>
                                                            <tbody>
                                                                    <tr><th>Desc: {hab.descripcion}</th></tr>
                                                                    <th>Nivel deseado</th>
                                                                    <tr><th>Nota 1: {hab.nota1}</th></tr>
                                                                    <tr><th>Nota 2: {hab.nota2}</th></tr>
                                                                    <tr><th>Nota 3: {hab.nota3}</th></tr>
                                                                    <tr><th>Nota 4: {hab.nota4}</th></tr>
                                                                    <tr><th>Nota 5: {hab.nota5}</th></tr>
                                                            </tbody>
                                                        </Table>
                                                    </Popover>
                                                  );

                                                  const puntaje_esperado = parseInt(this.capturarNumeroSiEsObjetoMongo(hab.esperado))
                                                  const puntaje_logrado = parseInt(this.capturarNumeroSiEsObjetoMongo(hab.logrado))
                                                // circleoptiondanger circleoptionoff circleoptiongreen
                                                return <tr>
                                                    <th><p className="nomargin">{hab.nombre}</p>
                                                    <OverlayTrigger trigger="click" placement="right" overlay={popover} 
                                                    rootClose={true}>
                                                        <Button variant="link" style={{ padding:0 }}>Más info {puntaje_logrado}</Button>
                                                    </OverlayTrigger>
                                                    </th>
                                                    <th style={{ textAlign:'center' }}><p className="esperado" >{this.capturarNumeroSiEsObjetoMongo(hab.esperado)} </p> </th>
                                                    <th><p className={`circleoption ${this.colorSegunPuntaje(puntaje_logrado, puntaje_esperado, 1)}`} onClick={()=>this.seleccionarHabilidad('1', inivel, levi, leveli, ihab )}>1</p></th>
                                                    <th><p className={`circleoption ${this.colorSegunPuntaje(puntaje_logrado, puntaje_esperado, 2)}`} onClick={()=>this.seleccionarHabilidad('2', inivel, levi, leveli, ihab )}>2</p></th>
                                                    <th><p className={`circleoption ${this.colorSegunPuntaje(puntaje_logrado, puntaje_esperado, 3)}`} onClick={()=>this.seleccionarHabilidad('3', inivel, levi, leveli, ihab )}>3</p></th>
                                                    <th><p className={`circleoption ${this.colorSegunPuntaje(puntaje_logrado, puntaje_esperado, 4)}`} onClick={()=>this.seleccionarHabilidad('4', inivel, levi, leveli, ihab )}>4</p></th>
                                                    <th><p className={`circleoption ${this.colorSegunPuntaje(puntaje_logrado, puntaje_esperado, 5)}`} onClick={()=>this.seleccionarHabilidad('5', inivel, levi, leveli, ihab )}>5</p></th>
                                                </tr>
                                              }) : <h5>Sin habilidades</h5>
                                    
                            }) : false
                        }) : false
                    })
                }
            </tbody>
            </Table>

            {this.mostrarAccionesSoloParaEvaluador(evaluacion)}


            </Col>
        
    }

    colorSegunPuntaje(logrado, esperado, pos){
        if(pos === logrado){
            if(logrado >= esperado){
                return 'circleoptiongreen'
            } else {
                return 'circleoptiondanger'
            }
        }
        return 'circleoptionoff'
    }

    mostrarAccionesSoloParaEvaluador(evaluacion){
        const { guardandocambios, user, evaluacionoriginal } = this.state

        if(user.tipo === 'admin' || user.tipo === 'evaluator'){
            if(evaluacion.status==='1'){
                return <div><h6>Esta evaluación ya no se puede editar</h6></div>
            }
            return <div>

                {
                    evaluacion.status==='0' && evaluacionoriginal ? <button className="btn link" onClick={()=>{
                        
                        const autorizar = checkForPermissions( user,'evaluaciones','editar')
                        if(!autorizar) return toast.error('No estás autorizado para realizar esta operación', this.state.toaststyle)
                        this.setState({ evaluacion: evaluacionoriginal})

                    }} >REINICIAR A EVALUACIÓN ORIGINAL</button> : false
                }

                {
                    guardandocambios ? <div>
                    <Spinner animation="border" />
                    <h3>Espera un momento...</h3>
                </div> : <div>
                <button className="btn btn-outline-primary mr-3" onClick={()=>this.guardarCrear('0')} >GUARDAR CAMBIOS</button>
                <button className="btn btn-success" onClick={()=>this.guardarCrear('1')} >GUARDAR Y CONFIRMAR EVALUACIÓN</button>
                {this.accionesEvaluacionInsignia(evaluacion.tipo)}
                </div>
                }
            </div>
        }
        
        return false
    }

    accionesEvaluacionInsignia(tipo){
        const { aprobandoinsignia } = this.state
        if(tipo === 'insignia') return <div>
        
        { aprobandoinsignia ? <div>
            <Spinner animation="border" />
            <h3>Cargando...</h3>
        </div> : <button className="btn btn-outline-warning mr-3" onClick={()=>this.aprobarInsignia()} >APROBAR INSIGNIA</button> 
        }

        </div>
    }

    aprobarInsignia(){
        const { evaluacion, informacioninsignia, user } = this.state
        
        const autorizar = checkForPermissions( user,'evaluaciones','crear')
        if(!autorizar) return toast.error('No estás autorizado para realizar esta operación', this.state.toaststyle)

        const enviar = {
            idinsignia: evaluacion.idinsignia,
            informacioninsignia: informacioninsignia,
            fecha_creacion: this.formatDate(new Date()),
            idusuario: evaluacion.usuarioevaluado.id,
            evaluacion: evaluacion._id['$oid'],
            evaluador: user
        }

        this.setState({ aprobandoinsignia: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_AprobarInsignia`,{
            method: 'POST',
            body: JSON.stringify(enviar),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            this.setState({ aprobandoinsignia: false })
            return toast.success("Insignia aprobada exitosamente", this.state.toaststyle)

        })
        .catch(error => {
            this.setState({ aprobandoinsignia: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    seleccionarHabilidad(valor, inivel, levi, leveli, ihab){

        const { evaluacion } = this.state

        if(evaluacion.status==='1') return toast.warn('Esta evaluación ya fue aprobada y no se puede editar', this.state.toaststyle)

        if(evaluacion.matriz.esquema.niveles[inivel]){

            if(evaluacion.matriz.esquema.niveles[inivel].niveles[levi]){

                if(evaluacion.matriz.esquema.niveles[inivel].niveles[levi].niveles[leveli]){

                    if(evaluacion.matriz.esquema.niveles[inivel].niveles[levi].niveles[leveli].habilidades[ihab]){
                        evaluacion.matriz.esquema.niveles[inivel].niveles[levi].niveles[leveli].habilidades[ihab].logrado = valor.toString()

                        return this.setState({ evaluacion: evaluacion })
                    }

                }
            }

        }
    }

    ifAreaExist(idarea){
        const { areas } = this.state

        if(areas.length > 0){
            const buscar = areas.findIndex(ma => ma._id['$oid'] === idarea)
            if(areas[buscar]){
                return areas[buscar].nombre
            }
        }

        return 'No encontrado'
    }

    ifMatrixExist(idmatriz){
        const { matrices } = this.state

        if(matrices.length > 0){
            const buscar = matrices.findIndex(ma => ma._id['$oid'] === idmatriz)
            if(matrices[buscar]){
                return matrices[buscar].nombre
            }
        }

        return 'No encontrado'
    }
    
    render(){

        const {miusuario} = this.props
        const { errorLogin } = this.state
        return(
            <div className="fluid">
       <Header/>   
       <ToastContainer />
<div className="container-fluid">
  <div className="row">
   <Nav/>
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Detalles de Evaluación </h1>
      </div>
      {this.mostrarEvaluaciones()}
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(DetalleEvaluacion);