import React, { Component} from 'react'
import { connect } from 'react-redux'
import { userService } from '../../services/user.service'
import Header from '../Header'
import Nav from '../Nav'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { Row, Col, Card, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Spinner from 'react-bootstrap/Spinner'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { es } from 'date-fns/locale'
import Table from 'react-bootstrap/Table'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Select from 'react-select'

class ReclutamientoInterno extends Component {
    constructor(){
        super()
        this.state = {
            username: '',
            password: '',
            submitted: false,
            loading: false,
            errorLogin: '',
            user: JSON.parse(localStorage.getItem('user')),
            loadinguser: true,
            loadingconseciones: true,
            conseciones: false,
            loadingareas: true,
            area:'',
            areas: [],
            selectionRange: {
              startDate: new Date(),
              endDate: new Date(),
              key: 'selection'
            },
            nuevo: false,
            toaststyle: { position: toast.POSITION.BOTTOM_RIGHT },
            registros: [],
            opcionesfecha : { weekday: 'long', year: 'numeric', month:'long', day: 'numeric' },
            registro: {},
            saving: false,
            reclutamientos: [],
            deleting: false,
            loadingUsuarios: true,
            usuarios: []
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeRegistro = this.handleChangeRegistro.bind(this)
        this.handleChangeEmails = this.handleChangeEmails.bind(this)
    }

    handleChangeEmails(e){
      const { registro } = this.state

      let array = []
      if(e.length > 0){
        e.map(user => {
          array.push(user.value)
        })
      }

      console.log(array)

      registro.emails = array
      return this.setState({ registro })
    }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    handleChangeRegistro(e){
      const { name, value } = e.target
      const { registro } = this.state
      registro[name] = value
      return this.setState({ registro })
    }

    cambiarRangoFecha(item){
      this.setState({ selectionRange: item.selection })
    }

    componentDidMount(){
      const { user } = this.state
      this.actualizarData(user._id['$oid'])
      this.getConseciones(user.propietario)
      this.getAreas(user.propietario)
      this.getReclutamiento(user.propietario)
      this.getUsuariosByTipo(user)
    }

    getUsuariosByTipo(user){

      switch (user.tipo) {
          case 'admin':
              return this.fetchUsuarios({ propietario: user.propietario })
              break;
          case 'evaluator':
              this.getRoles(user.tipo)
              return this.fetchUsuarios({ propietario: user.propietario, tipo: 'empleado' })
              break;
          default:
              this.setState({ loadingUsuarios: false })
              break;
      }
  }

  fetchUsuarios(condicion){
    this.setState({ loadingUsuarios: true })
    return fetch('https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getUsersCustom',{
        method: 'POST',
        body: JSON.stringify(condicion),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(res => res.json())
    .then(res => {
        console.log(res)
        this.setState({ loadingUsuarios: false, usuarios: res })
    })
    .catch(error => {
        this.setState({ loadingUsuarios: false })
        alert("Error al consultar la información, intente nuevamente")
    })
}

    removerReclutamiento(id){
      const { user } = this.state
      this.setState({ deleting: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/deleteReclutamiento?id=${id}`)
        .then(res => res.json())
        .then(res => {
            this.setState({ deleting: false })
            this.getReclutamiento(user.propietario)
            return toast.success('Borrado exitosamente', this.state.toaststyle)
        })
        .catch(error => {
            this.setState({ deleting: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    getReclutamiento(propietario){
      this.setState({ loadingReclutamientos: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/getReclutamientos?propietario=${propietario}`)
        .then(res => res.json())
        .then(res => {
            const datos = JSON.parse(res)
            console.log(datos)
            this.setState({ loadingReclutamientos: false, reclutamientos: datos })
        })
        .catch(error => {
            this.setState({ loadingReclutamientos: false })
            alert("Error al consultar la información, intente nuevamente")
        })
    }

    getAreas(propietario){

        this.setState({ loadingareas: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getAreas?propietario=${propietario}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingareas: false, areas: res })
        })
        .catch(error => {
            this.setState({ loadingmatrices: false })
            alert("Error al consultar la información, intente nuevamente")
        })

    }

    async getConseciones(propietario){
        this.setState({ loadingconseciones: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_campanasReclutamiento?propietario=${propietario}`)
        .then(res => res.json())
        .then(async res => {
            this.setState({ conseciones: res, loadingconseciones: false })
        })
        .catch(error => {
            this.setState({ loadingconseciones: false })
            return toast.error("Error al consultar la información, intente recargar la página", this.state.toaststyle)
        })
  
    }

    async actualizarData(id){
      this.setState({ loadinguser: true })
      return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_refreshUserData?id=${id}`)
      .then(res => res.json())
      .then(async res => {
          if(!res) return window.location.replace('/login')
          console.log(res)
          
          localStorage.setItem( "user", JSON.stringify(res) )
          this.setState({ user: res, loadinguser: false })
      })
      .catch(error => {
          this.setState({ loadinguser: false })
          return toast.error("Error al consultar la información, intente recargar la página", this.state.toaststyle)
      })

  }

    handleSubmit() {

        this.setState({ submitted: true });
        const { username, password } = this.state;

        if (!(username && password )) {
            return;
        }

        this.setState({ loading: true });
        userService.login(username, password )
            .then(
                user => {
                    if(!user){
                        this.setState({ errorLogin: "Credenciales incorrectas", loading: false })
                    } else {
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                    }
                    
                },
                error => this.setState({ error, loading: false })
            );
    }

    misDatos(){
      const {user } = this.state

      return <Row className="bienvenida">

        <Col md={3}>
        <Card className="text-white cardblue equalheight">
  <Card.Body>
    <img src="user-icon.png" style={{ width: 70 }} />
    <p className="nomargin" style={{ fontWeight: 'lighter' }} >Nivel de usuario</p>
    <Card.Title>{user.matriz ? `${user.matriz.nombre}` : false } </Card.Title>
    
  </Card.Body>
</Card>
        </Col>

        <Col md={3}>
        <Card className="equalheight">
  <Card.Body>
    <img src="cargo.png" style={{ width: 70 }} />
    <p className="nomargin" style={{ fontWeight: 'lighter' }} >Cargo de trabajo</p>
    {
      user.detallescargo ? <div>
        <Card.Title className="nomargin">{user.detallescargo.nombre ? user.detallescargo.nombre : 'Desconocido' } </Card.Title>
        <p className="nomargin" style={{ fontWeight: 'lighter' }} >{user.detallescargo.descripcion}</p>
      </div> : <p>No tienes un cargo asignado dentro de la empresa</p>
    }
    
  </Card.Body>
</Card>
        </Col>

        <Col md={3}>
        <Card className="cardorange equalheight">
  <Card.Body>
    <img src="area.png" style={{ width: 70 }} />
    <p className="nomargin" style={{ fontWeight: 'lighter' }} >Evaluaciones</p>
    <Card.Title>{user.evaluaciones ? user.evaluaciones.length : false } </Card.Title>
    <Link to="/evaluaciones" ><button className="btn btn-sm btn-outline-light" >DETALLES</button></Link>
    
  </Card.Body>
</Card>
        </Col>

        <Col md={3}>
        <Card className="equalheight">
  <Card.Body>
    <img src="areatrabajo.png" style={{ width: 70 }} />
    <p className="nomargin" style={{ fontWeight: 'lighter' }} >Área de trabajo</p>
    {
      user.detallesarea ? <div>
        <Card.Title className="nomargin">{user.detallesarea.nombre ? user.detallesarea.nombre : 'Desconocido' } </Card.Title>
        <p className="nomargin" style={{ fontWeight: 'lighter' }} >{user.detallesarea.descripcion}</p>
      </div> : <p>No tienes un cargo asignado dentro de la empresa</p>
    }
    
  </Card.Body>
</Card>
        </Col>

        <Col xs={12} className="mt-3"> <h5>Mis datos</h5>  </Col>

        <Col md={3}>
        <Card >
          <Card.Body>
            <p className="text-primary nomargin" >Estado del usuario</p>
            <p className="nomargin" style={{ fontSize: 12 }} >{this.ifactive(user.status) }</p>
          </Card.Body>
        </Card>
        </Col>

        <Col md={3}>
        <Card >
          <Card.Body>
            <p className="text-primary nomargin" >Fecha nacimiento</p>
            <p className="nomargin" style={{ fontSize: 12 }} >{user.fechanacimiento }</p>
          </Card.Body>
        </Card>
        </Col>

        <Col md={3}>
        <Card >
          <Card.Body>
            <p className="text-primary nomargin" >Rut</p>
            <p className="nomargin" style={{ fontSize: 12 }} >{user.rut }</p>
          </Card.Body>
        </Card>
        </Col>

        <Col md={3}>
        <Card >
          <Card.Body>
            <p className="text-primary nomargin" >Email</p>
            <p className="nomargin" style={{ fontSize: 12 }} >{user.email }</p>
          </Card.Body>
        </Card>
        </Col>

      </Row>

    }

    ifactive(status){
      switch(status){
        case '1':
          return <p className="nomargin" style={{ fontSize: 12 }} ><i style={{ color: '#00b23d'}} className="fas fa-check-circle"></i> ACTIVO</p>
        default:
            return <p className="nomargin" style={{ fontSize: 12 }} ><i className="far fa-clock"></i> INACTIVO</p>
      }
    }

    conseciones(){
        const { loadingconseciones, saving, conseciones, areas } = this.state

        if(loadingconseciones){
            return <div>
            <Spinner animation="border" />
            <h3>Cargando información...</h3>
        </div>
        }

        if(!conseciones) return <div>
                
            <h3>No hay datos guardados</h3>
            <button className="btn btn-outline-primary" onClick={() => this.setState({ conseciones: { }}) } >CONFIGURAR ACCESOS A EVALUACIONES</button>

    </div>

        return <Row>

<Col md={3} >
    <label className="form-control-label d-block">Area de trabajo</label>
    <select name="area" className="form-control" onChange={this.handleChange} >
        <option value="">Seleccione</option>
        {
            areas.length > 0 ? areas.map(area => {
                return <option value={area._id['$oid']}> {area.nombre} </option>
            }) : false
        }
    </select>
</Col>

<Col xs={3}>
<label className="form-control-label d-block">Click para agregar</label>
<button className="btn btn-outline-primary" onClick={() => this.agregarArea()} >SELECCIONAR</button>
</Col>

<Col xs={12} className="mt-3">
{
    conseciones.areas ? <div>
        <h3>Áreas gestionadas</h3>
        <p>En esta opción podrás activar y desactivar las evaluaciones de cada área de trabajo</p>
        {
            conseciones.areas.map((area,i) => {
                const detallearea = areas.find(ar => ar._id['$oid'] === area.idarea)
                if(detallearea) return <Row>
                    <Col xs={3}><h5>{detallearea.nombre}</h5></Col>
                    <Col xs={3}>{area.activo === true ? <p style={{ color: '#28a745' }}>ACTIVO</p> : <p style={{ color: 'red' }}>INACTIVO</p> }</Col>
                    <Col xs={3}>{ area.activo === true ? <button className="btn btn-outline-danger" onClick={() => this.activarDesactivar(i)} >DESACTIVAR</button> : <button className="btn btn-outline-success" onClick={() => this.activarDesactivar(i)} >ACTIVAR</button> } </Col>
                    <Col xs={12} > <hr /> </Col>
                </Row>
            })
        }


        {
            saving ? <div>
            <Spinner animation="border" />
            <h3>Guardando información...</h3>
        </div> : <button className="btn btn-success" onClick={()=>this.guardarConfiguracion()}>GUARDAR CONFIGURACIÓN</button>
        }
    </div> : <h5>Aún no gestionas el acceso a evaluaciones de ningun área de trabajo</h5>
}
</Col>
        </Row>

    }

    guardarReclutamiento(){
      const { registro, selectionRange, user } = this.state

      registro.startDate = new Date(selectionRange.startDate)
      registro.endDate = new Date(selectionRange.endDate)
      registro.propietario = user.propietario

      const requeridos = [ 'titulo', 'descripcion', 'startDate', 'endDate' ]
      let errores = []
      requeridos.map(key => {
        if(!registro[key]) return errores.push(`${key} es requerido`)
      })
      
      
      if(registro.tipo === 'area'){
        if(!registro.areasignada) errores.push('Falta seleccionar un área')
      } else if ( registro.tipo === 'usuario'){
        if(!registro.emails || registro.emails.length < 1) errores.push('Falta seleccionar los usuarios')
      }
      
      if(errores.length > 0) return toast.error(`Corrije los errores, ${errores.join(', ')}`, this.state.toaststyle)
      
      this.setState({ saving: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/crearEditarReclutamiento`,{
            method: 'POST',
            body: JSON.stringify(registro),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            toast.success('Guardado exitosamente', this.state.toaststyle)
            this.getReclutamiento(user.propietario)
            return this.setState({ saving: false, nuevo: false, registro: {} })
        })
        .catch(error => {
            this.setState({ saving: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    mostrarReclutamientos(){
      const { reclutamientos, deleting, opcionesfecha, loadingReclutamientos, areas } = this.state

      if(loadingReclutamientos) return <div>
      <Spinner animation="border" />
      <h3>Cargando información...</h3>
  </div>

      return <div>

<Table responsive hover>
  <thead>
    <tr>
      <th>Título</th>
      <th>Inicio</th>
      <th>Término</th>
      <th>Área</th>
    </tr>
  </thead>
  <tbody>
    { reclutamientos.map((dato,i)=>{
        let area = {}
        const fechainicio = new Date(dato.startDate)
        const fechatermino = new Date(dato.endDate)
        
        if(dato.areasignada){
            if(areas.length > 0){
                area = areas.find(ar => ar._id['$oid']===dato.areasignada)
            }
        }
        return <tr key={`user${i}`}>
            <th>{dato.titulo}<br/>
            <small className="text-primary d-block onclick" onClick={()=>this.setState({ nuevo: true, registro: dato, selectionRange: { key:'selection', startDate: fechainicio, endDate: fechatermino } })}>Editar</small>
            <Link to={`/planificacion-${dato._id}`}><small className="text-primary d-block onclick" >Evaluaciones</small></Link>
            { deleting ? <Spinner animation="border" /> : <small className="text-danger d-block onclick" onClick={()=>this.solicitarEliminado(dato)} >Eliminar</small> }
            </th>
            <th>{fechainicio.toLocaleDateString('es-ES', opcionesfecha)}</th>
            <th>{fechatermino.toLocaleDateString('es-ES', opcionesfecha)}</th>
            <th>{area._id ? area.nombre : 'Sin área asignada'}</th>
        </tr>
    }) }
  </tbody>
</Table>

      </div>

    }

    solicitarEliminado(dato){

        return confirmAlert({
          title: `¿Deseas eliminar ${dato.titulo}?`,
          message: 'Esta acción no se puede deshacer.',
          buttons: [
            {
              label: 'SI',
              onClick: () => this.removerReclutamiento(dato._id)
            },
            {
              label: 'CANCELAR',
              onClick: () => false
            }
          ]
        })
    }

    guardarConfiguracion(){
        const { conseciones, user } = this.state

        conseciones.propietario = user.propietario

        this.setState({ saving: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_GuardarConfigReclutamiento`,{
            method: 'POST',
            body: JSON.stringify(conseciones),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            toast.success('Guardado exitosamente', this.state.toaststyle)
            this.setState({ saving: false })
            return this.getConseciones(user.propietario)
        })
        .catch(error => {
            this.setState({ saving: false })
            alert("Error al consultar la información, intente nuevamente")
        })

    }

    formularioNuevoReclutamiento(){
      const { selectionRange, nuevo, saving, loadingUsuarios, usuarios, areas, registro, opcionesfecha } = this.state

      if(!nuevo) return false

      let rangoactual = ''

        if(selectionRange.startDate){
            const stringdesde = selectionRange.startDate.toLocaleDateString('es-ES', opcionesfecha)
            rangoactual += `Desde ${stringdesde}`
        }

        if(selectionRange.endDate){
            const stringhasta = selectionRange.endDate.toLocaleDateString('es-ES', opcionesfecha)
            rangoactual += ` hasta ${stringhasta}`
        }

        if(selectionRange.startDate === selectionRange.endDate){
            const stringunicafecha = selectionRange.startDate.toLocaleDateString('es-ES', opcionesfecha)
            rangoactual = ''
            rangoactual = stringunicafecha
        }

        const options = []

        usuarios.map(user => {
          options.push({ value: user.email, label: user.email })
        })

        return <div>

<Row>

<Col md={3} >
<div className="form-group">
    <label className="form-control-label d-block">Título</label>
   <input className="form-control" name="titulo" value={registro.titulo} onChange={this.handleChangeRegistro} />
</div>
</Col>

<Col md={3} >
<div className="form-group">
    <label className="form-control-label d-block">Descripción</label>
   <input className="form-control" name="descripcion" value={registro.descripcion} onChange={this.handleChangeRegistro} />
</div>
</Col>

<Col md={3} >
<div className="form-group">
    <label className="form-control-label d-block">Tipo</label>
    <select name="tipo" className="form-control" value={registro.tipo} onChange={this.handleChangeRegistro} >
        <option value="">Seleccione</option>
        <option value="area">Por área</option>
        <option value="usuario">Por usuario</option>
    </select>
</div>
</Col>

{
  registro.tipo === 'area' ? <Col md={3} >
  <div className="form-group">
      <label className="form-control-label d-block">Area de trabajo</label>
      <select name="areasignada" className="form-control" value={registro.areasignada} onChange={this.handleChangeRegistro} >
          <option value="">Seleccione</option>
          {
              areas.length > 0 ? areas.map(area => {
                  return <option value={area._id['$oid']}> {area.nombre} </option>
              }) : false
          }
      </select>
  </div>
  </Col> : false
}

{
  registro.tipo === 'usuario' ? <Col md={12} >
  <div className="form-group">
      <label className="form-control-label d-block">Selecciona los usuarios a los cuales está dirigida la evaluación en esta planificación</label>
      <Select
        isLoading={loadingUsuarios}
        isMulti={true} 
        options={options} 
        placeholder="Seleccione los usuarios"
        onChange={this.handleChangeEmails}
        />
  </div>
  </Col> : false
}


<Col md={12}>
<div className="form-group">
<label className="form-control-label d-block">Fecha de evaluación</label>
<h4>{rangoactual}</h4>
<DateRangePicker
locale={es}
ranges={[selectionRange]}
onChange={item => this.cambiarRangoFecha(item)}
direction="vertical"
scroll={{ enabled: true }}
/>

</div>
</Col>

<Col md={3}>
<label className="form-control-label d-block">Click para agregar</label>
{ saving ? <Spinner animation="border" /> : <button className="btn btn-success" onClick={() => this.guardarReclutamiento()} >GUARDAR</button> }

</Col>

</Row>

        </div>
    }
    reclutamiento(){
      const { selectionRange, nuevo, registros } = this.state


      return <div>
        <Card>
          <Card.Body>
          <Card.Title style={{ float: 'left' }}>{registros.length} registros encontrados</Card.Title>
          <Button style={{ float: 'right', marginBottom: 15 }} onClick={()=> this.setState({ nuevo: nuevo ? false : true,  })}  variant="outline-primary">NUEVA PLANIFICACIÓN</Button>
          <div style={{ clear: 'both' }}><hr /></div>
            {this.formularioNuevoReclutamiento()}
            {this.mostrarReclutamientos()}
          </Card.Body>
        </Card>
        

      </div>
    }

    activarDesactivar(i){
        const { conseciones } = this.state

        if(!conseciones.areas) return false
        if(!conseciones.areas[i]) return false
        
        conseciones.areas[i].activo = conseciones.areas[i].activo === true ? false : true
        return this.setState({ conseciones: conseciones})

    }

    agregarArea(){
        const { conseciones, area } = this.state
        if(!area) return toast.error('Debes seleccionar una area antes de poder gestionarla', this.state.toaststyle)
        if(!conseciones.areas) conseciones.areas = []

        const buscar = conseciones.areas.filter(ar => ar.idarea === area)

        if(buscar.length > 0) return false

        conseciones.areas.push({
            idarea: area,
            activo: false
        })
        return this.setState({ conseciones: conseciones  })
    }
    
    render(){

        const {miusuario} = this.props
        const { user } = this.state
        return(
            <div className="fluid">
       <Header/>   
       <ToastContainer />
<div className="container-fluid">
  <div className="row">
   <Nav/>
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Planificación</h1>
        
      </div>
          { this.reclutamiento() }
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(ReclutamientoInterno);