import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button } from 'react-bootstrap'
import Header from '../Header'
import Nav from '../Nav'
import Spinner from 'react-bootstrap/Spinner'
import Table from 'react-bootstrap/Table'
import { ToastContainer, toast } from 'react-toastify';
import * as XLSX from 'xlsx'

class BibliotecaHabilidades extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            matrices: [],
            loadingUsuarios: true,
            filtroroles: '',
            rol: {},
            hojas: [],
            showcreacion: false,
            textguardado: '',
            loadingroles: true,
            roles: [],
            loadingmatrices: true,
            tipohabilidad: '',
            toaststyle: { position: toast.POSITION.BOTTOM_RIGHT }
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeRol = this.handleChangeRol.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
    }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    handleChangeRol(e){
        const { name, value } = e.target
        const { rol } = this.state
        rol[name] = value
        console.log(rol)
        return this.setState({ rol: rol })
    }

    handleInputChange (event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        this.setState({
          [name]: value
        })
        let hojas = []
        if (name === 'file') {
            this.setState({procesandoexcel:true})
          let reader = new FileReader()
          reader.readAsArrayBuffer(target.files[0])
          reader.onloadend = (e) => {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, {type: 'array'});
    
            workbook.SheetNames.forEach(function(sheetName) {
              var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
              hojas.push(XL_row_object)
            })
            let items = []
            const requeridos = [
                'nombre',
                'tipo',
                'esperado'
            ]
            const tipospermitidos = [
                'insignia',
                'evaluacion'
            ]

            const columnasincompletas = []
            hojas[0].forEach((row,i) => {
                const faltantes = []
                requeridos.map(req => {
                    if(!row[req]) faltantes.push(req)
                    return true
                })
                if(!tipospermitidos.includes(row['tipo'])) columnasincompletas.push(`La columna ${i+1} contiene un tipo de habilidad no válida, permitidos: insignia o evaluacion`)
                if(faltantes.length > 0) columnasincompletas.push(`La columna ${i+1} le faltan campos: ${faltantes.join(', ')}`)
                return true
            })

            hojas[0].map((row,i) => {

                const hab = {
                nombre: row.nombre ? row.nombre.toString() : '' ,
                descripcion: row.descripcion ? row.descripcion.toString() : '' ,
                nota1: row.nota1 ? row.nota1.toString() : '' ,
                nota2: row.nota2 ? row.nota2.toString() : '' ,
                nota3: row.nota3 ? row.nota3.toString() : '' ,
                nota4: row.nota4 ? row.nota4.toString() : '' ,
                nota5: row.nota5 ? row.nota5.toString() : '' ,
                tipo: row.tipo ? row.tipo.toString() : '' ,
                status: '1',
                estado: '1',
                logrado: '',
                esperado: row.esperado ? row.esperado.toString() : '' 
                }
                return items.push(hab)
            })

            if(columnasincompletas.length > 0){
                this.setState({ procesandoexcel: false })
                columnasincompletas.push('Corrige el documento e inténtalo de nuevo')
                return toast.error( columnasincompletas.join('. '), this.state.toaststyle )
            }
            
            this.setState({
              selectedFileDocument: target.files[0],
              hojas: items,
              procesandoexcel: false
            })
          }
        }
      }

    componentDidMount(){
        const { user } = this.state
        //this.getRoles(user.tipo)
        this.getHabilidades(user.propietario)
    }

    mostrarExcel(){
        const { hojas, procesandoexcel, guardando } = this.state

        if(procesandoexcel){
            return <div>
            <Spinner animation="border" />
            <h4>Analizando documento...</h4>
            </div>
        }

        if(hojas.length > 0){
            return <div className="boxexcel">
             {
                 guardando ? <Spinner animation="border" /> : <div>
                    <div className="table-responsive">

                <h5>Encontramos {hojas.length} registros en tu documento excel </h5>
                <Table responsive hover> 
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Descripción</th>
                            <th>Score 1</th>
                            <th>Score 2</th>
                            <th>Score 3</th>
                            <th>Score 4</th>
                            <th>Score 5</th>
                            <th>Tipo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            hojas.map((usuario,iu) => {
                                return <tr key={`user${iu}`}>
                            <th>{usuario.nombre}</th>
                            <th>{usuario.descripcion}</th>
                            <th>{usuario.nota1}</th>
                            <th>{usuario.nota2}</th>
                            <th>{usuario.nota3}</th>
                            <th>{usuario.nota4}</th>
                            <th>{usuario.nota5}</th>
                            <th><span className="spanclass" style={{ background: usuario.tipo === 'evaluacion' ? '#208bff' : '#ffcb0f' }}>{usuario.tipo}</span></th>
                                </tr>
                            })
                        }
                    </tbody>
                </Table>


                        </div>

            <button className="btn btn-success mb-3" onClick={() => this.subirHabilidades()}>SUBIR ARCHIVO Y ACTUALIZAR BASE DE DATOS</button>
            </div>
             }   
            </div>
        }
    }

    subirHabilidades(){
        const { user, hojas } = this.state
        this.setState({ guardando: true })

        return fetch('https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_crearHabilidadesBach',{
            method: 'POST',
            body: JSON.stringify({
                habilidades: hojas,
                propietario: user.propietario
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            this.setState({ guardando: false, hojas: [] })
            toast.success('Datos cargados exitosamente', this.state.toaststyle )
            this.getHabilidades(user.propietario)
        })
        .catch(error => {
            this.setState({ guardando: false })
            return toast.error('Error al consultar la información, intente nuevamente', this.state.toaststyle)
        })
    }

    getHabilidades(propietario){

        this.setState({ loadingmatrices: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_BibliotecaHabilidades?propietario=${propietario}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingmatrices: false, matrices: res })
        })
        .catch(error => {
            this.setState({ loadingmatrices: false })
            alert("Error al consultar la información, intente nuevamente")
        })

    }

    getRoles(tipo){

        this.setState({ loadingroles: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getRolesUsuario?tipo=${tipo}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingroles: false, roles: res })
        })
        .catch(error => {
            this.setState({ loadingroles: false })
            alert("Error al consultar la información, intente nuevamente")
        })

    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }
    
    guardarCrear(){
        const { rol, user } = this.state

        const requeridos = [
            'nombre',
            'descripcion',
            'nota1',
            'nota2',
            'nota3',
            'nota4',
            'nota5',
            'tipo'
        ]
        
        const tipospermitidos = [
            'insignia',
            'evaluacion'
        ]

        const faltantes = []
        requeridos.forEach((elem,i) => {
            if(!rol[elem]) faltantes.push(elem)
        })
        
        if(!tipospermitidos.includes(rol.tipo)) faltantes.push(`Tipo de habilidad no válida, permitidos: insignia o evaluacion`)

        if(faltantes.length > 0){
            return toast.error( `Faltan campos por llenar: ${faltantes.join(', ')}`, this.state.toaststyle )
        }

        rol.propietario = user.propietario
        if(!rol._id){
            rol.status = '1'
            rol.estado = '1'
            rol.logrado  = ''
            rol.esperado  = ''
            rol.evaluacionfinal = ''            
        }

        this.setState({ guardandocambios: true })
        return fetch('https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_EditarCrearHabilidad',{
            method: 'POST',
            body: JSON.stringify(rol),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            this.setState({ guardandocambios: false, rol: {}, showcreacion: false })
            this.getHabilidades(user.propietario)
            return toast.success("Datos guardados exitosamente", this.state.toaststyle )
        })
        .catch(error => {
            this.setState({ guardandocambios: false })
            return toast.error("Error, no se pudo consultar la información, intenta actualizar la página", this.state.toaststyle )
        })
    }

    fetchUsuarios(condicion){
        this.setState({ loadingUsuarios: true })
        return fetch('https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getUsersCustom',{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingUsuarios: false, usuarios: res })
        })
        .catch(error => {
            this.setState({ loadingUsuarios: false })
            alert("Error al consultar la información, intente nuevamente")
        })
    }
    getUsuariosByTipo(user){

        switch (user.tipo) {
            case 'admin':
                this.getRoles(user.tipo)
                return this.fetchUsuarios({ propietario: user.propietario })
            case 'evaluator':
                this.getRoles(user.tipo)
                return this.fetchUsuarios({ propietario: user.propietario, tipo: 'empleado' })
            default:
                this.setState({ loadingUsuarios: false })
                break;
        }
        return false
    }

    statusUser(tipo){
        switch (tipo) {
            case '1':
                    return 'Activo'
                    break;
            default:
                return 'Inactivo'
                break;
        }
    }

    tipoUser(tipo){
        switch (tipo) {
            case 'admin':
                    return 'Administrador'
                    break;
            case 'evaluator':
                    return 'Evaluador'
                    break;
            case 'empleado':
                    return 'Usuario'
                    break;        
            default:
                return tipo
                break;
        }
    }

    formularioNuevaHabilidad(){
        const { showcreacion, rol, guardandocambios, matrices } = this.state

        if(guardandocambios){
            return <div>
            <Spinner animation="border" />
            <h3>Guardando información...</h3>
        </div>
        }
        if(showcreacion){

            return <Card className="card-blue">
                
                <Card.Body className="text-white ">
                <Row>
                <Col md={12} >
                { rol._id ? <h3>Editar matriz de {rol.nombre ? rol.nombre : 'habilidad'} </h3> : <h3>Crear habilidad</h3> }
                </Col>

<Col md={12} >
    <label className="form-control-label">Nombre</label>
    <input name="nombre" className="form-control" value={rol.nombre ? rol.nombre : ''} onChange={this.handleChangeRol} />
</Col>

<Col md={12} >
    <label className="form-control-label">Descripción</label>
    <input name="descripcion" className="form-control" value={rol.descripcion ? rol.descripcion : ''} onChange={this.handleChangeRol} />
</Col>

<Col md={12} >
    <label className="form-control-label">Tipo de habilidad</label>
    <select className="form-control" name="tipo" value={rol.tipo ? rol.tipo : ''} onChange={this.handleChangeRol} >
        <option value="" >Selecciona una opción</option>
        <option value="insignia" >Insignia</option>
        <option value="evaluacion">Evaluación</option>
    </select>
</Col>

<Col md={12} >
    <label className="form-control-label">Score 1</label>
    <input name="nota1" className="form-control" value={rol.nota1 ? rol.nota1 : ''} onChange={this.handleChangeRol} />
</Col>

<Col md={12} >
    <label className="form-control-label">Score 2</label>
    <input name="nota2" className="form-control" value={rol.nota2 ? rol.nota2 : ''} onChange={this.handleChangeRol} />
</Col>

<Col md={12} >
    <label className="form-control-label">Score 3</label>
    <input name="nota3" className="form-control" value={rol.nota3 ? rol.nota3 : ''} onChange={this.handleChangeRol} />
</Col>

<Col md={12} >
    <label className="form-control-label">Score 4</label>
    <input name="nota4" className="form-control" value={rol.nota4 ? rol.nota4 : ''} onChange={this.handleChangeRol} />
</Col>

<Col md={12} >
    <label className="form-control-label">Score 5</label>
    <input name="nota5" className="form-control" value={rol.nota5 ? rol.nota5 : ''} onChange={this.handleChangeRol} />
</Col>



<Col md={3}>
<label className="form-control-label d-block">Click para { rol._id ? 'editar' : 'crear' } </label>
    <Button style={{ background: 'white' }} onClick={()=>this.guardarCrear()} variant="outline-white">{ rol._id ? 'GUARDAR CAMBIOS' : 'CREAR' }</Button>
</Col>

<Col xs={12}>
<p className="m-0" onClick={()=>this.setState({ rol: {} })}>Reiniciar formulario</p>

</Col>

</Row>
                </Card.Body>
            </Card>
        }


    }

    mostrarHabilidades(){
        const { loadingmatrices, filtroroles, tipohabilidad, matrices, showcreacion } = this.state

        if(loadingmatrices){
            return <div>
                <Spinner animation="border" />
                <h3>Cargando información</h3>
            </div>
        }

        const lowercasedFilter = filtroroles.toLowerCase();
        const matricesFiltradas = matrices.filter(item => {
            return Object.keys(item).some(key =>
                {
                    if(!item[key]) return false
                    return item[key].toString().toLowerCase().includes(lowercasedFilter)
                }
            );
        });

        const filtradosporhabilidad = tipohabilidad ? matricesFiltradas.filter(orden => {
            if(orden.tipo === tipohabilidad) return true
            return false
        }) : matricesFiltradas
        
        return <Card style={{ width: '100%' }}>
        <Card.Body>
          <Card.Title style={{ float: 'left' }}>{matrices.length} habilidades encontradas</Card.Title>
          <Button style={{ float: 'right', marginBottom: 15 }} onClick={()=> this.setState({ showcreacion: showcreacion ? false : true, textguardado: '' })}  variant="outline-primary">CREAR</Button>
          <div style={{ clear: 'both' }}><hr /></div>
        {this.formularioNuevaHabilidad()}
          <Row>

            <Col md={3}>
            <label className="form-control-label">Filtrar resultados</label>
            <input name="filtroroles" className="form-control mb-4" placeholder="Escribe cualquier palabra clave" onChange={this.handleChange} />
            </Col>

            <Col md={3}>
            <label className="form-control-label">Filtrar tipo de habilidad</label>
            <select className="form-control" name="tipohabilidad" onChange={this.handleChange} >
                <option value="">Selecciona</option>
                <option value="evaluacion">Evaluación</option>
                <option value="insignia">Insignia</option>
            </select>
            </Col>
            
            <Col xs={12}>
            <hr />
            <div className="form-group">
            <label className="form-control-label">Carga tu documento excel con la base de tus habilidades, las columnas son <b>nombre, descripcion, nota1, nota2, nota3, nota4, nota5 y esperado</b></label>
            <div className="mb-2">
            <a href="bibliotecahabilidades.xlsx" className="btn btn-sm btn-outline-warning" >DESCARGAR MODELO EXCEL</a>
            </div>
            <input 
            required 
            type="file" 
            name="file" 
            id="file" 
            className="form-control"
            onChange={this.handleInputChange} 
            placeholder="Archivo de excel" 
            />
            </div>

            {this.mostrarExcel()}
            </Col>
            {filtroroles !== '' ? <Col xs={12}><label className="form-control-label">{filtradosporhabilidad.length} Coindicencias</label></Col>:false}
          
          </Row>          

          <Table responsive hover>
  <thead>
    <tr>
      <th>Nombre</th>
      <th>Tipo</th>
      <th>Estado</th>
      <th>Descripción</th>
      <th>Score 1</th>
      <th>Score 2</th>
      <th>Score 3</th>
      <th>Score 4</th>
      <th>Score 5</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    { filtradosporhabilidad.map((rol,i)=>{
        // <small style={{ fontWeight: 600 }} onClick={()=>this.duplicarElemento(rol._id['$oid'])} className="text-primary onclick">Duplicar</small> 
        return <tr key={`user${i}`}>
            <th>{rol.nombre}<br/><small className="text-primary onclick" onClick={()=>this.setState({ showcreacion: true, rol: rol })}>Editar</small> </th>
            <th><span className="spanclass" style={{ background: rol.tipo === 'evaluacion' ? '#208bff' : '#ffcb0f' }}>{rol.tipo}</span></th>
            <th>{this.statusUser(rol.estado)}</th>
            <th>{rol.descripcion}</th>
            <th>{rol.nota1}</th>
            <th>{rol.nota2}</th>
            <th>{rol.nota3}</th>
            <th>{rol.nota4}</th>
            <th>{rol.nota5}</th>
            <th></th>
        </tr>
    }) }
  </tbody>
</Table>

        </Card.Body>
      </Card>
    }


    duplicarElemento(id){
        const { user } = this.state
        this.setState({ loadingmatrices: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_duplicarMatriz?id=${id}`)
        .then(res => res.json())
        .then(res => {

            this.setState({ loadingmatrices: false})
            this.getHabilidades({ propietario: user.propietario })

        })
        .catch(error => {
            this.setState({ loadingmatrices: false })
            alert("Error al consultar la información, intente nuevamente")
        })
    }
    
    render(){

        const {miusuario} = this.props
        const { errorLogin } = this.state
        return(
            <div className="fluid">
       <Header/>   
       <ToastContainer />
<div className="container-fluid">
  <div className="row">
   <Nav/>
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Biblioteca de habilidades</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
        </div>
      </div>

      {this.mostrarHabilidades()}
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(BibliotecaHabilidades);