import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card } from 'react-bootstrap'
import Header from '../Header'
import Nav from '../Nav'
import Spinner from 'react-bootstrap/Spinner'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import * as XLSX from 'xlsx'
import { calcularCategoria, checkForPermissions } from '../../helpers/index'

class Configuracion extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            usuarios: [],
            loadingclasificacion: true,
            filtrousuario: '',
            usuario: {},
            tipousuario: '',
            showcreacion: false,
            textguardado: '',
            loadingroles: true,
            roles: [],
            loadingcargos: true,
            cargos: [],
            areas: [],
            loadingareas: true,
            hojas: [],
            clasificacion: false,
            procesandoexcel: false,
            guardando: false,
            toaststyle: { position: toast.POSITION.BOTTOM_RIGHT },
            area_busqueda: '', 
            cargo_busqueda: '',
            loadingmatrices: true,
            matrices: [],
            evaluaciones: []
        }

        this.handleChangeClasificacion = this.handleChangeClasificacion.bind(this)
        this.handleChangeClasificacionv2 = this.handleChangeClasificacionv2.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleChangeActualizar = this.handleChangeActualizar.bind(this)

    }

    handleChangeClasificacionv2(e){
        const { name, value } = e.target
        const { clasificacion } = this.state
        clasificacion[name] = value
        return this.setState({ clasificacion })
    }
    handleChangeClasificacion(e){
        const { name, value } = e.target
        const { clasificacion } = this.state
        clasificacion[name].texto = value
        return this.setState({ clasificacion })
    }

    handleChangeActualizar(e){
        const { name, value } = e.target
        const { matrices, cargos } = this.state
        if(name==='cargo_busqueda'){
            const buscar_cargo = cargos.findIndex(ca => ca._id['$oid'] === value)
            if(buscar_cargo > -1){
                const buscar = matrices.filter(ma => ma._id['$oid'] === cargos[buscar_cargo].idmatriz )
            }
        } else if( name === 'area_busqueda' ){
            this.setState({ cargo_busqueda: '' })
        }
        return this.setState({ [name]: value })
    }

    handleInputChange (event) {
        const target = event.target
        const name = target.name
        const { user, cargos, cargo_busqueda, matrices } = this.state
        this.setState({ errores_carga_excel: []  })
        let hojas = []
        if (name === 'file') {
            this.setState({procesandoexcel: true  })
            let reader = new FileReader()
            reader.readAsArrayBuffer(target.files[0])
            reader.onloadend = (e) => {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, {type: 'array'});
    
            workbook.SheetNames.forEach(function(sheetName) {
              var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
              hojas.push(XL_row_object)
            })

            if(hojas.length < 1) return toast.error('Sin datos encontrados', this.state.toaststyle)
            if(hojas[0].length < 1) return toast.error('Sin datos encontrados', this.state.toaststyle)
            
            const requeridos = [
                'level1',
                'level2',
                'level3',
                'habilidad',
                'Desired Level'
            ]
            let columnas_faltantes = []
            let errores = []
            let evaluaciones = []

            const buscar_cargo = cargos.findIndex(ca => ca._id['$oid'] === cargo_busqueda)
            const buscar = matrices.findIndex(ma => ma._id['$oid'] === cargos[buscar_cargo].idmatriz )
            

            let columnas_excel_cargado = Object.keys(hojas[0][0])
            requeridos.map(req => {
                if(!columnas_excel_cargado.includes(req)) columnas_faltantes.push(req)
            })
            if(columnas_faltantes.length > 0) return toast.error(`Columnas faltantes en el esquema ${columnas_faltantes.join(', ')}`, this.state.toaststyle)

            // ELIMINAR DE LAS KEYS LOS CAMPOS DE LA MATRIZ
            requeridos.map(req => {
                const i = columnas_excel_cargado.findIndex(col => col === req)
                if(i > -1) columnas_excel_cargado.splice(i,1)
            })
            
            let personas = []
            columnas_excel_cargado.map(persona => personas.push({ rut: persona, habilidades: [] }))
            
            hojas[0].map(async (row,i) => {

                columnas_excel_cargado.map(async key => {

                    const ipersona = personas.findIndex(pe => pe.rut === key)
                    if(ipersona > -1){

                        personas[ipersona].habilidades.push({
                            nombre: row.habilidad,
                            puntaje: row[key]
                        })

                    }
                    /*
                    const i_evaluacion = evaluaciones.findIndex(evaluacion => evaluacion.usuarioevaluado.rut === key)
                    if(i_evaluacion < 0) return false

                   // evaluaciones[i_evaluacion].matriz.esquema.niveles[i_nivel1].niveles[i_nivel2].niveles[i_nivel3].habilidades[i_hab].logrado = row[key]

                    let primernivel = []
                    evaluaciones[i_evaluacion].matriz.esquema.niveles.map(nivel => {
                                        if(!nivel.niveles) return false
                                        let niveles = []
                                        nivel.niveles.map(level=>{
                                            if(!level.niveles) return false
                                            let subniveles = []
                                            level.niveles.map(le=>{
                                                if(!le.habilidades) return false
                                                let habilidadex = []
                                                le.habilidades.map(hab=>{
                                                    
                                                    if(hab.nombre.toLowerCase() === row.habilidad.toLowerCase()){
                                                        hab.logrado = row[key].toString()
                                                        habilidadex.push(hab)
                                                    } else habilidadex.push(hab)
                                                })
                                                subniveles.push({
                                                    nombre: le.nombre,
                                                    habilidades: habilidadex
                                                })
                                            })
                                            niveles.push({
                                                nombre: level.nombre,
                                                niveles: subniveles
                                            })
                                        })
                                        primernivel.push({
                                            nombre: nivel.nombre,
                                            niveles: niveles
                                        })
                                    })
                                    evaluaciones[i_evaluacion] = {
                                        usuarioevaluado: evaluaciones[i_evaluacion].usuarioevaluado,
                                        propietario: user.propietario,
                                        modalidad: 'autoevaluacion',
                                        cargo: false,
                                        matriz: {
                                            esquema: {
                                                niveles: primernivel
                                            }
                                        }
                                    }
                                    */

                })
                
            })

            if(errores.length > 0){
                return this.setState({ errores_carga_excel: errores, procesandoexcel: false  })
            }

            personas.map(persona => {

                let primernivel = []
                matrices[buscar].esquema.niveles.map(nivel => {
                    if(!nivel.niveles) return false
                    let niveles = []
                    nivel.niveles.map(level=>{
                        if(!level.niveles) return false
                        let subniveles = []
                        level.niveles.map(le=>{
                            if(!le.habilidades) return false
                            let habilidadex = []
                            le.habilidades.map(hab=>{
                                const ihabilidad = persona.habilidades.findIndex(h => h.nombre.toLowerCase() === hab.nombre.toLowerCase() )
                                if(ihabilidad > -1){
                                    habilidadex.push({
                                        _id: hab._id,
                                        nombre: hab.nombre,
                                        logrado: persona.habilidades[ihabilidad].puntaje.toString(),
                                        descripcion: hab.descripcion,
                                        nota1: hab.nota1,
                                        nota2: hab.nota2,
                                        nota3: hab.nota3,
                                        nota4: hab.nota4,
                                        nota5: hab.nota5,
                                        esperado: hab.esperado ? hab.esperado.toString() : '',
                                    })
                                } else {
                                    habilidadex.push(hab)
                                }         
                            })
                            subniveles.push({
                                nombre: le.nombre,
                                habilidades: habilidadex
                            })
                        })
                        niveles.push({
                            nombre: level.nombre,
                            niveles: subniveles
                        })
                    })
                    primernivel.push({
                        nombre: nivel.nombre,
                        niveles: niveles
                    })
                })

                const evaluacion_final = this.calcularResultadoEvaluacion({
                    usuarioevaluado: { rut: persona.rut },
                    propietario: user.propietario,
                    modalidad: 'autoevaluacion',
                    cargo: cargos[buscar_cargo],
                    tipo: "simple",
                    creado: this.formatDate(new Date()),
                    status: "1",
                    matriz: {
                        esquema: {
                            niveles: primernivel
                        }
                    }
                })

                evaluaciones.push(evaluacion_final)



            })

            
            this.setState({
              procesandoexcel: false, evaluaciones
            })
          }
        }
      }

      capturarNumeroSiEsObjetoMongo(numero){
          if(typeof numero === 'object'){
              if(numero['$numberInt']) return numero['$numberInt']
          }
          return numero
      }

      calcularResultadoEvaluacion(evaluacion){

        let llenado = 0
        let correctas = 0
        let cantidadhabilidades = 0
        let totalparapuntaje = 0
        let promediodeseado = 0
        evaluacion.matriz.esquema.niveles.map(nivel => {
            if(!nivel.niveles) return false
            nivel.niveles.map(level=>{
                if(!level.niveles) return false
                level.niveles.map(le=>{
                    if(!le.habilidades) return false
                    le.habilidades.map(hab=>{
                        cantidadhabilidades++
                        if(hab.logrado) llenado++
                        const esperado = hab.esperado ? parseInt(this.capturarNumeroSiEsObjetoMongo(hab.esperado)) : 0
                        const logrado = hab.logrado ? parseInt(this.capturarNumeroSiEsObjetoMongo(hab.logrado)) : 0
                        totalparapuntaje = totalparapuntaje + logrado
                        promediodeseado = promediodeseado + esperado
                        if(logrado>=esperado) correctas++
                    })
                })
            })
        })
        const deseado = Math.round(promediodeseado / cantidadhabilidades)
        const puntaje = (totalparapuntaje / cantidadhabilidades).toFixed(2)

        let categoria = calcularCategoria(puntaje, deseado)

        evaluacion.porcentajellenado = Math.round(llenado/cantidadhabilidades*100).toString()
        evaluacion.porcentajecorrectas = Math.round(correctas/cantidadhabilidades*100).toString()
        evaluacion.resultadopromedio = (totalparapuntaje / cantidadhabilidades).toFixed(2).toString()
        evaluacion.promediodeseado = (promediodeseado / cantidadhabilidades).toFixed(2).toString()
        evaluacion.categoria = categoria

        return evaluacion

      }

      formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    componentDidMount(){
        const { user } = this.state
        this.getConfigByTipo(user.propietario,'clasificacion','clasificacion','loadingclasificacion')
        this.getAreas(user.propietario,'areas','loadingareas')
        this.getCargos({ propietario: user.propietario })
        this.getMatrices({ propietario: user.propietario })
    }

    sliceIntoChunks(arr, chunkSize) {
        const res = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            const chunk = arr.slice(i, i + chunkSize);
            res.push(chunk);
        }
        return res;
    }

    getMatrices(condicion){

        this.setState({ loadingmatrices: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_VerMatricesHabilidades`,{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            this.setState({ loadingmatrices: false, matrices: res })
        })
        .catch(error => {
            this.setState({ loadingmatrices: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }


    getCargos(condicion){
        this.setState({ loadingcargos: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_GetCargos`,{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            this.setState({ loadingcargos: false, cargos: res })
        })
        .catch(error => {
            this.setState({ loadingcargos: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    getAreas(propietario,estado,loading){

        this.setState({ [loading]: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getAreas?propietario=${propietario}`)
        .then(res => res.json())
        .then(res => {
            this.setState({ [loading]: false, [estado]: res })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            alert("Error al consultar la información, intente nuevamente")
        })
    
    }

    getConfigByTipo(propietario,tipo,estado,loading){

        this.setState({ [loading]: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/getConfitByTipo?propietario=${propietario}&tipo=${tipo}`)
        .then(res => res.json())
        .then(res => {
            this.setState({ [loading]: false, [estado]: res })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    guardarClasificacion(){
        const { clasificacion, user } = this.state
        clasificacion.propietario = user.propietario
        this.setState({ guardando: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/guardarConfigByTipo`,{
            method: 'POST',
            body: JSON.stringify(clasificacion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            this.setState({ guardando: false })
        })
        .catch(error => {
            this.setState({ guardando: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    mostrarUsuarios(){
        const { loadingUsuarios, guardando, loadingclasificacion, clasificacion, usuarios, cargos, areas, filtrousuario, textguardado, tipousuario, showcreacion } = this.state

        if(loadingclasificacion){
            return <div>
                <Spinner animation="border" />
                <h3>Cargando información</h3>
            </div>
        }

        return <Card style={{ width: '100%' }}>
        <Card.Body>
          <Card.Title style={{ float: 'left' }}></Card.Title>
          <Row>
            <Col xs={12}>
            <h4>Clasificación</h4>
            <hr />
            <Row>
            <Col xs={12} className="mb-3">
                <label className="form-control-label">Información general</label>
                <input className="form-control" name="informacion_adicional" value={clasificacion.informacion_adicional} onChange={this.handleChangeClasificacionv2} />
            </Col>
            <Col xs={12} className="mb-3">
                <h4>A</h4>
                <label className="form-control-label">Descripción</label>
                <input className="form-control" name="a" value={clasificacion.a.texto} onChange={this.handleChangeClasificacion} />
            </Col>
            <Col xs={12} className="mb-3">
                <h4>B</h4>
                <label className="form-control-label">Descripción</label>
                <input className="form-control" name="b" value={clasificacion.b.texto} onChange={this.handleChangeClasificacion} />
            </Col>
            <Col xs={12} className="mb-3">
                <h4>C</h4>
                <label className="form-control-label">Descripción</label>
                <input className="form-control" name="c" value={clasificacion.c.texto} onChange={this.handleChangeClasificacion} />
            </Col>
            <Col xs={12} className="mb-3">
                <h4>D</h4>
                <label className="form-control-label">Descripción</label>
                <input className="form-control" name="d" value={clasificacion.d.texto} onChange={this.handleChangeClasificacion} />
            </Col>
            <Col xs={12} className="mb-3">
                <h4>E</h4>
                <label className="form-control-label">Descripción</label>
                <input className="form-control" name="e" value={clasificacion.e.texto} onChange={this.handleChangeClasificacion} />
            </Col>
            <Col xs={12} className="mb-3">
            { guardando ? <div>
            <Spinner animation="border" />
            <h3>Espere un momento, guardando información...</h3>
            </div> : <button className="btn btn-success" onClick={()=>this.guardarClasificacion()}>GUARDAR CAMBIOS</button>
            } 
            </Col>            
            </Row>
            </Col>
          </Row>
          {this.formularioCargaDatosExcel()}
        </Card.Body>
      </Card>
    }

    formularioCargaDatosExcel(){
        const { areas, cargos, area_busqueda, cargo_busqueda, matrices } = this.state

        const cargos_filtrados = cargos.filter(cargo => cargo.areaasignada === area_busqueda)

        return <Row>
<Col xs={12}>
    <h2>Subir evaluaciones masivas en excel</h2>
</Col>
<Col md={3} >
    <label className="form-control-label d-block">Area de trabajo</label>
    <select name="area_busqueda" className="form-control" value={area_busqueda} onChange={this.handleChangeActualizar} >
        <option value="">Seleccione</option>
        {
            areas.length > 0 ? areas.map(area => {
                return <option value={area._id['$oid']}> {area.nombre} </option>
            }) : false
        }
    </select>
    </Col>
    <Col md={3} >
    <label className="form-control-label d-block">Cargo</label>
    <select name="cargo_busqueda" className="form-control" value={cargo_busqueda} onChange={this.handleChangeActualizar} >
        <option value="">Seleccione</option>
        {
            cargos_filtrados.length > 0 ? cargos_filtrados.map(cargo => {
                return <option value={cargo._id['$oid']}> {cargo.nombre} </option>
            }) : false
        }
    </select>
</Col>

            {this.mostrarCargaExcel()}

        </Row>
    }

    mostrarCargaExcel(){
        const { cargo_busqueda, evaluaciones } = this.state
        if(!cargo_busqueda) return false

        return <Col xs={12} className="mb-3">
        <label className="form-control-label">Carga tu documento excel con la base de tus cargos de trabajo</label>
        <input 
        required 
        type="file" 
        name="file" 
        id="file" 
        className="form-control"
        onChange={this.handleInputChange} 
        placeholder="Archivo de excel" 
        />
        {this.accionesSubidaEvaluaciones()}
        </Col>
    }

    accionesSubidaEvaluaciones(){
        const { evaluaciones } = this.state
        if(evaluaciones.length < 1) return false
        return <button className="btn btn-sm btn-success mt-3" onClick={()=>this.subirEvaluaciones()}>SUBIR {evaluaciones.length} EVALUACIONES</button>
    }

    async subirEvaluaciones(){
        const { evaluaciones } = this.state

        this.setState({ loadingclasificacion: true })

        const array_dividido = this.sliceIntoChunks(evaluaciones,3)

        const recorrer = await Promise.all( await array_dividido.map(async datos => {
               const procesar = await this.uploadEvaluaciones(datos)
        }) )

        this.setState({ loadingclasificacion: false })
        return toast.success('Información cargada exitosamente', this.state.toaststyle)
    }

    uploadEvaluaciones(evaluaciones){
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/crearEvaluacionesMasivas`,{
             method: 'POST',
             body: JSON.stringify({ evaluaciones }),
             headers: {
                'Content-Type': 'application/json'
             }
        })
        .then(res => res.json())
        .then(async res => res)
        .catch(error => toast.error("Error al agregar usuarios a la lista, intente nuevamente", this.state.toaststyle))
    }
    
    render(){

        const { areas } = this.state

        return(
            <div className="fluid">
       <Header/>
       <ToastContainer />   
<div className="container-fluid">
  <div className="row">
   <Nav/>
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2"> Ajustes </h1>
      </div>

      {this.mostrarUsuarios()}
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(Configuracion);