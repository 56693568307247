import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Login from './components/Login';
import store from './redux/store';
import reportWebVitals from './reportWebVitals'
import 'bootstrap/dist/css/bootstrap.min.css'
import Home from './components/Home';
import { PrivateRoute } from './components/PrivateRoute';
import Usuarios from './components/Usuarios';
import Roles from './components/Roles';
import Permisos from './components/Permisos';
import MatrizHabilidades from './components/MatrizHabilidades';
import editarMatrizHabilidades from './components/editarMatrizHabilidades';
import Areas from './components/Areas';
import Cargos from './components/Cargos';
import Evaluaciones from './components/Evaluaciones';
import NuevaEvaluacion from './components/NuevaEvaluacion';
import DetalleEvaluacion from './components/DetalleEvaluacion';
import ReclutamientoInterno from './components/ReclutamientoInterno';
import BibliotecaHabilidades from './components/BibliotecaHabilidades';
import Insignias from './components/Insignias';
import NuevaEvalInsignia from './components/NuevaEvalInsignia';
import Informes from './components/Informes';
import Capacitaciones from './components/Capacitaciones';
import DetalleCapacitacion from './components/DetalleCapacitacion';
import DetallePlanificacion from './components/DetallePlanificacion';
import Configuracion from './components/Configuracion';

const App = (
  <Provider store={store}>
    <BrowserRouter>
    <Switch>
    
      <PrivateRoute exact path="/" component={Home} />
      <PrivateRoute exact path="/usuarios" component={Usuarios} />
      <PrivateRoute exact path="/roles" component={Roles} />
      <PrivateRoute exact path="/cargos" component={Cargos} />
      <PrivateRoute exact path="/insignias" component={Insignias} />
      <PrivateRoute exact path="/evaluaciones" component={Evaluaciones} />
      <PrivateRoute exact path="/crear-evaluacion" component={NuevaEvaluacion} />
      <PrivateRoute exact path="/crear-evaluacioninsignia" component={NuevaEvalInsignia} />
      <PrivateRoute exact path="/detalle-evaluacion-:id" component={DetalleEvaluacion} />
      <PrivateRoute exact path="/detalle-capacitacion-:id" component={DetalleCapacitacion} />
      <PrivateRoute exact path="/biblioteca-habilidades" component={BibliotecaHabilidades} />
      <PrivateRoute exact path="/planificacion-:id" component={DetallePlanificacion} />
      <PrivateRoute exact path="/configuracion" component={Configuracion} />
      <PrivateRoute exact path="/areas" component={Areas} />
      <PrivateRoute exact path="/informes" component={Informes} />
      <PrivateRoute exact path="/capacitaciones" component={Capacitaciones} />
      <PrivateRoute exact path="/permisos" component={Permisos} />
      <PrivateRoute exact path="/reclutamiento" component={ReclutamientoInterno} />
      <PrivateRoute exact path="/habilidades" component={MatrizHabilidades} />
      <PrivateRoute exact path="/editarhab-:idmatriz" component={editarMatrizHabilidades} />      
      <Route path="/login" component={Login} />
    </Switch>
  </BrowserRouter>
  </Provider>
) 

ReactDOM.render(App, document.getElementById('root'))
reportWebVitals();
