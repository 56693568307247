import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button } from 'react-bootstrap'
import Header from '../Header'
import Nav from '../Nav'
import Spinner from 'react-bootstrap/Spinner'
import Table from 'react-bootstrap/Table'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Accordion from 'react-bootstrap/Accordion'
import * as XLSX from 'xlsx'

const isObject = (obj) => {
    return Object.prototype.toString.call(obj) === '[object Object]';
};

class DetalleCapacitacion extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            idcapacitacion: this.props.match.params.id,
            matrices: [],
            filtrousuario: '',
            evento: {},
            loadingUsuarios: true,
            filtroevaluaciones: '',
            cargo: {},
            capacitacion: false,
            showcreacion: false,
            loadingcargos: true,
            cargos: [],
            areas: [],
            loadingareas: true,
            loadingmatrices: true,
            evaluaciones: [],
            reporte: [],
            toaststyle: { position: toast.POSITION.BOTTOM_RIGHT },
            habilitarnuevaevaluacion: false,
            conseciones: false,
            filtrotipo: '',
            filtrocategoria: '',
            reporteinsignia: [],
            loadingreporteinsignia: true,
            capacitaciones: [],
            selectionRange: {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection'
            },
            usuarioslista: [],
            loadingusuarioslista: false,
            usuariosfiltrados: [],
            loadingusuariosfiltrados: false,
            usuarios_detalles: [],
            loadingevento: false,
            loadingcapacitacion: true,
            habilidades: [],
            no_encontradas: [],
            loadingvalidacion: false
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeCap = this.handleChangeCap.bind(this)
        this.handleChangeEvento = this.handleChangeEvento.bind(this)
        this.handleChangeUsuarios = this.handleChangeUsuarios.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
    }

    handleInputChange (event) {
        const target = event.target
        const name = target.name
        const { user, cargos, cargo_busqueda, matrices } = this.state
        this.setState({ errores_carga_excel: [], no_encontradas: []  })
        let hojas = []
        if (name === 'file') {
            this.setState({procesandoexcel: true  })
            let reader = new FileReader()
            reader.readAsArrayBuffer(target.files[0])
            reader.onloadend = (e) => {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, {type: 'array'});
    
            workbook.SheetNames.forEach(function(sheetName) {
              var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
              hojas.push(XL_row_object)
            })

            if(hojas.length < 1) return toast.error('Sin datos encontrados', this.state.toaststyle)
            if(hojas[0].length < 1) return toast.error('Sin datos encontrados', this.state.toaststyle)
            
            const requeridos = [
                'habilidad',
                'rut',
                'puntaje'
            ]
            let datos = []
            const columnasincompletas = []
            hojas[0].forEach((row,i) => {
                const faltantes = []
                requeridos.map(req => {
                    if(!row[req]) faltantes.push(req)
                })
                if(faltantes.length > 0) columnasincompletas.push(`La columna ${i+1} le faltan campos: ${faltantes.join(', ')}`)
            })

            if(columnasincompletas.length > 0){
                this.setState({ procesandoexcel: false })
                columnasincompletas.push('Corrige el documento e inténtalo de nuevo')
                return toast.error( columnasincompletas.join('. '), this.state.toaststyle )
            }

            hojas[0].map((row,irow) => {
                if(!row.habilidad || !row.rut || !row.puntaje) return false
                datos.push({
                    rut: row.rut,
                    habilidad: row.habilidad,
                    puntaje: row.puntaje
                })
                
            })
            
            this.setState({
              procesandoexcel: false, habilidades: datos
            })
          }
        }
      }

    handleChangeUsuarios(e){
        const { evento } = this.state
        if(!evento.usuarios) evento.usuarios = []
        e.map(user=>{
            const buscar = evento.usuarios.findIndex(per => per.email === user.value)
            if(buscar < 0) evento.usuarios.push({ email: user.email, asistencia: false  })
        })

        evento.usuarios.map((usuario,posi) => {
           // const buscar_seleccionado = e.findIndex(uu => uu.value === usuario.email)
           // if(buscar_seleccionado < 0) evento.usuarios.splice(posi,1)
        })

        console.log(evento.usuarios)

        this.setState({ evento })
        return e
    }
    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    handleChangeCap(e){
        const { name, value } = e.target
        const { capacitacion } = this.state
        capacitacion[name] = value
        const refrescar = [
            'calificacion',
            'tipo',
            'area'
        ]
        if(refrescar.includes(name) !== false) this.setState({ usuariosfiltrados: [] })
        return this.setState({ capacitacion: capacitacion })
    }

    handleChangeEvento(e){
        const { name, value } = e.target
        const { evento } = this.state
        evento[name] = value
        return this.setState({ evento })
    }

    componentDidMount(){
        const { idcapacitacion } = this.state
        const { user } = this.state
        this.getCargos({ propietario: user.propietario })
        this.getMatrices({ propietario: user.propietario })
        this.getCapacitacion(idcapacitacion)
        this.getAreas(user.propietario)
    }

    checkIfNuevaEvaluacion(){
        const { user, conseciones } = this.state
        if(!user.area) return false
        if(!conseciones) return false
        if(!conseciones.areas) return false
        const buscararea = conseciones.areas.filter(ar => ar.idarea === user.area)
        if(buscararea.length > 0){
            return this.setState({ habilitarnuevaevaluacion: buscararea[0].activo })
        }
    }

    cambiarRangoFecha(item){
        this.setState({ selectionRange: item.selection, usuariosfiltrados: [] })
    }

 

    getAreas(propietario){

        this.setState({ loadingareas: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getAreas?propietario=${propietario}`)
        .then(res => res.json())
        .then(res => {
            this.setState({ loadingareas: false, areas: res })
        })
        .catch(error => {
            this.setState({ loadingareas: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    getEvaluaciones(condicion){
        const { user } = this.state
        let url = 'https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_VerEvaluaciones'

        if(user.tipo==='admin'){
            url = 'https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getMultiplesEvaluaciones'
        } else if(user.tipo==='evaluator'){
            if(user.area){
                url = 'https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getMultiplesEvaluaciones'
            }
        } 

        this.setState({ loadingevaluaciones: true })
        return fetch(url,{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            
            res.map((info,ival) => {
                res[ival].buscar = JSON.stringify(info.usuarioevaluado)
            })

            this.setState({ loadingevaluaciones: false, evaluaciones: res })
        })
        .catch(error => {
            this.setState({ loadingevaluaciones: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    getCapacitacion(id){

        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getCapById?id=${id}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            let usuariosfiltrados = []
            if(!res) return this.setState({ loadingcapacitacion: false, capacitacion: false })

            if(res.usuarios){
                if(Array.isArray(res.usuarios) !== false){
                    if(res.usuarios.length > 0){
                        res.usuarios.map(user => {
                            usuariosfiltrados.push(user._id['$oid'])
                        })

                    }
                }
            }

            const nuevo_evento = {
                usuarios: res.usuarios
            }

            this.setState({ evento: nuevo_evento, loadingcapacitacion: false, capacitacion: res, usuarios_detalles: res.usuarios, usuariosfiltrados })
        })
        .catch(error => {
            this.setState({ loadingcapacitacion: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    getMatrices(condicion){

        this.setState({ loadingmatrices: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_VerMatricesHabilidades`,{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            this.setState({ loadingmatrices: false, matrices: res })
        })
        .catch(error => {
            this.setState({ loadingmatrices: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    getCargos(condicion){
        this.setState({ loadingcargos: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_GetCargos`,{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            this.setState({ loadingcargos: false, cargos: res })
        })
        .catch(error => {
            this.setState({ loadingcargos: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    getRoles(tipo){

        this.setState({ loadingcargos: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getRolesUsuario?tipo=${tipo}`)
        .then(res => res.json())
        .then(res => {
            this.setState({ loadingcargos: false, roles: res })
        })
        .catch(error => {
            this.setState({ loadingcargos: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    guardarCrear(){
        const { capacitacion, usuariosfiltrados, user } = this.state
        
        if(!capacitacion.titulo||!capacitacion.descripcion){
            return toast.error("Todos los campos son requeridos", this.state.toaststyle)
        }
        capacitacion.propietario = user.propietario
        capacitacion.status = '1'
        capacitacion.creador = user
        capacitacion.usuarios = usuariosfiltrados
        capacitacion.creado = this.formatDate(new Date())
        
        this.setState({ guardandocambios: true })
        return fetch('https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_CrearCapacitacion',{
            method: 'POST',
            body: JSON.stringify(capacitacion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            const texto = capacitacion._id ? 'Registro modificado exitosamente' : 'Registro creado exitosamente'
            toast.success(texto, this.state.toaststyle)
            this.setState({ guardandocambios: false, showcreacion: false })
        })
        .catch(error => {
            this.setState({ guardandocambios: false, showcreacion: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    fetchUsuarios(condicion){
        this.setState({ loadingUsuarios: true })
        return fetch('https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getUsersCustom',{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            this.setState({ loadingUsuarios: false, usuarios: res })
        })
        .catch(error => {
            this.setState({ loadingUsuarios: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    statusUser(tipo){
        switch (tipo) {
            case '1':
                    return <small className="text-success"><i className="far fa-check-circle"></i> APROBADA</small>
                    break;
            default:
                return <small ><i className="far fa-clock"></i> EN REVISIÓN</small>
                break;
        }
    }

    tipoUser(tipo){
        switch (tipo) {
            case 'admin':
                    return 'Administrador'
                    break;
            case 'evaluator':
                    return 'Evaluador'
                    break;
            case 'empleado':
                    return 'Usuario'
                    break;        
            default:
                return tipo
                break;
        }
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    addMoreDays(date, days){
        var result = new Date(date)
        result.setDate(result.getDate() + days)
        return this.formatDate(result)
    }

    sliceIntoChunks(arr, chunkSize) {
        const res = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            const chunk = arr.slice(i, i + chunkSize);
            res.push(chunk);
        }
        return res;
    }

    async subirArchivoHabilidades(){

        const { habilidades } = this.state

        this.setState({ loadingvalidacion: true })

        const array_dividido = this.sliceIntoChunks(habilidades,30)
        
        const recorrer = await Promise.all( await array_dividido.map(async datos => {
               const procesar = await this.uploadHabilidadesToProcess(datos)
        }) )

        this.setState({ loadingvalidacion: false })
        return toast.success('Información cargada exitosamente', this.state.toaststyle)

    }

    async subirValidarHabilidades(){
        const { habilidades } = this.state

        this.setState({ loadingvalidacion: true })

        let hash = {}
        const duplicados_eliminados = habilidades.filter(o => hash[o.habilidad] ? false : hash[o.habilidad] = true)

        const array_dividido = this.sliceIntoChunks(duplicados_eliminados,30)
        
        const recorrer = await Promise.all( await array_dividido.map(async datos => {
               const procesar = await this.uploadHabilidadesToValidate(datos)
        }) )

        this.setState({ loadingvalidacion: false })
        return toast.success('Información cargada exitosamente', this.state.toaststyle)
    }

    uploadHabilidadesToProcess(habs){

        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_recalcularHabilidades`,{
            method: 'POST',
            body: JSON.stringify(habs),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(datos => {
            console.log(datos)
        })
        .catch(error => {
            console.log(error)
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    uploadHabilidadesToValidate(habs){
        const { no_encontradas, habilidades } = this.state
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_validarHabilidades`,{
            method: 'POST',
            body: JSON.stringify(habs),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(datos => {

            if( datos.no_encontrados.length > 0 ) {
                datos.no_encontrados.map(hab => no_encontradas.push(hab))
                toast.error('Algunas habilidades no fueron encontradas', this.state.toaststyle)
            }

            if( datos.encontradas.length > 0 ) {
                habilidades.map((hab,ihab) => {
                    const i = datos.encontradas.findIndex(ha => ha.nombre === hab.habilidad)
                    if(i > -1){
                        habilidades[ihab].id = datos.encontradas[i].id
                    }
                })
            }

            console.log(habilidades)
        
            this.setState({ no_encontradas, habilidades })
        })
        .catch(error => {
            console.log(error)
            this.setState({ loadingmatrices: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    mostrarProgresoExcel(){
        const { habilidades, loadingvalidacion } = this.state
        if(loadingvalidacion){
            return <div>
            <Spinner animation="border" />
            <h3>Espere un momento...</h3>
        </div>
        }
        if(habilidades.length < 1) return <div>
            <hr />
            <h4>Sin habilidades cargadas</h4>
        </div>
        return <div>
            <hr />
            <h4>{habilidades.length} Habilidades cargadas</h4>
            <Button variant="outline-primary" onClick={()=>this.subirValidarHabilidades()}>Validar habilidades</Button>

            {this.validacionResumenHabilidades()}
        </div>

    }

    validacionResumenHabilidades(){
        const { no_encontradas, habilidades } = this.state

        if(no_encontradas.length > 0) return <div>

            <h4 className="mt-3">Habilidades no válidas y/o no encontradas</h4>
            <Table>
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Estado</th>
                    </tr>
                </thead>
                <tbody>
            {
                no_encontradas.map((hab,i) => {
                    return <tr key={`hab${i}`}>
                        <th>{hab.habilidad}</th>
                        <th>No encontrada o inválida</th>
                    </tr>
                })
            }
            </tbody>
            </Table>
        </div>

        const hab_sin_id = habilidades.filter(ha => !ha.id)

        if(hab_sin_id.length > 0) return false

        return <div className="mt-3">
            <Button variant="success" onClick={()=>this.subirArchivoHabilidades()} >SUBIR ARCHIVO</Button>
        </div>


    }

    mostrarCapacitacion(){
        const { loadingcapacitacion, capacitacion, procesandoexcel, loadingborrado, showcreacion } = this.state
        if(loadingcapacitacion){
            return <div>
            <Spinner animation="border" />
            <h3>Cargando información...</h3>
        </div>
        }

        if(!capacitacion) return <h2>No se pudo obtener este registro</h2>

        return <div className="mb-5">
            {this.formularioNuevoUser()}
            <hr />
        <h2><i className="far fa-file-excel"></i> Subir archivo de habilidades</h2>
        <div style={{ clear: 'both' }}><hr /></div>

        <Card >
            <Card.Body>
                <h4>Puedes subir un nuevo progreso de habilidades obtenido de esta capacitación, deberás cargar un excel</h4>
            <label className="form-control-label">Carga tu documento excel con dos columnas, <b>habilidad, puntaje y rut (Sin punto ni guiones)</b></label>
        <input 
        required 
        type="file" 
        name="file" 
        id="file" 
        className="form-control"
        onChange={this.handleInputChange} 
        placeholder="Archivo de excel" 
        />
        { procesandoexcel ?  <Spinner animation="border" /> : false }
        {this.mostrarProgresoExcel()}
            </Card.Body>
        </Card>
        
        <hr />
        <Button style={{ float: 'right', marginBottom: 15 }} onClick={()=> this.setState({ showcreacion: showcreacion ? false : true, textguardado: '' })}  variant="outline-primary">CREAR</Button>
        <h2><i className="far fa-calendar-alt"></i> Eventos</h2>
        <div style={{ clear: 'both' }}><hr /></div>
        {this.formularioEvento()}

        {

capacitacion.eventos.length > 0 ? <div>


<h5>{capacitacion.eventos.length} Eventos</h5>
        <Table responsive hover className="mb-0">
            <thead>
              <tr>
                <th>Título</th>
                <th>Descripción</th>
                <th>Fecha</th>
                <th>Hora</th>
                <th>Eliminar</th>
              </tr>
            </thead>
            <tbody>
            {
            capacitacion.eventos.length > 0 ? capacitacion.eventos.map((evento,iev) => {
                return <tr key={`evento-${iev}`}>
                    <th>{evento.titulo}
                    <p className="text-primary d-block" onClick={()=>{
                        this.setState({ evento: evento, showcreacion: true })
                        if(document.getElementById('formevento')) document.getElementById('formevento').scrollIntoView({ behavior: 'smooth' })
                    }}>EDITAR</p>
                    </th>
                    <th>{evento.descripcion}</th>
                    <th>{evento.fecha}</th>
                    <th>{evento.hora}</th>
                    <th> { loadingborrado ?  <Spinner animation="border" /> : <button className="btn btn-sm btn-outline-danger" onClick={()=>this.eliminarEvento(evento._id['$oid'])}>ELIMINAR</button> }  </th>
                    </tr>
            }) : false
        }
            </tbody>
          </Table>

    </div> : <div>
        <img src="icon-busqueda.png" style={{ width: 80 }} />
        <h4>No hay eventos</h4>
        </div>


        }

        
        </div>
    }

    eliminarEvento(id){
        const { idcapacitacion } = this.state
        this.setState({ loadingborrado: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_eliminarEvento?id=${id}`)
        .then(res => res.json())
        .then(res => {

            toast.success('Evento borrado', this.state.toaststyle)
            this.getCapacitacion(idcapacitacion)
            this.setState({ loadingborrado: false })
        })
        .catch(error => {
            this.setState({ loadingborrado: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    formularioEvento(){
        const { showcreacion, evento, usuarios_detalles, filtrousuario, loadingevento } = this.state

        let options_selected = []
        let options_available = []
        let mostrar_usuarios = []

        usuarios_detalles.map(user => {
            options_available.push({ value: user.email, label: user.email })
        })

        if(evento.usuarios){
            if(Array.isArray(evento.usuarios) !== false){
                if(evento.usuarios.length > 0){
                    evento.usuarios.map(user => {
                        mostrar_usuarios.push(user)
                        options_selected.push({ value: user.email, label: user.email })
                    })
                }
            }
        }

        const lowercasedFilter = filtrousuario.toLowerCase();
        const usuariosFiltrados = evento.usuarios.filter(item => {
            return Object.keys(item).some(key =>
                item[key].toString().toLowerCase().includes(lowercasedFilter)
            );
        });

        if(!showcreacion) return false

        return <Card className="mb-3" id="formevento">
            <Card.Body>

                <Row>

                <Col md={4} >
                    <div className="form-group">
                    <label className="form-control-label d-block">Título</label>
                    <input name="titulo" className="form-control" value={evento.titulo ? evento.titulo : ''} onChange={this.handleChangeEvento} />
                    </div>
                </Col>

                <Col md={8} >
                    <div className="form-group">
                    <label className="form-control-label d-block">Descripción</label>
                    <input name="descripcion" className="form-control" value={evento.descripcion ? evento.descripcion : ''} onChange={this.handleChangeEvento} />
                    </div>
                </Col>

                <Col md={4} >
                    <div className="form-group">
                    <label className="form-control-label d-block">Fecha</label>
                    <input name="fecha" type="date" className="form-control" value={evento.fecha ? evento.fecha : ''} onChange={this.handleChangeEvento} />
                    </div>
                </Col>

                <Col md={4} >
                    <div className="form-group">
                    <label className="form-control-label d-block">Hora</label>
                    <input name="hora" type="time" className="form-control" value={evento.hora ? evento.hora : ''} onChange={this.handleChangeEvento} />
                    </div>
                </Col>


                <Col md={12} >
                    <div className="form-group">
                    <h4><i className="far fa-user"></i> Usuarios participantes</h4>
                    
                    

                    <button className="btn mr-3 mb-3 btn-sm btn-outline-primary" onClick={()=>this.invitarTodos(true)}>INVITAR TODOS</button>
                    <button className="btn mr-3 mb-3 btn-sm btn-outline-dark" onClick={()=>this.invitarTodos(false)}>DES-INVITAR TODOS</button>
                    <button className="btn mr-3 mb-3 btn-sm btn-outline-primary" onClick={()=>this.asistirTodos(true)}>MARCAR ASISTENCIA TODOS</button>
                    <button className="btn mr-3 mb-3 btn-sm btn-outline-dark" onClick={()=>this.asistirTodos(false)}>MARCAR INASISTENCIA TODOS</button>
                    <button className="btn mr-3 mb-3 btn-sm btn-outline-dark" onClick={()=>this.desmarcarAsistenciaTodos()}>DESMARCAR ASISTENCIA TODOS</button>

                    <div className="form-group">
                    <input name="filtrousuario" className="form-control" placeholder="Filtrar resultados" onChange={this.handleChange} />
                    </div>

                    <Table responsive hover className="mb-0">
                        <thead>
                            <tr>
                                <th>Email</th>
                                <th>Invitado</th>
                                <th>Asistencia</th>
                            </tr>
                        </thead>
                    {
                        usuariosFiltrados.map((persona,iper) => {
                            return <tr key={`user${iper}`}>
                                <th>{persona.email} </th>
                                <th><button className={`btn btn-sm ${persona.invitado === true ? 'btn-primary':'btn-outline-primary'} `} onClick={()=>this.cambiarInvitacion(persona.email,true)}>INVITADO</button>  </th>
                                <th>
                                    <button className={`btn mr-3 ${persona.asistencia===true?'btn-success':'btn-outline-success'}`} onClick={()=>this.cambiarAsistencia(persona.email,true)} >SI</button>
                                    <button className={`btn mr-3 ${persona.asistencia===false?'btn-danger':'btn-outline-danger'}`} onClick={()=>this.cambiarAsistencia(persona.email,false)}>NO</button>
                                </th>
                                <th> </th>
                            </tr>
                        })
                    }
                    </Table>
                    </div>
                </Col>

                <Col md={4} >
                    <div className="form-group">
                    <label className="form-control-label d-block"> { evento._id ? 'Click para editar' : 'Click para crear' } </label>
                    <button className="btn btn-success" onClick={()=>this.crearEditarEvento()}>{ evento._id ? 'GUARDAR CAMBIOS' : 'CREAR' }</button>
                    { loadingevento ? <Spinner animation="border" /> : false }
                    </div>
                </Col>

                </Row>

            </Card.Body>
        </Card>
    }

    asistirTodos(status){
        const { evento } = this.state

        evento.usuarios.map((persona,i) => {
            if(status===true) evento.usuarios[i].invitado = status
            evento.usuarios[i].asistencia = status
        })
        
        return this.setState({ evento })
    }

    desmarcarAsistenciaTodos(){
        const { evento } = this.state

        evento.usuarios.map((persona,i) => {
            delete evento.usuarios[i].asistencia
        })
        
        return this.setState({ evento })
    }

    invitarTodos(status){
        const { evento } = this.state

        evento.usuarios.map((persona,i) => {
            evento.usuarios[i].invitado = status
        })
        
        return this.setState({ evento })
    }

    cambiarInvitacion(email,status){
        const { evento } = this.state

        const buscar = evento.usuarios.findIndex(u => u.email === email)
        if(buscar > -1){
            evento.usuarios[buscar].invitado = status
        }
        console.log(evento)
        return this.setState({ evento })
    }

    cambiarAsistencia(email,status){
        const { evento } = this.state

        const buscar = evento.usuarios.findIndex(u => u.email === email)
        if(buscar > -1){
            if(status===true) evento.usuarios[buscar].invitado = status
            evento.usuarios[buscar].asistencia = status
        }
        console.log(evento)
        return this.setState({ evento })
    }

    crearEditarEvento(){
        const { evento,  idcapacitacion } = this.state

        const requeridos = [
            'titulo',
            'descripcion',
            'fecha',
            'hora'
        ]
        let errores = []
        requeridos.map(req => {
            if(!evento[req]) errores.push(`${req} es requerido`)
        })
        if(errores.length > 0) return toast.error(errores.join(', '), this.state.toaststyle)

        // SET VARIABLES
        evento.idcapacitacion = idcapacitacion

        this.setState({ loadingevento: true })
        return fetch('https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_EditarCrearEvento',{
            method: 'POST',
            body: JSON.stringify(evento),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            const texto = evento._id ? 'Registro modificado exitosamente' : 'Registro creado exitosamente'
            toast.success(texto, this.state.toaststyle)
            this.setState({ loadingevento: false, evento: {} })
            this.getCapacitacion(idcapacitacion)
        })
        .catch(error => {
            this.setState({ loadingevento: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    refrescarRegistros(estado, loading){
        const { selectionRange, user, capacitacion } = this.state

        const requeridos = [
            'titulo',
            'descripcion'
        ]
        let errores = []

        requeridos.map(item => {
            if(!capacitacion[item]) errores.push(`${item} es requerido`)
        })

        if(errores.length > 0) return toast.error(errores.join(', '), this.state.toaststyle)

        const desde = this.formatDate(selectionRange.startDate)
        const hasta = this.formatDate(selectionRange.endDate)
        return this.getUsuariosLista(desde, this.addMoreDays(hasta,2), capacitacion.calificacion, capacitacion.tipo, capacitacion.area, user.propietario, estado, loading)
    }

    getUsuariosLista(desde, hasta, clasificacion, tipo, area, propietario, estado, loading){
        this.setState({ [loading]: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_GetListaBetweenDates?desde=${desde}&hasta=${hasta}&propietario=${propietario}&clasificacion=${clasificacion ? clasificacion : ''}&tipo=${tipo ? tipo : ''}&area=${area ? area : ''}`)
        .then(res => res.json())
        .then(res => {

            let usuarios = []
            let usuarios_detalles = []

            res.map(evaluacion => {
                if(evaluacion.usuarioevaluado){
                    if(isObject(evaluacion.usuarioevaluado) !== false){
                        if(evaluacion.usuarioevaluado._id){
                            if(!evaluacion.usuarioevaluado._id['$oid']) return false
                            if(usuarios.includes(evaluacion.usuarioevaluado._id['$oid']) !== true){
                                usuarios.push(evaluacion.usuarioevaluado._id['$oid'])
                                usuarios_detalles.push(evaluacion.usuarioevaluado)
                            }
                        }
                    }
                }
            })

            if(usuarios.length < 1) toast.warn('Sin usuarios encontrados', this.state.toaststyle)
                        
            this.setState({ [loading]: false, [estado]: usuarios, usuarios_detalles })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    formularioNuevoUser(){
        const { usuariosfiltrados, capacitacion, areas, guardandocambios } = this.state

        if(guardandocambios){
            return <div>
            <Spinner animation="border" />
            <h3>Guardando información...</h3>
        </div>
        }

            return <Card className="card-blue">
                
                <Card.Body className="text-white ">
                <Row>
                <Col md={12} >
                { capacitacion._id ? <h3>Editar {capacitacion.titulo ? capacitacion.titulo : 'registro'} </h3> : <h3>Crear capacitación</h3> }
                <p>{capacitacion.descripcion}</p>
                </Col>

<Col md={4} >
    <div className="form-group">
    <label className="form-control-label d-block">Título</label>
    <input name="titulo" className="form-control" value={capacitacion.titulo ? capacitacion.titulo : ''} onChange={this.handleChangeCap} />
    </div>
</Col>

<Col md={8} >
<div className="form-group">
    <label className="form-control-label d-block">Descripción</label>
    <input name="descripcion" className="form-control" value={capacitacion.descripcion ? capacitacion.descripcion : ''} onChange={this.handleChangeCap} />
    </div>
</Col>

{
    capacitacion.calificacion ?  <Col md={3} >
    <div className="form-group">
    <label className="form-control-label d-block">Calificación</label>
    <h4>{capacitacion.calificacion}</h4>
    </div>
</Col> : false
}

{
    capacitacion.tipo ? <Col md={3} >
    <div className="form-group">
        <label className="form-control-label d-block">Tipo de evaluación</label>
        <h4>{capacitacion.tipo}</h4>
    </div>
    </Col> : false
}

{

}


<Col md={12}>
    
<div className="form-group">
{this.ifusuariosfiltrados(usuariosfiltrados)}
</div>
</Col>

{
    usuariosfiltrados.length > 0 ? <Col md={3}>
    <label className="form-control-label d-block">Click para { capacitacion._id ? 'editar' : 'crear' } </label>
    <Button style={{ background: 'white' }} onClick={()=>this.guardarCrear()} variant="outline-white">{ capacitacion._id ? 'GUARDAR CAMBIOS' : 'CREAR' }</Button>
    </Col> : false
}


<Col xs={12}>
<p className="m-0 onclick" onClick={()=>this.setState({ capacitacion: {}, usuariosfiltrados: [], usuarios_detalles: [] })}>Reiniciar formulario</p>

</Col>

</Row>
                </Card.Body>
            </Card>
        


    }


    ifusuariosfiltrados(usuarios){
        const { usuarios_detalles, capacitacion } = this.state
        if(usuarios.length < 1) return false

        return <Accordion>
        <hr />
    <Card>
      <Accordion.Toggle as={Card.Header} className="card text-dark" style={{ borderRadius: 3 }} eventKey="1">
        <h5 className="nomargin">{usuarios.length} USUARIOS <span style={{ color: '#adacac', fontSize: 15 }}>Ver detalles</span> </h5>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="1">
        <Card.Body>

        <Table responsive hover className="mb-0">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Apellido P</th>
                <th>Apellido M</th>
                <th>Rut</th>
                <th>Email</th>
                <th>Observaciones</th>
              </tr>
            </thead>
            <tbody>
              { usuarios_detalles.map((user,icap)=>{
                 
                  return <tr key={`cap${icap}`}>
                      <th>{user.nombres}</th>
                      <th>{user.apellidopaterno}</th>
                      <th>{user.apellidomaterno }</th>
                      <th>{user.rut}</th>
                      <th>{user.email} </th>
                      <th>{this.observacionesBySupTipoCapacitacion(user._id ? user._id['$oid'] : false)} </th>
                  </tr>
              }) }
            </tbody>
          </Table>

        </Card.Body>
      </Accordion.Collapse>
    </Card>
  </Accordion>
    }

    observacionesBySupTipoCapacitacion(id){
        if(!id) return false
        const { capacitacion } = this.state
        if(!capacitacion.meta) return false
        if(Array.isArray(capacitacion.meta) !== true ) return false

        if(capacitacion.subtipo==='habilidades-reprobadas'){

            
            const buscar_posicion_usuario = capacitacion.meta.findIndex(us => us.id === id)
            if(buscar_posicion_usuario > -1){
                if(!capacitacion.meta[buscar_posicion_usuario].habilidades) return false
                if(Array.isArray(capacitacion.meta[buscar_posicion_usuario].habilidades) !== true) return false
                let observaciones = []
                return capacitacion.meta[buscar_posicion_usuario].habilidades.map(hab => {
                    return <p><b>Obtuvo {hab.logrado} de {hab.esperado} en </b> {hab.nombre}</p>
                })

            }

        }

        return false
    }

    ifAreaExist(idarea){
        const { areas } = this.state

        if(areas.length > 0){
            const buscar = areas.findIndex(ma => ma._id['$oid'] === idarea)
            if(areas[buscar]){
                return areas[buscar].nombre
            }
        }

        return 'No encontrado'
    }

    ifMatrixExist(idmatriz){
        const { matrices } = this.state

        if(matrices.length > 0){
            const buscar = matrices.findIndex(ma => ma._id['$oid'] === idmatriz)
            if(matrices[buscar]){
                return matrices[buscar].nombre
            }
        }

        return 'No encontrado'
    }
    
    render(){
        return(
            <div className="fluid">
       <Header/>   
       <ToastContainer />
<div className="container-fluid">
  <div className="row">
   <Nav/>
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Detalle de capacitación</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
        </div>
      </div>
            {this.mostrarCapacitacion()}

     { /* <Card className="mb-3 cardblue">
<Card.Body>

<Row className="leyenda">
<Col md={12}>
        <h4 className="text-white">Definición de clasificación</h4>
        <hr />
    </Col>

    <Col md={2}>
        <h3 className="etiqueta">A</h3>
        <p>Nota Mayor al 160% del promedio deseado</p>
    </Col>

    <Col md={2}>
        <h3 className="etiqueta">B</h3>
        <p>Nota Mayor al 130% del promedio deseado y menor al 160%</p>
    </Col>

    <Col md={2}>
        <h3 className="etiqueta">C</h3>
        <p>Nota mayor al promedio deseado y menor al 130% del promedio deseado</p>
    </Col>

    <Col md={2}>
        <h3 className="etiqueta">D</h3>
        <p>Nota mayor al 50% del promedio deseado y menor del promedio deseado</p>
    </Col>
    <Col md={2}>
        <h3 className="etiqueta">E</h3>
        <p>Nota menor al 50% del promedio deseado</p>
    </Col>
    <Col md={12}>
        <p style={{fontSize: 14, fontWeight: 'lighter' }}>Otras consideraciones Si el trabajador aprueba sobre el 85% de las habilidades, la clasificación mínima a la que accede es C, por otro lado, si el trabajador aprueba menos del 85%, entonces la clasificación máxima a la que accede es D</p>
    </Col>
</Row>

</Card.Body>
        </Card> */}
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(DetalleCapacitacion);