import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button } from 'react-bootstrap'
import Header from '../Header'
import Nav from '../Nav'
import Spinner from 'react-bootstrap/Spinner'
import Table from 'react-bootstrap/Table'
import * as XLSX from 'xlsx'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

class Areas extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            matrices: [],
            loadingUsuarios: true,
            filtroroles: '',
            area: {},
            showcreacion: false,
            textguardado: '',
            loadingroles: true,
            areas: [],
            loadingmatrices: true,
            hojas: [],
            procesandoexcel: false,
            guardando: false,
            toaststyle: { position: toast.POSITION.BOTTOM_RIGHT }
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeArea = this.handleChangeArea.bind(this)
        this.handleChangeNivel = this.handleChangeNivel.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
    }

    handleInputChange (event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        this.setState({
          [name]: value
        })
        let hojas = []
        if (name === 'file') {
            this.setState({procesandoexcel:true})
          let reader = new FileReader()
          reader.readAsArrayBuffer(target.files[0])
          reader.onloadend = (e) => {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, {type: 'array'});
    
            workbook.SheetNames.forEach(function(sheetName) {
              var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
              hojas.push(XL_row_object)
            })
            
            const requeridos = [
                'nombre',
            ]
            const columnasincompletas = []
            hojas[0].map((row,i) => {
                const faltantes = []
                requeridos.map(req => {
                    if(!row[req]) faltantes.push(req)
                    return true
                })
                if(faltantes.length > 0) columnasincompletas.push(`La columna ${i+1} le faltan campos: ${faltantes.join(', ')}`)
                return true
            })

            if(columnasincompletas.length > 0){
                this.setState({ procesandoexcel: false })
                columnasincompletas.push('Corrige el documento e inténtalo de nuevo')
                return toast.error( columnasincompletas.join('. '), this.state.toaststyle )
            }
            
            this.setState({
              selectedFileDocument: target.files[0],
              hojas: hojas[0],
              procesandoexcel: false
            })
          }
        }
      }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    handleChangeArea(e){
        const { name, value } = e.target
        const { area } = this.state
        area[name] = value
        console.log(area)
        return this.setState({ area: area })
    }

    handleChangeNivel(e){
        const { name, value } = e.target
        const { area } = this.state
        const pos = e.target.getAttribute('pos')
        if(area.niveles[pos]){
            area.niveles[pos][name] = value
        }
        return this.setState({ area: area })
    }

    emoverNivel(pos){
        const { area } = this.state
        if(area.niveles[pos]){
            area.niveles.splice(pos,1)
        }
        return this.setState({ area: area })
    }

    activardesactivar(pos){
        const { area } = this.state
        if(area.niveles[pos]){
            area.niveles[pos].status = area.niveles[pos].status===true ? false : true
        }
        return this.setState({ area: area })
    }

    anadirNivel(){
        const { area } = this.state
        if(!area.niveles) area.niveles = []
        area.niveles.push({
            nombre: '',
            descripcion:'',
            status: true,
        })
        return this.setState({ area: area })
    }

    componentDidMount(){
        const { user } = this.state
        this.getAreas(user.propietario)
    }

    getAreas(propietario){

        this.setState({ loadingmatrices: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getAreas?propietario=${propietario}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingmatrices: false, areas: res })
        })
        .catch(error => {
            this.setState({ loadingmatrices: false })
            alert("Error al consultar la información, intente nuevamente")
        })

    }

    
    guardarCrear(){
        const { area, user } = this.state

        if(!area.nombre){
            return alert("Todos los campos son requeridos")
        }
        area.propietario = user.propietario
        this.setState({ guardandocambios: true })
        return fetch('https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_crearEditarArea',{
            method: 'POST',
            body: JSON.stringify(area),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            const texto = area._id ? 'Área modificada exitosamente' : 'Área creada exitosamente'
            toast(texto)
            this.setState({ guardandocambios: false, area: {}, showcreacion: false })
            this.getAreas(user.propietario)
        })
        .catch(error => {
            this.setState({ guardandocambios: false })
            alert("Error al consultar la información, intente nuevamente")
        })
    }

    
    statusUser(tipo){
        switch (tipo) {
            case true:
                    return 'Activo'
            default:
                return 'Inactivo'
        }
    }

    formularioNuevaArea(){
        const { showcreacion, area, guardandocambios } = this.state

        if(guardandocambios){
            return <div>
            <Spinner animation="border" />
            <h3>Guardando información...</h3>
        </div>
        }
        if(showcreacion){

            return <Card className="card-blue">
                
                <Card.Body className="text-white ">
                <Row>
                <Col md={12} >
                { area._id ? <h3>Editar área {area.nombre ? area.nombre : 'trabajo'} </h3> : <h3>Crear área</h3> }
                </Col>

<Col md={3} >
    <label className="form-control-label">Nombre</label>
    <input name="nombre" className="form-control" value={area.nombre ? area.nombre : ''} onChange={this.handleChangeArea} />
</Col>

<Col md={4} >
    <label className="form-control-label d-block">Click para agregar una sub area</label>
    <i style={{ fontSize: 38 }} onClick={()=>this.anadirNivel()} className="fas fa-plus-circle onclick"></i>
</Col>


<Col xs={12}>
<hr />
    {
        area.niveles ? area.niveles.map((ar,iar) => {
            return <Row key={`key-${iar}`}>
                <Col xs={12}>
                    <h4>Sub área {iar+1}</h4>
                </Col>
                <Col xs={3}>
                <label className="form-control-label d-block">Nombre</label>
                <input name="nombre" pos={iar} className="form-control" value={ar.nombre} onChange={this.handleChangeNivel} />
                </Col>

                <Col xs={3}>
                <label className="form-control-label d-block">Descripción</label>
                <input name="descripcion" pos={iar} className="form-control" value={ar.descripcion} onChange={this.handleChangeNivel} />
                </Col>

                <Col xs={3}>
                <label className="form-control-label d-block">Activar/Desactivar</label>
                <i style={{ fontSize: 38, color: ar.status===true?'#02eb52':'#1476cd' }} onClick={()=>this.activardesactivar(iar)} className={ar.status===true ? 'fas fa-check-circle onclick' : 'far fa-check-circle onclick'}></i>
                </Col>

                <Col xs={3}>
                <label className="form-control-label d-block">Eliminar sub área</label>
                <i style={{ fontSize: 38, color: 'red' }} onClick={()=>this.emoverNivel(iar)} className="fas fa-minus-circle onclick"></i>
                </Col>
                {
                    iar+1 < area.niveles.length ? <Col xs={12}>
                    <hr />
                </Col> : false
                }
                
            </Row>
        }) : <h3>Sin sub áreas creadas</h3>
    }
<hr />
</Col>

<Col md={12}>
<label className="form-control-label d-block">Click para { area._id ? 'editar' : 'crear' } </label>
    <Button style={{ background: 'white' }} onClick={()=>this.guardarCrear()} variant="outline-white">{ area._id ? 'GUARDAR CAMBIOS' : 'CREAR' }</Button>
</Col>

<Col xs={12}>
<p className="m-0 onclick" onClick={()=>this.setState({ area: {} })}>Reiniciar formulario</p>

</Col>

</Row>
                </Card.Body>
            </Card>
        }


    }

    mostrarExcel(){
        const { hojas, procesandoexcel, guardando } = this.state

        if(procesandoexcel){
            return <div>
            <Spinner animation="border" />
            <h4>Analizando documento...</h4>
            </div>
        }

        if(hojas.length > 0){
            return <div>
             {
                 guardando ? <Spinner animation="border" /> : <div>
                    <div className="table-responsive">

                <h5>Encontramos {hojas.length} registros en tu documento excel </h5>
                <Table responsive hover> 
                    <thead>
                        <tr>
                            <th>Nombre</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            hojas.map((usuario,iu) => {
                                return <tr key={`user${iu}`}>
                            <th>{usuario.nombre}</th>
                                </tr>
                            })
                        }
                    </tbody>
                </Table>


                        </div>

            <button className="btn btn-success" onClick={() => this.subirAreas()}>SUBIR ARCHIVO Y ACTUALIZAR BASE DE DATOS</button>
            
            </div>
             }   
            </div>
        }
    }

    subirAreas(){


        return toast.warn('Esta función no se encuentra 100% terminada', this.state.toaststyle)


    }

    mostrarAreas(){
        const { loadingmatrices, filtroroles, areas, showcreacion } = this.state

        if(loadingmatrices){
            return <div>
                <Spinner animation="border" />
                <h3>Cargando información</h3>
            </div>
        }

        const lowercasedFilter = filtroroles.toLowerCase();
        const rolesFiltrados = areas.filter(item => {
            return Object.keys(item).some(key =>
                item[key].toString().toLowerCase().includes(lowercasedFilter)
            );
        });
        
        return <Card style={{ width: '100%' }}>
        <Card.Body>
          <Card.Title style={{ float: 'left' }}>{areas.length} áreas encontradas</Card.Title>
          <Button style={{ float: 'right', marginBottom: 15 }} onClick={()=> this.setState({ showcreacion: showcreacion ? false : true, textguardado: '' })}  variant="outline-primary">CREAR</Button>
          <div style={{ clear: 'both' }}><hr /></div>
        {this.formularioNuevaArea()}
          <Row>

          <Col xs={12}>
            <img alt="iconsistema" src="icon4.jpg" className="iconsystem" />
            <h4>Áreas de trabajo que forman parte de esta empresa</h4>
            <hr />
            <div className="form-group">
            <label className="form-control-label">Carga tu documento excel con la base de tus áreas de trabajo</label>
            <div className="mb-2">
            <a href="areas.xlsx" className="btn btn-sm btn-outline-warning" >DESCARGAR MODELO EXCEL</a>
            </div>
            <input 
            required 
            type="file" 
            name="file" 
            id="file" 
            className="form-control"
            onChange={this.handleInputChange} 
            placeholder="Archivo de excel" 
            />
            </div>

            {this.mostrarExcel()}
            <hr />
            </Col>

            <Col md={3}>
            <label className="form-control-label">Filtrar resultados</label>
            <input name="filtroroles" className="form-control mb-4" placeholder="Escribe cualquier palabra clave" onChange={this.handleChange} />
            </Col>

            {filtroroles !== '' ? <Col xs={12}><label className="form-control-label">{rolesFiltrados.length} Coindicencias</label></Col>:false}
          
          </Row>          

          <Table responsive hover>
  <thead>
    <tr>
      <th>Nombre</th>
      <th>Sub areas</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    { rolesFiltrados.map((rol,i)=>{
        console.log(rol)
        return <tr key={`user${i}`}>
            <th>{rol.nombre}<br/><small className="text-primary onclick" onClick={()=>this.setState({ showcreacion: true, area: rol, textguardado: '' })}>Editar</small></th>
            <th>{rol.niveles ? `${rol.niveles.length} sub areas` : 'Sin sub areas'}</th>
            <th></th>
        </tr>
    }) }
  </tbody>
</Table>

        </Card.Body>
      </Card>
    }
    
    render(){
        return(
            <div className="fluid">
       <Header/>   
       <ToastContainer 
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          />
<div className="container-fluid">
  <div className="row">
   <Nav/>
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Áreas de trabajo</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
      
        </div>
      </div>

      {this.mostrarAreas()}
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(Areas);